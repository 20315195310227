.RelatedCustomizationsModal___2kG60 {
  color: #000000;
  line-height: 22px;
}
.RelatedCustomizationsModal___2kG60 .ant-modal-body {
  padding: 38px 24px 24px;
}
.RelatedCustomizationsModal___2kG60 .modal-header {
  text-align: center;
}
.RelatedCustomizationsModal___2kG60 .modal-header .modal-title {
  font-size: 18px;
  margin-top: 22px;
}
.RelatedCustomizationsModal___2kG60 .modal-body {
  margin: 12px 0 24px 0;
}
.RelatedCustomizationsModal___2kG60 .modal-body .related-summary {
  color: #8C8C8C;
  margin-bottom: 8px;
}
.RelatedCustomizationsModal___2kG60 .modal-actions {
  display: flex;
  flex-direction: column;
  padding: 0 21px;
  row-gap: 16px;
}
.RelatedCustomizationsModal___2kG60 .related-list {
  height: 160px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  column-gap: 4px;
  row-gap: 8px;
}
.RelatedCustomizationsModal___2kG60 .related-list .empty-content {
  padding: 50px 0;
  text-align: center;
  width: 100%;
  font-size: 16px;
  color: #8C8C8C;
}
.RelatedCustomizationsModal___2kG60 .related-list .customization-item {
  padding: 5px 12px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  background: #F5F5F5;
}
