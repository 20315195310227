.RadioCard___3q-le {
  padding: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}
.RadioCard___3q-le .img-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
}
.RadioCard___3q-le .img-wrapper > img {
  width: 100%;
}
.RadioCard___3q-le .img-wrapper .img-wrapper-disabled {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(30, 30, 30, 0.2);
  border-radius: 6px;
}
.RadioCard___3q-le .img-wrapper .img-wrapper-disabled > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 130px;
  font-family: Proxima Nova;
  color: #ffffff;
  font-size: 26px;
  font-weight: 600;
  line-height: 32.58px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}
.RadioCard___3q-le .ant-radio-wrapper {
  margin: 0;
}
.RadioCard___3q-le .tips-wrapper {
  padding-left: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
