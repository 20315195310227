.MonthlyStatementContainer___3Ev2W {
  margin-top: 15px;
  overflow: hidden;
}
.MonthlyStatement___1NOTH {
  width: 345px;
  color: #000000;
}
.MonthlyStatement___1NOTH .title {
  font-size: 18px;
  line-height: 26px;
}
.MonthlyStatement___1NOTH .period {
  margin: 28px 0 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.MonthlyStatement___1NOTH .month-list {
  background: #ffffff;
  border-radius: 4px;
}
.MonthlyStatement___1NOTH .month-list .column-month,
.MonthlyStatement___1NOTH .month-list .column-action {
  flex: 1;
  padding: 17px 16px 12px 16px;
}
.MonthlyStatement___1NOTH .month-list .month-list-header {
  display: flex;
  align-items: center;
}
.MonthlyStatement___1NOTH .month-list .row {
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.MonthlyStatementPDFContainer___1-QLa {
  position: absolute;
  left: -9999px;
  top: 0;
}
