.SalesTabContent___3Cqll .section-title {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.03em;
  color: rgba(29, 27, 46, 0.5);
  text-transform: uppercase;
}
.SalesTabContent___3Cqll .discount-summary {
  display: inline-block;
  padding: 6px 10px 4px;
  color: #15d0c5;
  background: #effaf9;
  border-radius: 100px;
}
.SalesTabContent___3Cqll .summary-data {
  margin-bottom: -10px;
}
.SalesTabContent___3Cqll .online-notice {
  display: flex;
  align-items: center;
  padding: 16px;
  background: #ffedf3;
  border-radius: 4px;
}
.SalesTabContent___3Cqll .online-notice .notice-message {
  flex: 1;
  min-width: 0;
  margin-right: 15px;
  font-weight: 500;
  color: #000;
  line-height: 24px;
}
.SalesTabContent___3Cqll .online-notice .error-icon {
  width: 24px;
  height: 24px;
  background-image: url('https://pos-static.chowbus.com/assets/icon_error.png');
  background-size: 100%;
  cursor: pointer;
}
.tooltip-divider {
  margin: 10px 0;
  border-top: 2px dashed #ffffff;
}
.ant-tooltip.summary-tooltip,
.ant-tooltip.amount-collected-tooltip {
  width: 360px;
  max-width: 360px;
}
.ant-tooltip.fee-tooltip-overlay {
  width: 630px;
  max-width: 1000px;
}
@media (max-width: 576px) {
  .ant-tooltip.fee-tooltip-overlay {
    width: 90vw;
    max-width: 90vw;
  }
}
