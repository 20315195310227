/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.extraMessageContainerClassName___2nT9r {
  overflow-y: auto;
  max-height: 400px;
  white-space: pre-line;
  text-align: left !important;
  width: 800px;
  max-width: calc(85vw);
}
.rejectModal___2jk5L,
.errorModal___zAQQp {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rejectModal___2jk5L .errorIcon___2KThD,
.errorModal___zAQQp .errorIcon___2KThD {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #faad14;
  font-size: 72px;
}
.rejectModal___2jk5L .errorIcon___2KThD img,
.errorModal___zAQQp .errorIcon___2KThD img {
  height: 80px;
  width: 80px;
}
.rejectModal___2jk5L .warnIcon___2eI_u,
.errorModal___zAQQp .warnIcon___2eI_u {
  margin-top: 20px;
  color: #faad14;
  font-size: 72px;
}
.rejectModal___2jk5L .warnIcon___2eI_u .img___A4EoL,
.errorModal___zAQQp .warnIcon___2eI_u .img___A4EoL {
  height: 80px;
  width: 80px;
}
.rejectModal___2jk5L .title___2H9du,
.errorModal___zAQQp .title___2H9du {
  text-align: center;
  font-size: 16px;
  margin-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
}
.rejectModal___2jk5L .content___3eR_k,
.errorModal___zAQQp .content___3eR_k {
  font-size: 14px;
  margin-bottom: 24px;
  padding-left: 20px;
  padding-right: 20px;
}
.rejectModal___2jk5L .later_btn___6atF1,
.errorModal___zAQQp .later_btn___6atF1 {
  background-color: #d9d9d9;
  color: #ffffff;
}
.rejectModal___2jk5L .later_btn___6atF1,
.errorModal___zAQQp .later_btn___6atF1,
.rejectModal___2jk5L .modify_btn___1TBzH,
.errorModal___zAQQp .modify_btn___1TBzH {
  width: 140px;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
}
