.autopilitPageTips___3Il71 {
  padding: 10px;
  font-size: 14px;
  background-color: #FFFAE7;
  margin-bottom: 15px;
}
.autopilitPageTips___3Il71 ol {
  margin-block-end: 0;
  padding-inline-start: 30px;
}
.autopilitPage___22lE5 .bizcharts {
  height: 238px !important;
}
.autopilitPage___22lE5 .grouped-menus {
  position: relative;
  z-index: 1;
  scroll-behavior: smooth;
}
.autopilitPage___22lE5 .column-price {
  padding-left: 30px !important;
}
.autopilitPage___22lE5 .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 48px;
}
.autopilitPage___22lE5 .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: 24px;
}
.autopilitPage___22lE5 .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}
.autopilitPage___22lE5 .ant-table-tbody > tr {
  cursor: pointer;
}
.autopilitPage___22lE5 .divider___1aMiI {
  background-color: #fffcfc;
  border-width: 1px 0 0;
  margin: 10px 0;
}
.autopilitPage___22lE5 .campaignSection___2RM9i.enh___1fxzQ {
  margin-bottom: 15px;
  background-color: #ffffff;
  padding: 16px;
  position: relative;
}
.autopilitPage___22lE5 .campaignSection___2RM9i.enh___1fxzQ .chartSection___3h5lz .ant-card {
  border-radius: 0 !important;
  border: 0 !important;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .chartSection___3h5lz {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .chartSection___3h5lz .ant-card {
  flex: 1;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .userSegmentTitle___2lpHr {
  font-size: 14px;
  font-weight: 400;
  color: rgba(29, 27, 46, 0.5);
  margin: 4px 0;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .campaignName___2z0Yf {
  font-weight: 600;
  font-size: 20px;
  color: #000000;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .campaignDesc___1henM {
  font-size: 16px;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .campaignStatusAndOpations___2fMQe {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .campaignTriggerTime___3AziE,
.autopilitPage___22lE5 .campaignSection___2RM9i .campaignStatus___3Jd9d {
  font-weight: 400;
  font-size: 14px;
  color: rgba(29, 27, 46, 0.5);
}
.autopilitPage___22lE5 .campaignSection___2RM9i .campaignTriggerTime___3AziE.campaignStatusActive___3Xr3V,
.autopilitPage___22lE5 .campaignSection___2RM9i .campaignStatus___3Jd9d.campaignStatusActive___3Xr3V {
  color: #15D0C5;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .campaignTriggerTime___3AziE {
  white-space: no-wrap;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .totalSent___3LnL3 {
  font-weight: 500;
  font-size: 24px;
  color: #000000;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .deliveriedTitle___kTHpi {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  height: 34px;
  display: flex;
  align-items: center;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .deliveriedChannels___sXl9v {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  height: 32px;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .deliveriedChannels___sXl9v .deliveriedChannelName___HGlg0 {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .campaignSectionHeader___w3tPK {
  min-height: 72px;
  position: relative;
  padding-bottom: 15px;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .campaignSectionHeader___w3tPK::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #D9D9D9;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .campaignSectionHeader___w3tPK .campaignActionStatusBar___1QeaU {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 18px;
  color: #000000;
  flex-shrink: 0;
  font-weight: 500;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .campaignSectionHeader___w3tPK .campaignActionStatusBar___1QeaU .campaignStatusDesc___2Tn9u {
  font-weight: 500;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .campaignSectionHeader___w3tPK .campaignActionStatusBar___1QeaU .campaignType___YMYjM {
  margin-left: 10px;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .campaignSectionHeader___w3tPK .campaignActionStatusBar___1QeaU .campaignAction___1dubz {
  margin-left: 15px;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .campaignSectionBody___2kGbE.campaignSectionBodyHidden___1A-rZ {
  display: none;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .campaignSectionBody___2kGbE .campaignSectionSentInfo___1MvqN {
  font-size: 18px;
  color: #000000;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0;
  font-weight: 600;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .campaignSectionFooter___10bpg {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  margin-top: 16px;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .campaignSectionFooter___10bpg::after {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  background-image: url('https://pos-static.chowbus.com/assets/show_hide_autopilot_data_icon.png');
  background-size: 16px 8px;
  background-position: center;
  background-repeat: no-repeat;
  transition-duration: 200ms;
  transition-property: transform;
  transition-timing-function: linear;
}
.autopilitPage___22lE5 .campaignSection___2RM9i .campaignSectionFooter___10bpg.expand___ND2cR::after {
  transform: rotate(-180deg);
}
.autopilitPage___22lE5 .campaignSectionEnh___9JmFD {
  padding: 20px;
}
.stickyShim___Tantb {
  position: fixed;
  top: 65px;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: #ffffff;
  z-index: 1;
}
