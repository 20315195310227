.manualCampaignPage___2kZis {
  border-radius: 10px;
  background-color: #ffffff;
  padding: 16px;
}
.manualCampaignPage___2kZis .grouped-menus {
  position: relative;
  z-index: 1;
  scroll-behavior: smooth;
}
.manualCampaignPage___2kZis .column-price {
  padding-left: 30px !important;
}
.manualCampaignPage___2kZis .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 48px;
}
.manualCampaignPage___2kZis .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: 24px;
}
.manualCampaignPage___2kZis .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}
.manualCampaignPage___2kZis .ant-table-tbody > tr {
  cursor: pointer;
}
.manualCampaignPage___2kZis .sensitive___13mCw {
  background-color: #FFFAE7;
  padding: 8px, 12px, 8px, 12px;
}
.manualCampaignPage___2kZis .divider___3JMNC {
  background-color: #fffcfc;
  border-width: 1px 0 0;
  margin: 10px 0;
}
.manualCampaignPage___2kZis .campaignSection___BceiW .userSegmentTitle___2FmqP {
  font-size: 14px;
  font-weight: 400;
  color: rgba(29, 27, 46, 0.5);
  margin: 4px 0;
}
.manualCampaignPage___2kZis .campaignSection___BceiW .campaignName___ijoFJ {
  font-weight: 600;
  font-size: 24px;
  color: #000000;
}
.manualCampaignPage___2kZis .campaignSection___BceiW .campaignStatusAndOpations___1dc5A {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.manualCampaignPage___2kZis .campaignSection___BceiW .campaignTriggerTime___2mVn_,
.manualCampaignPage___2kZis .campaignSection___BceiW .campaignStatus___1xU3Z {
  font-weight: 400;
  font-size: 14px;
  color: rgba(29, 27, 46, 0.5);
}
.manualCampaignPage___2kZis .campaignSection___BceiW .campaignTriggerTime___2mVn_.campaignStatusActive___1QC0d,
.manualCampaignPage___2kZis .campaignSection___BceiW .campaignStatus___1xU3Z.campaignStatusActive___1QC0d {
  color: #15D0C5;
}
.manualCampaignPage___2kZis .campaignSection___BceiW .campaignTriggerTime___2mVn_ {
  white-space: no-wrap;
}
.manualCampaignPage___2kZis .campaignSection___BceiW .totalSent___twQo0 {
  font-weight: 500;
  font-size: 24px;
  color: #000000;
}
.manualCampaignPage___2kZis .campaignSection___BceiW .deliveriedTitle___2AFHE {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  height: 34px;
  display: flex;
  align-items: center;
}
.manualCampaignPage___2kZis .campaignSection___BceiW .deliveriedChannels___-O-qy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  height: 32px;
}
.manualCampaignPage___2kZis .campaignSection___BceiW .deliveriedChannels___-O-qy .deliveriedChannelName___2HFGM {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.stickyShim___1Dxbf {
  position: fixed;
  top: 65px;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: #ffffff;
  z-index: 1;
}
.campaignStatusActive___1QC0d {
  color: #15D0C5;
}
.campaignStatusInactive___ExXHk {
  color: #E00051;
}
.manualCampaignPage___2kZis .no-wrap {
  white-space: nowrap;
  min-width: 70px;
}
.manualCampaignPage___2kZis .break-word {
  word-break: break-all;
}
