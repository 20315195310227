.download-page___bwPue {
  max-width: 1200px;
  padding: 24px;
  margin: 0 auto;
}
.download-page___bwPue .download-title {
  text-align: center;
}
.download-page___bwPue .download-section {
  display: flex;
}
.download-page___bwPue .download-section .download-guide {
  margin-left: 16px;
  flex: 1;
  min-width: 0;
}
.download-page___bwPue .download-section .download-guide .install-guide {
  margin-top: 20px;
}
.download-page___bwPue .download-section .download-guide .install-guide ol {
  padding: 0 0 0 1em;
  margin-top: 1em;
}
.download-page___bwPue .download-section .download-guide .install-guide ol li {
  margin-bottom: 1em;
}
.download-page___bwPue .qrcode-container {
  margin: 20px 0;
  text-align: center;
}
