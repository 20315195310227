.PayoutReport___aJ1hP .ant-card-type-inner .ant-card-extra {
  padding: 0;
}
.PayoutReport___aJ1hP .sales-report-header {
  position: sticky;
  z-index: 11;
  background: #f0f2f5;
}
.PayoutReport___aJ1hP .sales-tabs {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 16px;
  background: #ffffff;
}
.PayoutReport___aJ1hP .sales-tabs .sales-item {
  width: 110px;
  text-align: center;
  padding: 4px 0;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  cursor: pointer;
}
.PayoutReport___aJ1hP .sales-tabs .sales-item:last-child {
  margin-right: 0;
}
.PayoutReport___aJ1hP .sales-tabs .sales-item .ant-card-extra {
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  float: none;
}
.PayoutReport___aJ1hP .sales-tabs .sales-item.active {
  color: #e00051;
  background: #ffedf3;
  cursor: default;
}
.PayoutReport___aJ1hP .time-picker-container {
  flex: 1;
  min-width: 0;
}
@media (max-width: 991px) {
  .PayoutReport___aJ1hP .sales-tabs-container {
    width: 100%;
  }
  .PayoutReport___aJ1hP .time-picker-container {
    width: 100%;
    flex: auto;
  }
}
.PayoutReport___aJ1hP .chart-panel .tooltip-icon {
  color: #c0c2cc;
}
.PayoutReport___aJ1hP .absolute-center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MonthPicker___2eefw .ant-tabs-card > .ant-tabs-nav {
  line-height: 1;
  max-width: 100%;
}
.MonthPicker___2eefw .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  padding: 4px 16px;
  line-height: 25px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 0;
  border: none;
  cursor: pointer;
  background: transparent;
  font-family: 'Proxima Nova';
}
.MonthPicker___2eefw .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  background: #ffedf3;
  color: #e00051;
}
.MonthPicker___2eefw .ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar {
  display: none;
}
.MonthPicker___2eefw .ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}
.MonthPicker___2eefw .ant-tabs-top > .ant-tabs-nav:before {
  border-bottom: none;
}
