.ChartCard___2FqoV {
  width: 310px;
  height: 295px;
  padding: 32px 8px 0;
}
.ChartCard___2FqoV .card-top {
  display: flex;
  align-items: center;
  height: 156px;
}
.ChartCard___2FqoV .card-top .chart-container {
  width: 128px;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ChartCard___2FqoV .card-top .chart-summary {
  flex: 1;
  min-width: 0;
  margin-left: 14px;
}
.ChartCard___2FqoV .card-top .chart-summary .chart-title {
  color: rgba(29, 27, 46, 0.5);
  font-weight: 400;
}
.ChartCard___2FqoV .card-top .chart-summary .chart-aggregator {
  margin-top: 10px;
  font-size: 24px;
  font-weight: 600;
}
.ChartCard___2FqoV .card-top .chart-summary .chart-aggregator.no-wrap {
  white-space: nowrap;
}
.ChartCard___2FqoV .chart-divider {
  height: 1px;
  margin-bottom: 12px;
  background: rgba(0, 0, 0, 0.1);
}
