.entity-sample___EZEX- .shape {
  background: #c4c4c4;
}
.entity-sample___EZEX- .shape.square-table {
  width: 34px;
  height: 34px;
}
.entity-sample___EZEX- .shape.rect-table {
  width: 30px;
  height: 50px;
}
.entity-sample___EZEX- .shape.round-table {
  width: 39px;
  height: 39px;
  border-radius: 50%;
}
.entity-sample___EZEX- .shape.oval-table {
  width: 48px;
  height: 29px;
  border-radius: 50px;
}
.entity-sample___EZEX- .shape.bar-stool {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
.entity-sample___EZEX- .shape.arc {
  width: 28px;
  height: 56px;
  background: transparent;
  border-left: 10px solid #c4c4c4;
  border-top: 10px solid #c4c4c4;
  border-bottom: 10px solid #c4c4c4;
  border-radius: 28px 0 0 28px;
}
.entity-sample___EZEX- .shape.divider {
  width: 11px;
  height: 47px;
  border: 1px solid #000000;
  background: #ffffff;
}
.entity-sample___EZEX- .shape.booth {
  width: 28px;
  height: 56px;
  background: transparent;
  border-left: 10px solid #c4c4c4;
  border-top: 10px solid #c4c4c4;
  border-bottom: 10px solid #c4c4c4;
  border-radius: 28px 0 0 28px;
}
