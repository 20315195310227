.transaction-table___2Gw_j table > tbody > tr:hover {
  cursor: pointer;
}
.transaction-way-modal___1R_CP .modal-main {
  padding: 30px 30px 60px 30px;
}
.transaction-way-modal___1R_CP .modal-main .modal-header {
  text-align: center;
  font-size: 24px;
  margin-bottom: 50px;
}
.transaction-way-modal___1R_CP .modal-main .action-button + .action-button {
  margin-top: 20px;
}
.refund-input-modal___3lu_3 {
  color: #1d1b2e;
}
.refund-input-modal___3lu_3 .modal-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.refund-input-modal___3lu_3 .modal-main .modal-header {
  text-align: center;
}
.refund-input-modal___3lu_3 .modal-main .header-primary {
  font-size: 28px;
}
.refund-input-modal___3lu_3 .modal-main .header-secondary {
  width: 100%;
  font-size: 18px;
  color: #9b9b9b;
}
.refund-input-modal___3lu_3 .modal-main .input-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.refund-input-modal___3lu_3 .modal-main .input-wrapper .alpha-input-wrap {
  padding: 12px 23px;
}
.refund-input-modal___3lu_3 .modal-main .input-wrapper .alpha-input-wrap .alpha-input {
  width: 75px;
  height: 75px;
  text-align: center;
  font-size: 24px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  line-height: 75px;
  cursor: pointer;
  user-select: none;
}
.refund-input-modal___3lu_3 .modal-main .input-wrapper .alpha-input-wrap .alpha-input:active {
  background: #eeeeee;
}
.refund-input-modal___3lu_3 .modal-main .addition-action {
  text-align: center;
  padding: 10px 0;
}
.refund-input-modal___3lu_3 .modal-main .addition-action .ant-checkbox-wrapper {
  color: #1d1b2e;
  font-size: 18px;
}
.refund-input-modal___3lu_3 .modal-main .addition-action .ant-checkbox-wrapper .ant-checkbox-inner {
  border-radius: 50%;
}
.refund-input-modal___3lu_3 .modal-main .modal-footer {
  display: flex;
  width: 100%;
  margin-top: 15px;
}
