.LoginMethod___2jYus .scan-container-wrap {
  position: fixed;
  z-index: 97;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.LoginMethod___2jYus .scan-container-wrap .scan-container-overlayer {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
}
.LoginMethod___2jYus .scan-container-wrap .scan-container {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
}
.LoginMethod___2jYus .scan-container-wrap .scan-container .stop-scan-icon {
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  z-index: 3;
  transform: translate(50%, -50%);
  color: #ce2c54;
  font-size: 20px;
  cursor: pointer;
  opacity: 0.65;
}
.LoginMethod___2jYus .scan-container-wrap .scan-container .stop-scan-icon:hover {
  opacity: 1;
}
.LoginMethod___2jYus .gray-content {
  color: #92929F;
}
