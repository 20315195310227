.container___2St5m {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
}
.lang___TBQQw {
  width: 100%;
  height: 40px;
  line-height: 44px;
  text-align: right;
}
.lang___TBQQw .ant-dropdown-trigger {
  margin-right: 24px;
}
.header___3UabS {
  height: 44px;
  line-height: 44px;
  text-align: center;
}
.header___3UabS a {
  text-decoration: none;
}
.logo___2rPYl {
  height: 44px;
  margin-right: 16px;
  vertical-align: top;
}
