.CustomizationForm___3Jwa7 .section-title {
  font-size: 16px;
  line-height: 20px;
  color: #000;
  margin-bottom: 16px;
}
.CustomizationForm___3Jwa7 .section-options {
  margin-top: 16px;
}
.CustomizationForm___3Jwa7 .section-options .section-options-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.CustomizationForm___3Jwa7 .section-options .section-options-header .section-options-title {
  font-size: 16px;
  line-height: 20px;
  color: #000;
}
.CustomizationForm___3Jwa7 .ant-form-item-label > label {
  color: #8C8C8C;
}
.CustomizationForm___3Jwa7 .restriction-group-label,
.CustomizationForm___3Jwa7 .option-item-label {
  line-height: 22px;
  color: #8C8C8C;
  margin-bottom: 8px;
  white-space: nowrap;
}
