.shape-config-zone___3tQLf {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #f0f2f5;
  overflow-y: auto;
  z-index: 1;
  padding: 0 6px;
}
.shape-config-zone___3tQLf::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #eff1f6;
}
.shape-config-zone___3tQLf::-webkit-scrollbar-thumb {
  background-color: #c5cedb;
  border-radius: 0;
}
.shape-config-zone___3tQLf::-webkit-scrollbar-thumb:hover {
  background-color: #88bbf3;
}
.shape-config-zone___3tQLf .orientation-container {
  width: 100%;
  height: 158px;
  border: 2px solid #aaaaaa;
  border-radius: 4px;
}
.shape-config-zone___3tQLf .actions {
  margin: 0 -6px;
  padding: 10px;
  display: flex;
  background: #ffffff;
}
.shape-config-zone___3tQLf .actions .ant-btn {
  flex: 1;
}
