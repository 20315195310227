/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.BusinessTypeBlog___15jq5 .topSection .description-info {
  width: 35%;
}
@media (max-width: 575px) {
  .BusinessTypeBlog___15jq5 .topSection .description-info {
    width: 100%;
  }
}
.BusinessTypeBlog___15jq5 .topSection .description-card-poster {
  width: 65%;
}
@media (max-width: 575px) {
  .BusinessTypeBlog___15jq5 .topSection .description-card-poster {
    width: 100%;
  }
}
.BusinessTypeBlog___15jq5 .common-section-section {
  padding-bottom: 20px;
  padding-top: 20px;
}
@media (max-width: 575px) {
  .BusinessTypeBlog___15jq5 .common-section-section {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
}
.BusinessTypeBlog___15jq5 .scheduleYourFreeDemo .common-section-section {
  padding-bottom: 50px;
  padding-top: 70px;
}
@media (max-width: 575px) {
  .BusinessTypeBlog___15jq5 .scheduleYourFreeDemo .common-section-section {
    padding-bottom: unset;
    padding-top: unset;
  }
}
.BusinessTypeBlog___15jq5 .section-title {
  font-size: 30px !important;
  line-height: 40px !important;
}
@media (max-width: 575px) {
  .BusinessTypeBlog___15jq5 .section-title {
    font-size: 20px !important;
    line-height: normal !important;
  }
}
.BusinessTypeBlog___15jq5 .topSection {
  margin-bottom: 50px;
}
@media (max-width: 575px) {
  .BusinessTypeBlog___15jq5 .topSection {
    margin-bottom: 0px;
  }
}
.BusinessTypeBlog___15jq5 .advance-section {
  margin: 50px 0;
}
@media (max-width: 575px) {
  .BusinessTypeBlog___15jq5 .advance-section {
    margin: 30px 0;
  }
}
.BusinessTypeBlog___15jq5 .row-right-container___3y1Kb {
  white-space: pre-line;
}
@media (max-width: 575px) {
  .BusinessTypeBlog___15jq5 .row-right-container___3y1Kb {
    padding-left: 0px;
    padding-top: 20px;
  }
}
.BusinessTypeBlog___15jq5 .image___hcC7H {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}
.BusinessTypeBlog___15jq5 .blog-paragraph-section___2G1_k {
  font-family: 'Proxima Nova', 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px !important;
  color: #1D1B2E;
  margin-bottom: 30px;
}
.BusinessTypeBlog___15jq5 .blog-paragraph-section___2G1_k:last-child {
  margin-bottom: 0px;
}
.BusinessTypeBlogs___1d0aB .advance-section {
  margin: 50px 0;
}
@media (max-width: 575px) {
  .BusinessTypeBlogs___1d0aB .advance-section {
    margin: 30px 0;
  }
}
.BusinessTypeBlogs___1d0aB .advance-section .top-container .top-image {
  border-radius: 16px;
}
.BusinessTypeBlogs___1d0aB .production-advance-card:hover {
  box-shadow: unset !important;
}
.BusinessTypeBlogs___1d0aB .scheduleYourFreeDemo .common-section-section {
  padding-bottom: 50px;
  padding-top: 70px;
}
@media (max-width: 575px) {
  .BusinessTypeBlogs___1d0aB .scheduleYourFreeDemo .common-section-section {
    padding-bottom: unset;
    padding-top: unset;
  }
}
.businesstype-blogs .ant-layout-content {
  padding-top: 20px;
}
.CommonSectionLayout___3iHev {
  position: relative;
}
.CommonSectionLayout___3iHev .common-section-section {
  padding-top: 50px;
  margin-top: 0;
  margin-bottom: 0 !important;
  padding-bottom: 50px;
  position: relative;
}
@media (max-width: 575px) {
  .CommonSectionLayout___3iHev .common-section-section {
    padding-top: 40px !important;
    padding-bottom: 30px;
  }
}
.CommonSectionLayout___3iHev .common-section-section .section-content {
  padding-left: 64px;
  padding-right: 64px;
  position: relative;
  margin: 0 auto;
}
.CommonSectionLayout___3iHev .common-section-section .section-content .header {
  margin: 0 auto;
  max-width: 80%;
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .CommonSectionLayout___3iHev .common-section-section .section-content .header {
    max-width: 100%;
    margin: unset;
  }
}
.CommonSectionLayout___3iHev .common-section-section .section-content .header .section-title {
  margin-bottom: 5px;
}
.CommonSectionLayout___3iHev .common-section-section .section-content .section-title {
  margin-bottom: 30px;
  text-align: center;
}
