.reviewMain___mWSqz {
  width: 100vw;
  padding: 0 !important;
  margin: 0 auto;
  min-width: 100% !important;
  width: 100% !important;
  -webkit-text-size-adjust: none;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background: #c6c6c6;
  font-family: 'Proxima Nova', 'PingFang SC';
  height: calc(var(--app-height));
  position: relative;
}
.initialization___2WAX4 {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}
.initialization___2WAX4.willDestory___u8xlt {
  transition-property: all;
  transition-duration: 1000ms;
  transition-timing-function: ease-in-out;
  transform: scale(2);
  opacity: 0;
}
.initialization___2WAX4 .spln_loading___2hbps {
  width: 354px;
  height: 35px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-duration: 1500ms;
  transform-origin: center;
}
.reviewContainer___O7wDR {
  position: relative;
  margin: 0 !important;
  display: block;
  -webkit-text-size-adjust: none;
  max-width: 414px;
  width: 100%;
  padding: 0px 15px 68px 15px;
  padding: 0px 15px 0px 15px;
  height: 100%;
  overflow-y: hidden;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 576px) {
  .reviewContainer___O7wDR {
    max-width: 100vw;
  }
}
.reviewContainer___O7wDR .top___tgPh2 {
  height: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 15px;
  left: 15px;
  top: 5px;
}
@keyframes init_rotate_animation___PHORc {
  from {
    transform: rotate(0);
    opacity: 1;
  }
  to {
    transform: rotate(360deg);
    opacity: 1;
  }
}
.loadingOverLay___TEvg1 {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}
.loadingOverLay___TEvg1::before {
  content: '';
  display: block;
  width: 90px;
  height: 90px;
  background-color: #ffffff;
  border: 0px solid #cccccc;
  border-radius: 10px;
  box-shadow: 5px 5px 4px 1px #cccccc3b;
}
.loadingOverLay___TEvg1::after {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  background-size: contain;
  background-image: url('https://pos-static.chowbus.com/assets/review/spln_loading_icon.png');
  background-position: center;
  background-repeat: no-repeat;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-duration: 1000ms;
  transform-origin: center;
  animation-name: init_rotate_animation___PHORc;
}
