.table-shape-item___1zLfS {
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  cursor: move;
  background: #ffffff;
  height: 87px;
  padding: 0 20px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}
.table-shape-item___1zLfS.disabled___3OAmg {
  cursor: not-allowed;
}
.table-shape-item___1zLfS .shape-wrap {
  width: 48px;
  display: flex;
  justify-content: center;
}
.table-shape-item___1zLfS .table-shape-name {
  flex: 1;
  min-width: 0;
  margin-left: 14px;
  font-size: 18px;
}
