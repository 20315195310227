/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.blogs-page {
  background-color: #ffffff !important;
}
.blogs-page .ant-layout-content {
  padding-top: 10px !important;
}
@media (min-width: 767px) {
  .PageBlogs___1gdj1 {
    max-width: 1200px;
    margin: 0 auto;
  }
}
.PageBlogs___1gdj1 .topArticles___1TogE {
  width: 100%;
}
.PageBlogs___1gdj1 .topArticles___1TogE .common-section-section {
  padding-top: 10px;
  padding-bottom: 10px;
}
.PageBlogs___1gdj1 .otherArticles___3PByR {
  width: 100%;
}
.PageBlogs___1gdj1 .otherArticles___3PByR .common-section-section {
  padding-top: 30px;
  padding-bottom: 30px;
}
.PageBlogs___1gdj1 .otherArticles___3PByR .otherArticleTitle___3I9tQ {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}
.PageBlogs___1gdj1 .otherArticles___3PByR .otherArticleTitle___3I9tQ span {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  padding-left: 15px;
  line-height: 1.2;
  margin-bottom: 20px;
}
.PageBlogs___1gdj1 .otherArticles___3PByR .otherArticleTitle___3I9tQ::before {
  content: '';
  position: absolute;
  top: 3px;
  border-left: 8px solid #15d0c5;
  height: 28px;
  flex-shrink: 0;
}
.PageBlogs___1gdj1 .otherArticles___3PByR .articleReadMoreButton___1tKuV {
  margin-top: 30px;
  text-align: right;
}
@media (max-width: 575px) {
  .PageBlogs___1gdj1 .otherArticles___3PByR .articleReadMoreButton___1tKuV {
    text-align: center;
  }
}
.PageBlogs___1gdj1 .otherArticles___3PByR .articleReadMore___3fu9m {
  height: 44px;
  font-size: 16px;
  border-radius: 22px;
  background: #e00051;
  padding: 5px 24px !important;
}
@media (max-width: 575px) {
  .PageBlogs___1gdj1 .otherArticles___3PByR .articleReadMore___3fu9m {
    margin-top: 20px;
    flex: 1;
    border-radius: 22px;
    font-size: 18px;
    line-height: normal;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 100%;
  }
}
