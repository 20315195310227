.sales-report-container___1GlZ5 {
  background: #ffffff;
  margin-bottom: 10px;
}
.sales-report-container___1GlZ5 .sales-report-content {
  position: relative;
  width: 100%;
  height: 100%;
}
.sales-report-container___1GlZ5 .ant-card-type-inner .ant-card-extra {
  padding: 0;
}
.sales-report-container___1GlZ5 .sale-proportion-item {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #ffffff;
  display: flex;
  align-items: center;
}
.sales-report-container___1GlZ5 .sale-proportion-item .chart-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.sales-report-container___1GlZ5 .sale-proportion-item .chart-container .chart-wrapper {
  flex: 1;
  min-width: 0;
  margin-left: 15px;
}
@media (max-width: 576px) {
  .sales-report-container___1GlZ5 .sale-proportion-item {
    flex-direction: column;
  }
  .sales-report-container___1GlZ5 .sale-proportion-item .chart-container {
    flex-direction: column;
  }
  .sales-report-container___1GlZ5 .sale-proportion-item .chart-container .chart-wrapper {
    flex: auto;
    width: 100%;
  }
}
