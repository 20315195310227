.MasterDeviceIdSelector___XiuNm .ant-table-wrapper .ant-table-content {
  border: 0;
}
.MasterDeviceIdSelector___XiuNm .ant-table-wrapper .ant-table-content .ant-table-thead .ant-table-cell {
  padding: 18.5px 16px;
  font-family: 'Proxima Nova';
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  line-height: 18px;
  background-color: #fafafa;
}
.MasterDeviceIdSelector___XiuNm .ant-table-wrapper .ant-table-content .ant-table-thead .ant-table-cell:not(:last-child) {
  border-right: none;
}
.MasterDeviceIdSelector___XiuNm .ant-table-wrapper .ant-table-content .ant-table-tbody {
  background-color: #ffffff;
}
.MasterDeviceIdSelector___XiuNm .ant-table-wrapper .ant-table-content .ant-table-tbody .ant-table-row:nth-of-type(even) {
  background-color: #ffffff;
}
.MasterDeviceIdSelector___XiuNm .ant-table-wrapper .ant-table-content .ant-table-tbody .ant-table-row .ant-table-cell {
  padding: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 22px;
}
.MasterDeviceIdSelector___XiuNm .ant-table-wrapper .ant-table-content .ant-table-tbody .ant-table-row .ant-table-cell:first-child > div {
  display: flex;
}
.MasterDeviceIdSelector___XiuNm .ant-table-wrapper .ant-table-content .ant-table-tbody .ant-table-row .ant-table-cell:first-child > div .ant-radio-wrapper {
  height: 16px;
  margin: 0;
}
.MasterDeviceIdSelector___XiuNm .ant-table-wrapper .ant-table-content .ant-table-tbody .ant-table-row .ant-table-cell:not(:first-child) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.MasterDeviceIdSelector___XiuNm .ant-table-wrapper .ant-table-content .ant-table-tbody .ant-table-row .ant-table-cell:not(:last-child) {
  border-right: none;
}
