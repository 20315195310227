.VerifyPassword___29eT9 .ant-modal-body {
  padding: 70px 50px 50px 50px;
}
.VerifyPassword___29eT9 .modal-title {
  color: #000000;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.3px;
}
.VerifyPassword___29eT9 .email-text {
  margin: 36px 0;
  text-align: center;
  color: #1d1b2e;
  line-height: 24px;
}
