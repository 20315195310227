.OrderReviewContractNotActived___32j0E .ant-card-body {
  overflow: hidden;
  padding: 0px;
}
.OrderReviewContractNotActived___32j0E .group-content {
  margin: 0;
  display: flex;
  min-height: 180px;
  background-image: url('https://pos-static.chowbus.com/assets/order_reviews/order_reviews_contract_is_avtive_background_thin.png');
  background-size: 150px auto;
  background-position: calc(100% - 100px) bottom;
  background-repeat: no-repeat;
}
.OrderReviewContractNotActived___32j0E .page-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  position: relative;
  color: #e00051;
}
.OrderReviewContractNotActived___32j0E .page-title::before {
  content: '';
  width: 4px;
  height: 16px;
  background-color: #e00051;
  display: block;
  margin-right: 10px;
}
.isMobile___rwmmz .group-content {
  margin: 0;
  display: flex;
  flex-direction: column;
}
.isMobile___rwmmz .rightPoster___CsHN8 {
  padding: 10px 0px 0 0px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: unset;
  min-width: unset;
  width: 80%;
  margin: 0 auto;
}
.rightPoster___CsHN8 {
  max-width: 105px;
  min-width: 105px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reviewcontractIsActiveContainer___2tAIB {
  padding: 24px;
  width: 100%;
  height: 100%;
}
.reviewcontractIsActiveContainer___2tAIB.md___1I4c_ {
  background-image: none;
}
.reviewcontractIsActiveContainer___2tAIB .service_desc___1FUtS {
  color: #000000;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 10px 0;
  font-weight: 400;
}
.reviewcontractIsActiveContainer___2tAIB .emphasis___2awEG {
  color: #e00051;
}
.reviewcontractIsActiveContainer___2tAIB .customerSuccessManagerContractorInfo___7sXWG {
  margin-top: 10px;
  border: 1px solid #D9D9D9;
  width: auto;
  font-size: 14px;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  width: fit-content;
}
.reviewcontractIsActiveContainer___2tAIB .customerSuccessManagerContractorInfo___7sXWG .name___2iSqA {
  font-weight: 600;
  border-right: 1px solid #D9D9D9;
  padding-right: 20px;
}
.reviewcontractIsActiveContainer___2tAIB .customerSuccessManagerContractorInfo___7sXWG label {
  color: #8C8C8C;
  padding: 0 15px 0 20px;
}
.reviewcontractIsActiveContainer___2tAIB .customerSuccessManagerContractorInfo___7sXWG .email___1uzcC {
  display: flex;
}
.reviewcontractIsActiveContainer___2tAIB .customerSuccessManagerContractorInfo___7sXWG .mobilephone___16ibD {
  display: flex;
}
.reviewcontractIsActiveContainer___2tAIB.isMobile___rwmmz .customerSuccessManagerContractorInfo___7sXWG {
  width: 100%;
  flex-direction: column;
}
.reviewcontractIsActiveContainer___2tAIB.isMobile___rwmmz .customerSuccessManagerContractorInfo___7sXWG .name___2iSqA {
  border-right: 0;
}
.reviewcontractIsActiveContainer___2tAIB.isMobile___rwmmz .customerSuccessManagerContractorInfo___7sXWG label {
  padding-left: 0;
  padding-right: 0;
  width: 60px;
  margin-right: 10px;
}
