/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.robotsOverview___2xXq0 .overview {
  padding: 0 20px;
}
@media (max-width: 575px) {
  .robotsOverview___2xXq0 .overview {
    padding: 0 10px;
  }
}
@media (max-width: 575px) {
  .robotsOverview___2xXq0 .overview .rutechRobotsOverviewContainer {
    overflow-x: auto;
  }
}
.robotsOverview___2xXq0 .overview img {
  object-fit: contain;
  width: 100%;
  margin-top: 60px;
}
@media (max-width: 575px) {
  .robotsOverview___2xXq0 .overview img {
    margin-top: 30px;
    max-height: 500px;
    object-fit: cover;
    width: auto;
  }
}
.robotsOverview___2xXq0 .overview .action-button {
  height: 56px;
  line-height: 56px;
  font-size: 24px;
  border-radius: 34px;
  background: #e00051;
  padding: 16px 24px;
}
@media (max-width: 575px) {
  .robotsOverview___2xXq0 .overview .action-button {
    margin-top: 20px;
    flex: 1;
    font-size: 18px;
    line-height: normal;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}
