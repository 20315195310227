/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.SalesCategoryPageForm___2Vz18 .ant-form-item {
  margin-bottom: 0;
}
.SalesCategoryPageTips___3I52V {
  padding: 10px;
  font-size: 14px;
  background-color: #FFFAE7;
  margin-bottom: 15px;
}
.SalesCategoryPageTips___3I52V ol {
  margin-block-end: 0;
  padding-inline-start: 30px;
}
.SalesCategoryTable___vpmMW {
  margin-top: 16px;
  border: 1px solid #e5e5e5;
}
.SalesCategoryTable___vpmMW .ant-table-body {
  max-height: 450px;
}
.BindDishesSettingsWrapper___2nT9Z {
  margin-top: 16px;
  overflow: hidden;
}
.BindDishesSettingsWrapper___2nT9Z .title {
  font-size: 20px;
  line-height: 32px;
  white-space: nowrap;
  margin-right: 30px;
  font-weight: bold;
}
