.pageHomeRole___2ZXTc .role-card .ant-card-body {
  position: relative;
  height: 131px;
  padding: 18px 22px;
}
.pageHomeRole___2ZXTc .role-card .ant-card-body .member-count {
  line-height: 1;
  vertical-align: top;
  margin-left: 6px;
}
.pageHomeRole___2ZXTc .role-card .ant-card-body .delete-icon {
  position: absolute;
  bottom: 10px;
  right: 6px;
  cursor: pointer;
}
