.TipsAllocation___1wWfh {
  margin-top: 20px;
}
.TipsAllocation___1wWfh .section .section-title {
  font-size: 18px;
  line-height: 1;
}
.TipsAllocation___1wWfh .section .section-content {
  margin-top: 24px;
}
.TipsAllocation___1wWfh .section + .section {
  margin-top: 24px;
}
.TipsAllocation___1wWfh .rule-description {
  position: relative;
  padding: 16px;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  background: #ffffff;
}
.TipsAllocation___1wWfh .rule-description:before {
  content: "";
  position: absolute;
  left: 9px;
  top: -14px;
  width: 28px;
  height: 28px;
  z-index: 1;
  background: #ffffff;
  border-radius: 50%;
}
.TipsAllocation___1wWfh .rule-description:after {
  content: "";
  position: absolute;
  left: 15px;
  top: -8px;
  width: 16px;
  height: 16px;
  z-index: 2;
  background-image: url('https://pos-static.chowbus.com/assets/icon_info.png');
  background-size: 100%;
}
.TipsAllocation___1wWfh .rule-description ul {
  padding: 2em 0 2em 2em;
}
.TipsAllocation___1wWfh .rule-description a:hover {
  text-decoration: underline;
}
.TipsAllocation___1wWfh .rule-description + .rule-description {
  margin-top: 22px;
}
