.sectionSales___4is6y {
  margin-block-end: 20px;
  width: 100%;
  overflow: hidden;
}
.sectionSales___4is6y .section-sales_filter {
  margin-block: 20px;
}
.sectionSales___4is6y .ant-tree-select {
  z-index: 3!important;
}
.sectionSales___4is6y .ant-table .ant-table-tbody .ant-table-cell .list-child {
  margin-left: 16px;
}
