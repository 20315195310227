.DaysOfWeek___3DUot {
  width: 100%;
}
.DaysOfWeek___3DUot .day-picker-wrapper:first-child .day-picker {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.DaysOfWeek___3DUot .day-picker-wrapper:last-child .day-picker {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.DaysOfWeek___3DUot .day-picker {
  padding: 3px 0;
  text-align: center;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
.DaysOfWeek___3DUot .day-picker.selected {
  border-color: #E00051;
  color: #E00051;
  background-color: #FFEDF3;
}
.DaysOfWeek___3DUot .day-picker.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
