/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.PrintoutsSettings___3Wcke .vertical-radio-group {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  padding-left: 30px;
}
.PrintoutsSettings___3Wcke .vertical-radio-group.ant-radio-group label + label {
  margin-top: 10px;
}
.PrintoutsSettings___3Wcke .card-group .ant-card-body {
  padding-bottom: 0;
}
.PrintoutsSettings___3Wcke .card-group + .card-group {
  margin-top: 16px;
}
@media (min-width: 576px) {
  .PrintoutsSettings___3Wcke .separate-configuration .ant-form-item:not(.separate-line):not(.inline) .ant-form-item-control {
    text-align: right;
  }
}
.PrintoutsSettings___3Wcke .section-name {
  font-size: 18px;
  margin-bottom: 16px;
}
.PrintoutsSettings___3Wcke .section-name.small {
  font-size: 16px;
  color: #000000;
}
.PrintoutsSettings___3Wcke .section-label {
  font-size: 16px;
  margin-bottom: 14px;
}
.PrintoutsSettings___3Wcke .ant-form-item {
  margin-bottom: 16px;
}
.PrintoutsSettings___3Wcke .ant-radio-wrapper {
  white-space: normal;
}
.PrintoutsSettings___3Wcke .ant-form-item-label {
  text-align: left;
}
.PrintoutsSettings___3Wcke .ant-form-item-label > label {
  height: auto;
  white-space: normal;
}
.PrintoutsSettings___3Wcke .ant-form-item-label > label .helperText {
  color: #e00051;
  margin-left: 4px;
}
.PrintoutsSettings___3Wcke .ant-form-item-label > label .description {
  color: #5e5e5e;
}
.PrintoutsSettings___3Wcke .ant-form-item-label > label .anticon {
  line-height: 1;
}
.PrintoutsSettings___3Wcke .percentage-item .ant-form-item-label > label {
  height: 32px;
  white-space: nowrap;
}
.PrintoutsSettings___3Wcke .settings-block .settings-title {
  margin-bottom: 1px;
}
.PrintoutsSettings___3Wcke .settings-block .settings-section .settings-section-header {
  margin-bottom: 18px;
}
.PrintoutsSettings___3Wcke .settings-block .settings-section .settings-section-header .section-description {
  color: #92929f;
}
.PrintoutsSettings___3Wcke .settings-block .settings-section .settings-section-content {
  margin-left: 30px;
}
.PrintoutsSettings___3Wcke .settings-block .settings-section .settings-section-content .ant-radio-group {
  display: block;
}
.PrintoutsSettings___3Wcke .settings-block .settings-section .settings-section-content .secondary-title {
  margin-bottom: 16px;
}
.PrintoutsSettings___3Wcke .settings-block .settings-section .settings-section-content .settings-options .option-item + .option-item {
  margin-top: 16px;
}
.PrintoutsSettings___3Wcke .settings-block .settings-section .settings-section-content .settings-options .ant-radio-wrapper {
  display: flex;
}
.PrintoutsSettings___3Wcke .settings-block .settings-section .settings-section-content .settings-options .ant-radio-wrapper .ant-radio ~ span {
  flex: 1;
  min-width: 0;
  margin-top: -3px;
}
.PrintoutsSettings___3Wcke .settings-block .settings-section + .settings-section {
  margin-top: 16px;
}
.pageTitle___tP0SH {
  font-size: 16px;
}
.pageTitle___tP0SH h2 {
  margin-bottom: 0;
}
.pageTitle___tP0SH .ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}
.pageTitle___tP0SH .ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  padding: 0;
  font-size: 20px;
}
