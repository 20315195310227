.OnlineOrderingPreparation___1fn9d .setting-item {
  display: flex;
  align-items: center;
}
.OnlineOrderingPreparation___1fn9d .setting-item .setting-item-name {
  width: 147px;
  font-size: 14px;
  line-height: 20px;
  color: #000;
}
.OnlineOrderingPreparation___1fn9d .setting-item .input-group {
  display: table;
  width: 120px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}
.OnlineOrderingPreparation___1fn9d .setting-item .input-group .input {
  display: table-cell;
}
.OnlineOrderingPreparation___1fn9d .setting-item .input-group .input .ant-input-number-input {
  padding: 0 6px;
  text-align: right;
}
.OnlineOrderingPreparation___1fn9d .setting-item .input-group .addon {
  display: block;
  color: #8C8C8C;
}
.OnlineOrderingPreparation___1fn9d .setting-item + .setting-item {
  margin-top: 12px;
}
.GoogleMapContainer___1DK-6 {
  width: 739px;
  height: 413px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@keyframes loading___3-whh {
  from {
    transform: rotate(0) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1);
  }
  to {
    transform: rotate(360deg) scale(1);
  }
}
.mapContainer___1tWGt {
  width: 100%;
  height: 100%;
}
.markerLabel___2TXjC {
  background-color: #ffffff;
  color: #E00051;
  transform: translateY(30px);
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 220px;
}
