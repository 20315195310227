.orientation-tool-container___cGKvl {
  position: relative;
  width: 100%;
  height: 100%;
}
.orientation-tool-container___cGKvl .rotate-handle {
  position: absolute;
  top: 6px;
  right: 10px;
  cursor: pointer;
}
.orientation-tool-container___cGKvl .table-shape {
  background: #c4c4c4;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
}
.orientation-tool-container___cGKvl .table-shape.round-table {
  width: 84px;
  height: 84px;
  border-radius: 50%;
}
.orientation-tool-container___cGKvl .table-shape.square-table {
  width: 84px;
  height: 84px;
}
.orientation-tool-container___cGKvl .table-shape.rect-table {
  width: 63px;
  height: 84px;
}
.orientation-tool-container___cGKvl .table-shape.rect-table.horizontal {
  width: 84px;
  height: 63px;
}
.orientation-tool-container___cGKvl .table-shape.oval-table {
  width: 84px;
  height: 63px;
  border-radius: 21px;
}
.orientation-tool-container___cGKvl .table-shape.oval-table.horizontal {
  width: 63px;
  height: 84px;
}
.orientation-tool-container___cGKvl .table-shape.bar-stool {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
.orientation-tool-container___cGKvl .table-shape.divider {
  width: 11px;
  height: 100px;
  background: #ffffff;
  border: 1px solid #000000;
}
.orientation-tool-container___cGKvl .table-shape.divider.horizontal {
  width: 100px;
  height: 11px;
}
.orientation-tool-container___cGKvl .table-shape.booth {
  width: 42px;
  height: 84px;
  background: transparent;
  border-left: 10px solid #c4c4c4;
  border-top: 10px solid #c4c4c4;
  border-bottom: 10px solid #c4c4c4;
  border-radius: 42px 0 0 42px;
}
.orientation-tool-container___cGKvl .table-text {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}
