.orderReviewsDetailFilterOuter___3C77s .ant-spin-nested-loading {
  overflow: hidden;
}
.orderReviewsDetailFilterOuter___3C77s .ant-table-content {
  border: 0;
}
.orderReviewsDetailFilterOuter___3C77s .ant-pagination.ant-table-pagination {
  margin-bottom: 0;
}
.orderReviewsDetailFilterOuter___3C77s .ant-table-thead > tr > th {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}
.orderReviewsDetailFilterOuter___3C77s .ant-table.ant-table-scroll-horizontal .ant-table-tbody tr:nth-of-type(odd),
.orderReviewsDetailFilterOuter___3C77s .ant-table:not(.ant-table-scroll-horizontal) .ant-table-tbody tr:nth-of-type(even) {
  background-color: transparent;
}
.orderReviewsDetailFilterOuter___3C77s .ant-table.ant-table-small .ant-table-thead > tr > th {
  padding: 16px;
}
.orderReviewsDetailFilterOuter___3C77s .ant-table-small .ant-table-tbody > tr > td {
  padding: 16px;
}
.orderReviewsDetailFilterOuter___3C77s .ant-card-body {
  overflow: unset;
  padding: 24px 16px;
}
.orderReviewsDetailFilterOuter___3C77s .ant-card-body::after {
  content: none;
}
.orderReviewsDetailFilterOuter___3C77s .ant-table-wrapper::after {
  content: none;
}
.orderReviewsDetailFilter___355EG {
  margin-bottom: 24px;
}
.orderReviewsDetailFilter___355EG .ant-card-body {
  overflow: unset;
}
.orderReviewsDetailFilter___355EG .filter-item-wrap {
  position: relative;
}
.orderReviewsDetailFilter___355EG .filter-item-wrap .form-item-title {
  color: #8C8C8C;
  margin-bottom: 8px;
}
.orderReviewsDetailFilter___355EG .filter-item-wrap .clear-action {
  position: absolute;
  top: 0;
  right: 0;
}
.orderReviewsDetailFilter___355EG .filter-button {
  width: 87px;
  padding: 10px 0;
  margin-top: 29px;
  line-height: 20px;
}
.orderReviewsDetailFilter___355EG .filter-button:hover,
.orderReviewsDetailFilter___355EG .filter-button:active {
  color: rgba(0, 0, 0, 0.85);
}
.reviewContentSection___2xDls {
  display: flex;
  flex-direction: column;
}
.reviewContentSection___2xDls .reviewContent___2uKHo {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #000000;
}
.reviewContentSection___2xDls .reviewContentImgs___3Ba2W {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
}
.reviewContentSection___2xDls .reviewContentImgs___3Ba2W .reviewContentImgContainer___2FFjj {
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 60px;
  margin-right: 8px;
}
.reviewContentSection___2xDls .reviewContentImgs___3Ba2W .reviewContentImgContainer___2FFjj:last-child {
  margin-right: 0;
}
.reviewContentSection___2xDls .reviewContentImgs___3Ba2W .reviewContentImgContainer___2FFjj img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
