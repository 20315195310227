.excludingTags___3CXnN {
  padding: 10px;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px 10px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.excludingTags___3CXnN .excludingTag___2-G_Q {
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
  line-height: normal;
  padding: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 10px;
}
.excludingCategoryOrMealModal___fRwkt .ant-modal-close-x {
  display: none;
  pointer-events: none;
}
.excludingCategoryOrMealModal___fRwkt .ant-collapse.ant-collapse-borderless {
  background-color: #ffffff;
}
.excludingCategoryOrMealModal___fRwkt .ant-collapse.ant-collapse-borderless .ant-collapse-header {
  background-color: #f5f5f5;
}
.excludingCategoryOrMealModal___fRwkt .ant-collapse.ant-collapse-borderless .ant-collapse-item {
  border-bottom: 0;
  margin-bottom: 10px;
}
.excludingCategoryOrMealModal___fRwkt .ant-collapse .ant-collapse-content {
  background-color: #ffffff;
}
.excludingCategoryOrMealModal___fRwkt .ant-checkbox-group .ant-checkbox-group-item {
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 18px;
}
.excludingCategoryOrMealModal___fRwkt .section___rIokq {
  margin-bottom: 20px;
}
.excludingCategoryOrMealModal___fRwkt .section___rIokq .header___vMVRn {
  color: #1D1B2E;
  font-weight: 800;
  margin: 5px 0 10px 0;
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  font-family: 'Proxima Nova';
}
.excludingCategoryOrMealModal___fRwkt .section___rIokq .content___21Oal {
  max-height: 300px;
  overflow-y: auto;
}
.excludingCategoryOrMealModal___fRwkt .actionLink___k0k5f {
  color: #E00051;
  padding: 0 5px;
  cursor: pointer;
}
.excludingCategoryOrMealModal___fRwkt .collapseHeader___2omsd {
  font-size: 700;
  color: #1D1B2E;
  font-size: 18px;
}
.excludingCategoryOrMealModal___fRwkt .categoryOrMealSelector___2pH3D {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
}
.excludingCategoryOrMealModal___fRwkt .categoryOrMealSelector___2pH3D .menuList___3HWGP {
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #f5f5f5;
  padding-right: 20px;
  margin-right: 20px;
}
.excludingCategoryOrMealModal___fRwkt .categoryOrMealSelector___2pH3D .menuList___3HWGP .menuItem___1VUIW {
  max-width: 200px;
  color: #92929F;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
}
.excludingCategoryOrMealModal___fRwkt .categoryOrMealSelector___2pH3D .menuList___3HWGP .menuItem___1VUIW.active___Qvs0n {
  color: #E00051;
  justify-content: center;
  background-color: #FFEDF3;
}
.excludingCategoryOrMealModal___fRwkt .categoryOrMealSelector___2pH3D .categoryList___9NOP8 {
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #f5f5f5;
  padding-right: 20px;
  margin-right: 20px;
}
.excludingCategoryOrMealModal___fRwkt .categoryOrMealSelector___2pH3D .categoryList___9NOP8 .categoryItem___riq9w {
  max-width: 200px;
  color: #92929F;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
}
.excludingCategoryOrMealModal___fRwkt .categoryOrMealSelector___2pH3D .categoryList___9NOP8 .categoryItem___riq9w.active___Qvs0n {
  color: #92929F;
  justify-content: center;
  background-color: #FFEFDC;
}
.excludingCategoryOrMealModal___fRwkt .categoryOrMealSelector___2pH3D .categoryOptions___1aEeC {
  flex: 3;
}
.excludingCategoryOrMealModal___fRwkt .categoryOrMealSelector___2pH3D .mealOptions___2s--T {
  flex: 3;
}
