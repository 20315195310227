.BulkPriceUpdates___3Re5f .menu-zone {
  margin-top: 12px;
}
.BulkPriceUpdates___3Re5f .menu-header {
  display: flex;
  padding: 12px 0 12px 12px;
}
.BulkPriceUpdates___3Re5f .menu-header .menu-name {
  flex: 1;
  min-width: 0;
  line-height: 32px;
}
@media (max-width: 576px) {
  .BulkPriceUpdates___3Re5f .menu-header {
    flex-direction: column;
  }
}
.BulkPriceUpdates___3Re5f .menu-action-zone {
  padding: 24px;
  margin: 12px 0 16px 0;
  background: #ffffff;
}
.BulkPriceUpdates___3Re5f .menu-action-zone .action-summary {
  min-height: 25px;
}
.BulkPriceUpdates___3Re5f .input-wrapper {
  position: relative;
}
.BulkPriceUpdates___3Re5f .input-wrapper .ant-input-number-handler-wrap {
  display: none;
}
.BulkPriceUpdates___3Re5f .input-wrapper .icon-clear {
  position: absolute;
  right: 6px;
  top: 5px;
  opacity: 0.4;
  z-index: 10;
  cursor: pointer;
}
.BulkPriceUpdates___3Re5f .input-wrapper .icon-clear.percentage {
  right: 40px;
}
.BulkPriceUpdates___3Re5f .input-wrapper .icon-clear:hover {
  opacity: 0.6;
}
