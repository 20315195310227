.AmountCollectedTooltipContent___1AZ80 {
  padding: 30px;
}
.AmountCollectedTooltipContent___1AZ80 .list-item {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
}
.AmountCollectedTooltipContent___1AZ80 .list-item.bolder {
  font-weight: 600;
}
