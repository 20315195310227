.restaurant-layout___gB6xi {
  position: relative;
  display: flex;
  height: calc(100vh - 142px);
  overflow: hidden;
}
.restaurant-layout___gB6xi .editable-zone {
  position: relative;
  flex: 1;
  min-width: 0;
  height: 100%;
  margin-right: 15px;
  overflow: auto;
}
.restaurant-layout___gB6xi .editable-zone .conflict-tips {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
}
.restaurant-layout___gB6xi .config-zone {
  position: relative;
  width: 240px;
  height: 100%;
  background: #ffffff;
  padding: 20px 10px;
}
.restaurant-layout___gB6xi .config-zone .ant-form {
  height: 100%;
  overflow-y: auto;
}
.restaurant-layout___gB6xi .config-zone .section-status .ant-row {
  flex-direction: row;
  align-items: center;
}
.restaurant-layout___gB6xi .config-zone .section-status .ant-row .ant-form-item-control {
  width: auto;
  align-items: flex-end;
}
.restaurant-layout___gB6xi .config-zone .section-status .ant-row .ant-switch {
  transform: scale(1.2) translate(-5px, -5px);
}
.restaurant-layout___gB6xi .config-zone .section-status .ant-row .ant-switch.ant-switch-checked {
  background-color: #a3c95e;
}
.restaurant-layout___gB6xi .canvas-container {
  position: relative;
  overflow: auto;
}
.restaurant-layout___gB6xi .canvas-container .no-shapes {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  text-align: center;
  color: #000000;
}
.restaurant-layout___gB6xi .canvas-container .conflict-tips {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
}
.restaurant-layout___gB6xi .edit-section .section-item {
  padding: 0 6px;
  margin-bottom: 10px;
}
.restaurant-layout___gB6xi .edit-section .section-item h2 {
  color: #e00041;
  font-size: 20px;
}
.restaurant-layout___gB6xi .edit-section .section-item .table-widgets-wrap {
  margin: 0 -6px;
}
.add-table-modal___iXqZl .table-wrap {
  position: relative;
  width: 180px;
  height: 180px;
  border: 1px solid #9b9b9b;
  border-radius: 4px;
}
