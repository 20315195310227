.RelatedMeals___33Rco .related-meals-header {
  line-height: 22px;
  color: #8C8C8C;
  margin-bottom: 8px;
}
.RelatedMeals___33Rco .related-meals-content .inner-content {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 4px;
}
.RelatedMeals___33Rco .related-meals-content .inner-content .meal-item {
  display: inline-flex;
  align-items: center;
  padding: 5px 12px;
  height: 32px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  background: #F5F5F5;
}
.RelatedMeals___33Rco .related-meals-content .inner-content .meal-item .meal-title {
  color: #000;
  line-height: 22px;
}
.RelatedMeals___33Rco .related-meals-content .inner-content .meal-item .icon-remove {
  cursor: pointer;
  margin-left: 8px;
  line-height: 1;
}
.RelatedMeals___33Rco .expand-toggle {
  margin-top: 8px;
  text-align: center;
  cursor: pointer;
}
.RelatedMeals___33Rco .expand-toggle:hover {
  box-shadow: 0px 0 2px rgba(0, 0, 0, 0.3);
}
