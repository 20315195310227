.OrderDeletionWarningModal___1xEse .ant-modal-body {
  padding: 50px;
}
.OrderDeletionWarningModal___1xEse .icon-wrapper {
  text-align: center;
}
.OrderDeletionWarningModal___1xEse .icon-wrapper .waring-icon {
  display: inline-block;
  width: 141px;
  height: 140px;
  background-image: url('https://pos-static.chowbus.com/assets/icon_warning.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.OrderDeletionWarningModal___1xEse .prompt-title {
  margin: 24px 0;
  text-align: center;
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.3px;
}
.OrderDeletionWarningModal___1xEse .warning-title {
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  text-align: center;
}
.OrderDeletionWarningModal___1xEse .warning-content {
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  text-align: center;
}
.OrderDeletionWarningModal___1xEse .warning-actions {
  margin-top: 24px;
  text-align: center;
}
