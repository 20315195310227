.OnlineOrderingPreparation___1GeDi .setting-item {
  display: flex;
  align-items: center;
}
.OnlineOrderingPreparation___1GeDi .setting-item .setting-item-name {
  width: 147px;
  font-size: 14px;
  line-height: 20px;
  color: #000;
}
.OnlineOrderingPreparation___1GeDi .setting-item .input-group {
  display: table;
  width: 120px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}
.OnlineOrderingPreparation___1GeDi .setting-item .input-group .input {
  display: table-cell;
}
.OnlineOrderingPreparation___1GeDi .setting-item .input-group .input .ant-input-number-input {
  padding: 0 6px;
  text-align: right;
}
.OnlineOrderingPreparation___1GeDi .setting-item .input-group .addon {
  display: block;
  color: #8C8C8C;
}
.OnlineOrderingPreparation___1GeDi .setting-item + .setting-item {
  margin-top: 12px;
}
