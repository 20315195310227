/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.DescriptionCard___cHURu {
  display: flex;
  flex-wrap: wrap;
}
.DescriptionCard___cHURu .description-info {
  order: 1;
  width: 50%;
}
@media (max-width: 575px) {
  .DescriptionCard___cHURu .description-info {
    display: block;
  }
}
.DescriptionCard___cHURu .description-info .badge {
  margin-bottom: 16px;
}
.DescriptionCard___cHURu .description-info .badge:before {
  content: '';
  display: block;
  width: 80px;
  height: 16px;
  background: #e00051;
}
.DescriptionCard___cHURu .description-info .description-title {
  margin-bottom: 16px;
  padding-right: 15px;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: #1D1B2E;
}
.DescriptionCard___cHURu .description-info .description-content {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: #92929F;
}
.DescriptionCard___cHURu .description-info .actions {
  margin-top: 32px;
  display: flex;
  margin-bottom: 20px;
}
@media (max-width: 575px) {
  .DescriptionCard___cHURu .description-info .actions {
    width: 100%;
    justify-content: space-between;
  }
}
.DescriptionCard___cHURu .description-info .actions .action-button {
  height: 56px;
  line-height: 56px;
  font-size: 24px;
  border-radius: 34px;
  background: #e00051;
  padding: 16px 24px;
}
@media (max-width: 575px) {
  .DescriptionCard___cHURu .description-info .actions .action-button {
    margin-top: 20px;
    flex: 1;
    font-size: 18px;
    line-height: normal;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}
.DescriptionCard___cHURu .description-info .actions .action-button-outline {
  height: 56px;
  line-height: 56px;
  font-size: 24px;
  border-radius: 34px;
  color: #e00051;
  padding: 16px 24px;
  border: 2px solid #e00051;
  display: inline-block;
}
@media (max-width: 575px) {
  .DescriptionCard___cHURu .description-info .actions .action-button-outline {
    display: block;
    width: 100%;
  }
}
.DescriptionCard___cHURu .description-info .actions .action-button-outline {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
}
@media (max-width: 575px) {
  .DescriptionCard___cHURu .description-info .actions .action-button-outline {
    margin-top: 20px;
    flex: 1;
    font-size: 18px;
    line-height: normal;
    height: 44px;
    padding: 0;
  }
}
.DescriptionCard___cHURu .description-card-poster {
  padding-left: 60px;
  order: 2;
  width: 50%;
  position: relative;
}
.DescriptionCard___cHURu .description-card-poster.clickDisable {
  pointer-events: none;
}
.DescriptionCard___cHURu .description-card-poster .playerTopCover-- {
  padding-left: 60px;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DescriptionCard___cHURu .description-card-poster .playerTopCover--:after {
  display: block;
  content: '';
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: red;
  cursor: pointer;
  background-image: url('https://pos-static.chowbus.com/assets/video_play_button_white_bk.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.DescriptionCard___cHURu .description-card-poster .playerTopCover--.playing:after {
  display: none;
}
.DescriptionCard___cHURu .description-card-poster .poster-container-- {
  width: 100%;
  height: 0;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  display: flex;
}
.DescriptionCard___cHURu .description-card-poster .poster-img-container {
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  width: 100%;
}
.DescriptionCard___cHURu .description-card-poster .poster-img-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media (max-width: 575px) {
  .DescriptionCard___cHURu .description-info {
    order: 3 !important;
    width: 100%;
    margin-top: 16px;
  }
  .DescriptionCard___cHURu .description-info .badge {
    display: none;
  }
  .DescriptionCard___cHURu .description-info .description-title {
    font-size: 27px;
    line-height: normal;
  }
  .DescriptionCard___cHURu .description-info .description-content {
    font-size: 19px;
    line-height: normal;
  }
  .DescriptionCard___cHURu .description-info .actions {
    margin-top: 16px;
  }
  .DescriptionCard___cHURu .description-card-poster {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .DescriptionCard___cHURu .description-card-poster .playerTopCover {
    padding-left: 0px;
  }
}
