/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ProductAdvanceCard___BwU8-.through___2MYUI .production-advance-card {
  border-radius: 0 !important;
}
.ProductAdvanceCard___BwU8-.through___2MYUI .production-advance-card.row .top-container {
  flex: 476;
}
.ProductAdvanceCard___BwU8-.through___2MYUI .production-advance-card.row .description-container {
  flex: 900;
}
.ProductAdvanceCard___BwU8-.through___2MYUI .production-advance-card img {
  border-radius: 16px;
}
.ProductAdvanceCard___BwU8-.through___2MYUI .production-advance-card:hover {
  box-shadow: unset;
}
.ProductAdvanceCard___BwU8- .production-advance-card:hover {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
}
.ProductAdvanceCard___BwU8- .production-advance-card {
  background: #FFFFFF;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
.ProductAdvanceCard___BwU8- .production-advance-card.row {
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.ProductAdvanceCard___BwU8- .production-advance-card.row .top-container {
  flex: 704;
}
.ProductAdvanceCard___BwU8- .production-advance-card.row .description-container {
  flex: 678;
  padding-left: 48px;
  padding-right: 20px;
}
.ProductAdvanceCard___BwU8- .production-advance-card.row .ant-col {
  padding-right: 0px;
  padding-left: 0px;
}
.ProductAdvanceCard___BwU8- .production-advance-card.row .ant-col:nth-child(2n) {
  padding-right: 0 !important;
}
.ProductAdvanceCard___BwU8- .production-advance-card.row .ant-col:nth-child(2n+1) {
  padding-left: 0 !important;
}
.ProductAdvanceCard___BwU8- .production-advance-card.row .top-container .top-image {
  height: 100%;
}
.ProductAdvanceCard___BwU8- .production-advance-card .top-container {
  overflow: hidden;
  width: 100%;
  flex: 425;
}
.ProductAdvanceCard___BwU8- .production-advance-card .top-container .top-image {
  width: 100%;
  object-fit: cover;
}
.ProductAdvanceCard___BwU8- .production-advance-card .description-container {
  flex: 269;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  min-height: 219px;
  overflow: hidden;
}
@media (max-width: 575px) {
  .ProductAdvanceCard___BwU8- .production-advance-card .description-container {
    max-height: unset;
    min-height: unset;
    padding: 12px;
  }
}
.ProductAdvanceCard___BwU8- .production-advance-card .description-container .title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #1D1B2E;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
}
@media (max-width: 575px) {
  .ProductAdvanceCard___BwU8- .production-advance-card .description-container .title {
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 4px;
  }
}
.ProductAdvanceCard___BwU8- .production-advance-card .description-container .subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #92929F;
  white-space: pre-line;
}
@media (max-width: 575px) {
  .ProductAdvanceCard___BwU8- .production-advance-card .description-container .subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 30px;
  }
}
.ProductAdvanceCard___BwU8- .production-advance-card .description-container .keypoint-list {
  width: 100%;
  padding-inline-start: 20px;
  font-family: 'Proxima Nova', 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #1D1B2E;
  margin-bottom: 0;
}
.ProductAdvanceCard___BwU8- .production-advance-card .description-container .keypoint-list .keypoint-item {
  width: 100%;
  margin: 20px 0 ;
}
@media (max-width: 575px) {
  .ProductAdvanceCard___BwU8- .production-advance-card .description-container .keypoint-list {
    font-size: 14px;
  }
  .ProductAdvanceCard___BwU8- .production-advance-card .description-container .keypoint-list .keypoint-item {
    width: 100%;
    margin: 5px 0 ;
  }
}
.ProductAdvanceCard___BwU8- .link-more .link-more-wrapper::after {
  content: '';
  width: 8px;
  height: 12px;
  background-image: url('https://pos-static.chowbus.com/home/link_more_icon.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
  margin-left: 10px;
}
@media (max-width: 575px) {
  .ProductAdvanceCard___BwU8- .link-more .link-more-wrapper::after {
    width: 8px;
    height: 14px;
  }
}
.ProductAdvanceCard___BwU8- .link-more {
  flex: 1;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #E00051;
}
@media (max-width: 575px) {
  .ProductAdvanceCard___BwU8- .link-more {
    font-size: 20px;
    line-height: 24px;
  }
}
.ProductAdvanceCard___BwU8- .link-more-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
