.KdsServerUpdatedModal___2rXSH .ant-modal-content .ant-modal-body {
  padding: 40px;
}
.KdsServerUpdatedModal___2rXSH .ant-modal-content .ant-modal-body .image-wrapper {
  text-align: center;
}
.KdsServerUpdatedModal___2rXSH .ant-modal-content .ant-modal-body .image-wrapper .anticon {
  color: #faad14;
  font-size: 64px;
  background: radial-gradient(circle at 50%, #1d1b2e, #1d1b2e 60%, transparent 60%, transparent 100%);
}
.KdsServerUpdatedModal___2rXSH .ant-modal-content .ant-modal-body .title-wrapper {
  padding-top: 20px;
  padding-bottom: 8px;
  color: #000000;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
}
.KdsServerUpdatedModal___2rXSH .ant-modal-content .ant-modal-body .description-wrapper {
  margin-top: 12px;
  color: #000000;
  font-size: 16px;
  line-height: 24px;
}
.KdsServerUpdatedModal___2rXSH .ant-modal-content .ant-modal-body .button-wrapper {
  margin-top: 20px;
  text-align: center;
}
.KdsServerUpdatedModal___2rXSH .ant-modal-content .ant-modal-body .button-wrapper .ant-btn {
  width: 200px;
  height: 40px;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  border-radius: 34px;
}
