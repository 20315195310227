/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ProductFeatures___10KaJ {
  padding: 50px 0px;
}
@media (max-width: 575px) {
  .ProductFeatures___10KaJ {
    padding: 10px 0px;
  }
}
.ProductFeatures___10KaJ.dark___1izcW {
  background-color: #1D1B2E;
}
.ProductFeatures___10KaJ.dark___1izcW .section-title {
  color: #FFFFFF;
}
.ProductFeatures___10KaJ.dark___1izcW .section-subtitle {
  color: #FFFFFF;
}
.ProductFeatures___10KaJ.dark___1izcW .feature-item .title {
  color: #FFFFFF;
}
.ProductFeatures___10KaJ.dark___1izcW .common-description {
  color: #FFFFFF;
}
.ProductFeatures___10KaJ .feature-section {
  margin-bottom: 0 !important;
}
.ProductFeatures___10KaJ .feature-section .section-content {
  margin: 0 auto -24px;
}
.ProductFeatures___10KaJ .feature-section .section-content .section-title {
  margin-bottom: 48px !important;
  text-align: center;
  white-space: pre-line;
  font-family: 'Proxima Nova', 'PingFang SC';
}
.ProductFeatures___10KaJ .feature-section .section-content .section-subtitle {
  text-align: center;
  white-space: pre-line;
  font-family: 'Proxima Nova', 'PingFang SC';
  font-size: 18px;
  line-height: normal;
  display: block;
  font-weight: normal;
  margin-top: 10px;
}
@media (max-width: 575px) {
  .ProductFeatures___10KaJ .feature-section .section-content .section-subtitle {
    font-size: 16px;
  }
}
.ProductFeatures___10KaJ .feature-section .section-content .feature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 575px) {
  .ProductFeatures___10KaJ .feature-section .section-content .feature-item {
    margin-bottom: 24px;
  }
}
.ProductFeatures___10KaJ .feature-section .section-content .feature-item .common-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Proxima Nova', 'PingFang SC';
}
.ProductFeatures___10KaJ .feature-section .section-content .feature-item .title {
  margin: 24px 0 8px 0;
  font-size: 18px;
  line-height: 28px;
  font-family: 'Proxima Nova', 'PingFang SC';
  font-weight: normal;
  text-align: center;
}
.ProductFeatures___10KaJ .feature-section .section-content .feature-item .description {
  text-align: center;
}
