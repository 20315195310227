.CommonTooltipContent___1ExYH {
  padding: 30px;
}
.CommonTooltipContent___1ExYH .summary-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
}
.CommonTooltipContent___1ExYH .summary-item.bolder {
  font-weight: 700;
}
