.sensitive___104wD {
  padding: 10px;
  font-size: 14px;
  background-color: #FFFAE7;
  margin-bottom: 15px;
}
.sensitive___104wD ol {
  margin-block-end: 0;
  padding-inline-start: 30px;
}
.sensitive___104wD .item___3c3oG {
  margin-bottom: 10px;
}
