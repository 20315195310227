.MajorIndexes___3oC9o .header {
  display: flex;
  margin: 0 18px 30px 0;
}
.MajorIndexes___3oC9o .header .title {
  flex: 1;
  min-width: 0;
  margin-right: 16px;
  font-size: 28px;
  color: rgba(29, 27, 46);
}
