.previousAndNextEmployee___255-k {
  height: 20px;
}
.changeEmployeeButton___25bn1 {
  padding: 0;
  color: #ce2c54;
}
.margin0___3AIfb {
  margin: 0!important;
}
