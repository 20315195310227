.CustomizationListPanel___1VEp9 {
  width: 268px;
  height: 100%;
  padding: 12px 16px;
  overflow-y: auto;
  background: #fff;
  border-bottom-left-radius: 4px;
  border-right: 1px solid #D9D9D9;
}
.CustomizationListPanel___1VEp9 .customization-item {
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  color: #000000;
}
.CustomizationListPanel___1VEp9 .customization-item:hover {
  background-color: rgba(235, 235, 235, 0.5);
}
.CustomizationListPanel___1VEp9 .customization-item.selected {
  background-color: #EBEBEB;
}
.CustomizationListPanel___1VEp9 .customization-item + .customization-item {
  margin-top: 12px;
}
