.CustomizationSearchBar___OgkS8 .ant-input-affix-wrapper-lg {
  padding: 9px 12px;
  border-radius: 20px;
  line-height: 1;
}
.CustomizationSearchBar___OgkS8 .ant-input-affix-wrapper-lg .ant-input {
  font-size: 14px;
  line-height: 20px;
  color: #000;
}
.CustomizationSearchBar___OgkS8 .divider-line {
  border: 1px solid #BFBFBF;
  border-radius: 1px;
  height: 20px;
}
