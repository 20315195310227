.orderReviewBasicSettings___rJLwZ .ruleItem {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}
.orderReviewBasicSettings___rJLwZ .ruleItem .label {
  flex: 1;
  min-width: 200px;
  font-weight: 500;
  line-height: 20px;
  color: #000000;
}
.orderReviewBasicSettings___rJLwZ .ruleItem .ant-form-item {
  flex: 6;
  margin-bottom: 0;
}
.orderReviewBasicSettings___rJLwZ .ruleItem .ant-form-item .ant-form-item-control-input {
  order: 2;
}
.orderReviewBasicSettings___rJLwZ .ruleItem .ant-form-item .ant-form-item-extra {
  order: 1;
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 20px;
}
.orderReviewBasicSettings___rJLwZ .checkboxGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.orderReviewBasicSettings___rJLwZ .checkboxGroup .ant-checkbox-wrapper {
  margin-left: 0;
}
.orderReviewBasicSettings___rJLwZ .checkboxGroup .ant-checkbox-wrapper .ant-checkbox + span {
  padding-left: 8px;
}
.orderReviewBasicSettings___rJLwZ .ant-switch {
  margin-left: 5px;
}
.orderReviewBasicSettings___rJLwZ.isMobile___gP5kA .ruleItem {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
}
.orderReviewBasicSettings___rJLwZ .settingDisable___31DAT {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.orderReviewBasicSettings___rJLwZ .settingDisable___31DAT a {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
