/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.DishPreferenceForm___19KCS .option-item {
  position: relative;
  padding: 12px 12px 0;
  background: #fff;
  border: 1px solid #C0C2CC;
  border-radius: 5px;
}
.DishPreferenceForm___19KCS .option-item .index-description {
  position: absolute;
  top: 0;
  left: 12px;
  transform: translate(0, -50%);
}
.DishPreferenceForm___19KCS .option-item .index-description:before {
  content: "";
  position: absolute;
  display: block;
  z-index: 1;
  top: 50%;
  left: -3px;
  width: calc(100% + 6px);
  height: 2px;
  background: #fff;
  margin-top: -1px;
}
.DishPreferenceForm___19KCS .option-item .index-description .content {
  position: relative;
  z-index: 2;
  text-shadow: 0 0 5px #fff;
}
@media (min-width: 1300px) {
  .DishPreferenceForm___19KCS .option-item .delete-icon-wrapper {
    margin-top: 42px;
  }
}
.DishPreferenceForm___19KCS .restriction_title {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fill, 24px);
}
.DishPreferenceForm___19KCS .restriction_title span {
  font-family: Proxima Nova;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}
.DishPreferenceForm___19KCS .restriction_title p {
  font-family: Proxima Nova;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #8C8C8C;
}
@media (max-width: 992px) {
  .DishPreferenceForm___19KCS .restriction_title {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    gap: 5px;
  }
}
.DishPreferenceForm___19KCS .restriction_rules {
  gap: 15px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 420px);
}
.DishPreferenceForm___19KCS .restriction_rules .restriction_rules_col {
  padding-left: 10px;
  padding-right: 10px;
  align-items: baseline;
  margin-bottom: 16px;
  gap: 4px 10px;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: 32px auto;
}
.DishPreferenceForm___19KCS .restriction_rules .restriction_rules_col > span {
  grid-column-start: 1;
  grid-column-end: 3;
  line-height: 32px;
}
.DishPreferenceForm___19KCS .restriction_rules .restriction_rules_col .ant-form-item-label label {
  color: #8C8C8C;
}
.DishPreferenceForm___19KCS .restriction_rules .restriction_rules_col .ant-form-item-explain-error {
  font-family: Proxima Nova;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-top: 5px;
}
.DishPreferenceForm___19KCS .restriction_rules .restriction_rules_col .ant-input-number-input {
  text-align: center;
}
.DishPreferenceForm___19KCS .restriction_rules .ant-form-item {
  margin-bottom: 0px;
}
.DishPreferenceForm___19KCS .restriction_rules .ant-form-item .ant-form-item-control {
  flex: auto;
}
.DishPreferenceForm___19KCS .restriction_rules .ant-form-item .ant-form-item-label {
  padding: 0px;
  line-height: 40px;
  margin-right: 5px;
  flex: 0 0 auto;
}
.DishPreferenceForm___19KCS .restriction_rules .ant-form-item-row {
  flex-direction: row;
  flex-flow: nowrap;
}
@media (max-width: 576px) {
  .DishPreferenceForm___19KCS .restriction_rules .ant-form-item-label {
    flex: 0 0 auto;
  }
  .DishPreferenceForm___19KCS .restriction_rules .ant-row {
    flex-direction: column;
    flex-flow: nowrap;
  }
  .DishPreferenceForm___19KCS .restriction_rules .ant-form .ant-form-item .ant-form-item-label,
  .DishPreferenceForm___19KCS .restriction_rules .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 auto;
  }
}
@media (max-width: 575px) {
  .DishPreferenceForm___19KCS .restriction_rules .restriction_rules_col {
    gap: 0 10px;
  }
  .DishPreferenceForm___19KCS .restriction_rules .restriction_rules_col > span {
    grid-column-start: 1;
    grid-column-end: span 2;
  }
}
