.GoogleMapContainer___3okPO {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loading_icon___39u70 {
  width: 40px;
  height: 40px;
  background-image: url('https://pos-static.chowbus.com/assets/loading_icon.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  animation-name: loading___Y0m7e;
  animation-duration: 2000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
}
@keyframes loading___Y0m7e {
  from {
    transform: rotate(0) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1);
  }
  to {
    transform: rotate(360deg) scale(1);
  }
}
.mapContainer___J34YY {
  width: 100%;
  height: 100%;
}
.markerLabel___gYyau {
  background-color: #ffffff;
  color: #E00051;
  transform: translateY(30px);
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 220px;
}
