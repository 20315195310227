.VideoPlayer___2qnFy .video-container {
  width: 100%;
  padding-top: 56.25%;
  height: 0;
  position: relative;
}
.VideoPlayer___2qnFy .video-container .video-player,
.VideoPlayer___2qnFy .video-container .video-placeholder {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: #ffffff;
  border-radius: 12px;
}
.VideoPlayer___2qnFy .video-container .video-placeholder {
  background: #1d1b2e;
  overflow: hidden;
  display: flex;
}
.VideoPlayer___2qnFy .video-container .hasCover.video-placeholder {
  background: transparent;
}
.VideoPlayer___2qnFy .video-container .video-cover {
  width: 100%;
  object-fit: cover;
}
.VideoPlayer___2qnFy .video-container .play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  width: 44px;
  height: 44px;
  background-image: url('https://pos-static.chowbus.com/assets/video_play_button.png');
  background-size: 100%;
}
.VideoPlayer___2qnFy .ant-modal-content {
  border-radius: 12px;
  background: transparent;
  box-shadow: none;
  overflow: hidden;
}
.VideoPlayer___2qnFy .ant-modal-body {
  padding: 0;
}
.VideoPlayer___2qnFy .player-wrapper {
  position: relative;
}
