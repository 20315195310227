.Dashboard___2m8tV .ant-card-head-title {
  font-size: 19px;
  line-height: 19px;
}
@media (max-width: 576px) {
  .Dashboard___2m8tV .ant-card-head-wrapper {
    flex-wrap: wrap;
  }
  .Dashboard___2m8tV .ant-card-head-wrapper .ant-card-head-title {
    flex: auto;
  }
  .Dashboard___2m8tV .ant-card-head-wrapper .ant-card-extra {
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
    float: none;
  }
  .Dashboard___2m8tV .order-count-chart-container {
    flex: auto;
  }
}
.Dashboard___2m8tV .amount_summary {
  width: 100%;
  background: #ffffff;
  margin-bottom: 10px;
}
.Dashboard___2m8tV .order-trend-container {
  height: 142px;
  padding: 24px 0;
  background: #fff;
}
.Dashboard___2m8tV .stat-container {
  background: #ffffff;
  padding: 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.Dashboard___2m8tV .stat-container .stat-item {
  margin-bottom: 42px;
}
.Dashboard___2m8tV .stat-container .stat-indexes {
  flex: 1;
  min-height: 0;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.Dashboard___2m8tV .stat-container .stat-indexes .stat-item {
  margin-bottom: 50px;
}
.tooltipContainer___3_bFq {
  width: 360px;
  max-width: 360px;
}
