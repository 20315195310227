/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.sales-report___3OwLh .ant-card-type-inner .ant-card-extra {
  padding: 0;
}
.sales-report___3OwLh .sales-report-header {
  position: sticky;
  z-index: 11;
  background: #f5f5f5;
}
.sales-report___3OwLh .page-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.sales-report___3OwLh .page-header h2 {
  margin-bottom: 0;
  white-space: nowrap;
}
.sales-report___3OwLh .page-header .tabs-wrapper {
  flex: 1;
  min-width: 0;
}
.sales-report___3OwLh .page-header .ant-tabs-nav {
  margin: 0;
}
.sales-report___3OwLh .page-header .ant-tabs-tab {
  padding: 0;
  font-size: 20px;
}
.sales-report___3OwLh .time-picker-container {
  flex: 1;
  min-width: 0;
}
.sales-report___3OwLh .sales-tabs {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  background: #ffffff;
}
.sales-report___3OwLh .sales-tabs .sales-item {
  width: 110px;
  text-align: center;
  padding: 4px 0;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  cursor: pointer;
}
.sales-report___3OwLh .sales-tabs .sales-item:last-child {
  margin-right: 0;
}
.sales-report___3OwLh .sales-tabs .sales-item .ant-card-extra {
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  float: none;
}
.sales-report___3OwLh .sales-tabs .sales-item.active {
  color: #e00051;
  background: #ffedf3;
  cursor: default;
}
@media (max-width: 991px) {
  .sales-report___3OwLh .time-picker-container {
    width: 100%;
    flex: auto;
    min-width: 0;
  }
  .sales-report___3OwLh .sales-tabs-container {
    width: 100%;
  }
}
.sales-report___3OwLh .chart-panel .tooltip-icon {
  color: #c0c2cc;
}
.sales-report___3OwLh .absolute-center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sales-report___3OwLh .ant-select-multiple {
  z-index: 3!important;
}
