.OptionPreferences___2sjIr .add-button-wrap {
  display: flex;
  justify-content: end;
}
.OptionPreferences___2sjIr .preferences .choose-categoires {
  line-height: 22px;
  color: #8C8C8C;
}
.OptionPreferences___2sjIr .preferences .preference-selector {
  margin: 18px 0 26px 0;
}
.OptionPreferences___2sjIr .preferences .option-preference-group + .option-preference-group {
  margin-top: 24px;
}
.OptionPreferences___2sjIr .preferences .option-preference-group .group-content {
  margin-top: 16px;
}
.OptionPreferences___2sjIr .preferences .option-preference-group .group-content .group-title {
  font-size: 16px;
  line-height: 20px;
  color: #000;
}
.OptionPreferences___2sjIr .preferences .option-preference-group .group-content .option-name {
  line-height: 22px;
  color: #8C8C8C;
}
