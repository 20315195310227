.tasks___3U7Ci .ant-table tbody > tr.selected-row {
  background: #fff0f1;
}
.tasks___3U7Ci .filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.task-data-modal___1i2da .ant-modal-body {
  max-height: 500px;
  overflow-y: auto;
}
.task-data-modal___1i2da .ant-modal-body .original-data {
  margin-bottom: 30px;
}
