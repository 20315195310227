.PrintingTaskDetail___1WTbs .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 0;
}
.PrintingTaskDetail___1WTbs .ant-collapse-content-box {
  padding: 12px 0;
}
.PrintingTaskDetail___1WTbs .device-description {
  padding: 3px 6px;
  margin-left: 6px;
  background: #f2f4f5;
  font-size: 14px;
  border-radius: 3px;
  color: #666;
}
