/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.PageBreakManagement___1wSW9 .description-card {
  display: inline-flex;
  position: relative;
  padding: 10px 16px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 20px;
  color: #92929f;
  background: #ffffff;
}
.PageBreakManagement___1wSW9 .description-card::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  bottom: 0;
  background: #e00051;
}
.PageBreakManagement___1wSW9 .ant-card-body {
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .PageBreakManagement___1wSW9 .ant-form-item-control-input-content {
    text-align: right;
  }
}
.PageBreakManagement___1wSW9 .standalone .ant-form-item-control-input-content {
  text-align: left !important;
}
.PageBreakManagement___1wSW9 .helperText {
  color: #e00051;
  margin-left: 4px;
}
.title___2pdm9 {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.title___2pdm9 h2 {
  margin-bottom: 0;
}
.title___2pdm9 .ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}
.title___2pdm9 .ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  padding: 0;
  font-size: 20px;
}
