.dish-preference___2ioM3 {
  padding-top: 15px;
}
.dish-preference___2ioM3 h2 {
  text-transform: uppercase;
}
.dish-preference___2ioM3 .dish-category-item {
  width: 300px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 15px;
  user-select: none;
}
.dish-preference___2ioM3 .category-item-content {
  flex: 1;
  min-width: 0;
  margin-left: 10px;
  cursor: pointer;
}
.category-item___23_Zm {
  width: 250px;
  border-radius: 10px;
  padding: 10px 14px;
  background: #fff;
  border: 1px solid #dde4e7;
  align-items: center;
}
.optionLinkWrapper___rxlC0 + .optionLinkWrapper___rxlC0 {
  margin-top: 6px;
}
.optionLinkWrapper___rxlC0 .optionLink___HXSh- {
  color: #e5e5e5;
}
.optionLinkWrapper___rxlC0 .optionLink___HXSh-:hover {
  color: #fff;
  text-decoration: underline;
}
