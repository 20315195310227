.autoplayer___3Sqlz {
  border-radius: 12px;
  position: relative;
  margin-left: -1px;
  background-color: black;
  overflow: hidden;
}
.autoplayer___3Sqlz.clickDisable___244As {
  pointer-events: none;
}
.autoplayer___3Sqlz .playerTopCover {
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}
.autoplayer___3Sqlz .playerTopCover .playerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  font-size: 18px;
  border-radius: 25px;
  height: 45px;
  padding: 10px 15px;
  position: relative;
  cursor: pointer;
}
.autoplayer___3Sqlz .playerTopCover .playerButton.pureButton {
  width: 50px;
  height: 50px;
  padding: 0;
  border-radius: 0;
  background-color: #ffffff;
  border-radius: 100%;
}
.autoplayer___3Sqlz .playerTopCover .playerButton.pureButton:before {
  margin: 0;
  display: block;
  content: '';
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 0px solid red;
  cursor: pointer;
  background-image: url('https://pos-static.chowbus.com/assets/video_play_button_white_bk.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.autoplayer___3Sqlz .playerTopCover .playerButton:before {
  display: block;
  content: '';
  width: 25px;
  height: 25px;
  border-radius: 100%;
  cursor: pointer;
  background-image: url('https://pos-static.chowbus.com/assets/video_play_button_white_bk.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
  border: 1px solid red;
}
.autoplayer___3Sqlz .playerTopCover .playerButton.playerButtonClicked {
  display: none;
}
.autoplayer___3Sqlz .playerTopCover.playing {
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
}
.autoplayer___3Sqlz .playerTopCover.playing:after {
  display: none;
}
.autoplayer___3Sqlz .poster-container {
  width: 100%;
  height: 0;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  display: flex;
}
.autoplayer___3Sqlz .poster-img-container {
  overflow: hidden;
  display: flex;
  width: 100%;
}
.autoplayer___3Sqlz .poster-img-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
