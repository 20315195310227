/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.OrderAdvancedSearchBox___2AYaP {
  position: relative;
  z-index: 11;
}
.OrderAdvancedSearchBox___2AYaP .input-box {
  display: block;
  height: 40px;
  line-height: 30px;
}
.OrderAdvancedSearchBox___2AYaP .input-box:empty::before {
  content: attr(placeholder);
  color: #bfbfbf;
  line-height: 30px;
  font-size: 16px;
}
.OrderAdvancedSearchBox___2AYaP .search-box-panel {
  position: absolute;
  z-index: 11;
  left: 0;
  top: 100%;
  width: 420px;
  padding: 16px;
  margin-top: 4px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
@media (max-width: 767px) {
  .OrderAdvancedSearchBox___2AYaP .search-box-panel {
    width: 100%;
  }
}
.OrderAdvancedSearchBox___2AYaP .search-box-panel .panel-header {
  font-size: 18px;
  text-align: center;
}
.OrderAdvancedSearchBox___2AYaP .search-box-panel .panel-body {
  margin: 24px 0;
}
.OrderAdvancedSearchBox___2AYaP .search-box-panel .panel-body .search-field-item .field-title {
  margin-bottom: 8px;
}
.OrderAdvancedSearchBox___2AYaP .search-box-panel .panel-body .search-field-item .ant-radio-button-wrapper {
  font-size: 14px;
  margin-right: 0;
}
.OrderAdvancedSearchBox___2AYaP .search-box-panel .panel-body .search-field-item + .search-field-item {
  margin-top: 16px;
}
