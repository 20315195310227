.DeferredSalesTooltipContent___1wTex .summary-section .section-title {
  margin-bottom: 4px;
  font-size: 18px;
  text-transform: capitalize;
}
.DeferredSalesTooltipContent___1wTex .summary-section .summary-item {
  display: flex;
  align-items: center;
}
.DeferredSalesTooltipContent___1wTex .summary-section .summary-item .item-name {
  flex: 1;
  min-width: 0;
}
.DeferredSalesTooltipContent___1wTex .summary-section .summary-item .item-count {
  width: 40px;
  margin: 0 6px;
  text-align: right;
}
.DeferredSalesTooltipContent___1wTex .summary-section .summary-item .item-amount {
  width: 100px;
  text-align: right;
}
.DeferredSalesTooltipContent___1wTex .summary-section + .summary-section {
  margin-top: 16px;
}
