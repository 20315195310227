/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.referral-landing-page .ant-layout-content {
  padding-top: 0 !important;
}
.PageReferralLanding___2LkMq .customerVoiceList___3FNWL {
  width: 100%;
}
.PageReferralLanding___2LkMq .emphasis___30B0x {
  color: #FDD129;
  font-weight: 600;
}
.PageReferralLanding___2LkMq .section-title {
  font-size: 35px !important;
  line-height: 40px !important;
}
@media (max-width: 575px) {
  .PageReferralLanding___2LkMq .section-title {
    font-size: 25px !important;
    line-height: normal !important;
  }
}
.PageReferralLanding___2LkMq .topSection {
  margin-bottom: 50px;
}
@media (max-width: 575px) {
  .PageReferralLanding___2LkMq .topSection {
    margin-bottom: 0px;
  }
}
.PageReferralLanding___2LkMq .advance-section {
  margin: 50px 0;
}
@media (max-width: 575px) {
  .PageReferralLanding___2LkMq .advance-section {
    margin: 30px 0;
  }
}
