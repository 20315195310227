.FeesTooltipContent___1OWMA {
  padding: 30px 30px 0;
}
.FeesTooltipContent___1OWMA .fee-group {
  margin-bottom: 30px;
}
.FeesTooltipContent___1OWMA .list-item {
  display: flex;
  line-height: 21px;
  margin-bottom: 4px;
}
.FeesTooltipContent___1OWMA .list-item .list-item-value {
  width: 90px;
  text-align: right;
}
.FeesTooltipContent___1OWMA .list-item .list-item-name {
  flex: 1;
  margin-right: 4px;
}
