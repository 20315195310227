.permission-management___3Wde_ .ant-card-head-wrapper {
  height: 60px;
}
.permission-management___3Wde_ .ant-card-body {
  height: calc(100vh - 210px);
  overflow-y: auto;
}
.permission-management___3Wde_ .restaurantCard .ant-card-body {
  padding: 0;
}
.permission-management___3Wde_ .ant-menu-submenu .ant-menu .ant-menu-item {
  height: auto;
  white-space: normal;
  line-height: 1.4;
  padding: 8px;
}
.permission-picker-modal___2j1x7 .ant-select-item-option-content {
  white-space: normal;
}
