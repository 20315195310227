.Menus___3r58p .main-content {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex-direction: row;
  flex-wrap: wrap;
}
.Menus___3r58p .main-content .list-wrapper .list-header {
  font-size: 16px;
  margin-bottom: 30px;
  color: #4a4a4a;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.Menus___3r58p .main-content .list-wrapper .list-item {
  margin-bottom: 20px;
}
.Menus___3r58p .main-content .customization-list {
  display: flex;
  text-transform: capitalize;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 10px;
}
.Menus___3r58p .main-content .customization-list.row {
  flex-direction: row;
}
.Menus___3r58p .main-content .customization-list.row .customization-item + .customization-item {
  margin-left: 30px;
}
.Menus___3r58p .main-content .customization-list .option-description {
  margin-top: 10px;
  color: #666;
}
.Menus___3r58p .main-content .customization-list .option-property {
  margin-top: 15px;
  color: #666;
}
.Menus___3r58p .main-content .customization-list .option-price {
  margin-left: 8px;
}
.Menus___3r58p .main-content.row .list-wrapper + .list-wrapper {
  margin-left: 50px;
}
.Menus___3r58p .main-content.column {
  flex-direction: column;
}
.Menus___3r58p .main-content.column .list-wrapper + .list-wrapper {
  margin-top: 50px;
}
.Menus___3r58p .empty-list {
  padding-top: 150px;
}
.Menus___3r58p .dish-button-color-header {
  font-size: 16px;
  margin-bottom: 10px;
  color: #4a4a4a;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.Menus___3r58p .menu-divider {
  height: 100%;
  background: #cccccc;
  margin: 0 30px;
}
.Menus___3r58p .menu-customization-list {
  display: flex;
}
.Menus___3r58p .menu-customization-list .ant-switch {
  transform: scale(1.4) translate(0, -3px);
  transform-origin: 0 0;
}
.Menus___3r58p .retry-wrapper {
  padding-top: 100px;
  text-align: center;
  font-size: 18px;
}
.Menus___3r58p .retry-wrapper a {
  text-transform: capitalize;
}
.Menus___3r58p .sortable-list-item {
  display: flex;
  margin-bottom: 20px;
  user-select: none;
}
.Menus___3r58p .color-picker-dropdown {
  position: absolute;
  padding: 5px;
  border-radius: 5px;
  color: #000000;
  background: #ffffff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
.Menus___3r58p .color-picker-dropdown:before,
.Menus___3r58p .color-picker-dropdown:after {
  content: '';
  position: absolute;
}
.Menus___3r58p .color-picker-dropdown.bottom {
  transform: translateY(6px);
}
.Menus___3r58p .color-picker-dropdown.bottom:before {
  top: -6px;
  left: 20px;
  border-width: 0 6px 6px 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.15) transparent;
}
.Menus___3r58p .color-picker-dropdown.bottom:after {
  top: -5px;
  left: 21px;
  border-width: 0 5px 5px 5px;
  border-style: solid;
  border-color: #fff transparent;
}
.Menus___3r58p .color-picker-dropdown.top {
  transform: translateY(-6px);
}
.Menus___3r58p .color-picker-dropdown.top:before {
  bottom: -6px;
  left: 20px;
  border-width: 6px 6px 0 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.15) transparent;
}
.Menus___3r58p .color-picker-dropdown.top:after {
  bottom: -5px;
  left: 21px;
  border-width: 5px 5px 0 5px;
  border-style: solid;
  border-color: #fff transparent;
}
