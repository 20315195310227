.innerSection___20VQL {
  padding-left: 24px;
  padding-right: 24px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}
.thanks_icon___3ftns {
  width: 130px;
}
.thanks_title___35hDd {
  white-space: pre-line;
  font-size: 32px;
  line-height: 36px;
  color: #E00051;
  text-align: center;
}
.navTo3RdContainer___2TnZ5 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.navTo3RdContainer___2TnZ5 .navToTitle___3Udil {
  color: #8C8C8C;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  width: 100%;
}
.navTo3RdContainer___2TnZ5 .navToTitle___3Udil span {
  background-color: #ffffff;
  padding: 0 10px;
  position: relative;
  z-index: 1;
  white-space: pre-wrap;
  display: block;
  width: fit-content;
  text-align: center;
  margin: 0 auto;
}
.navTo3RdContainer___2TnZ5 .navToTitle___3Udil::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 1px;
  background-color: #EBEBEB;
}
.navTo3RdContainer___2TnZ5 .navToTargetOptions___3Rw1q {
  margin-top: 20px;
}
.navTo3RdContainer___2TnZ5 .navTagetButton___1utd1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #E00051;
}
.navTo3RdContainer___2TnZ5 .navTagetButton___1utd1::before {
  position: absolute;
  left: 8px;
  content: '';
  width: 32px;
  height: 32px;
  display: block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.navTo3RdContainer___2TnZ5 .navTagetButton___1utd1.google_target___1JftW::before {
  background-image: url('https://pos-static.chowbus.com/assets/review/review_target_google_logo.png');
}
.navTo3RdContainer___2TnZ5 .navTagetButton___1utd1.yelp_target___GVAfQ::before {
  background-image: url('https://pos-static.chowbus.com/assets/review/review_target_yelp_logo.png');
}
.copyToClipboardButton___1s7Sh {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #ffffff;
  background-color: #F5F5F5;
  width: 150px;
  height: 32px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 16px;
  cursor: context-menu;
  color: #000000;
  margin: 0 auto;
  margin-top: 16px;
  padding: 6px 16px;
  white-space: nowrap;
}
.copyToClipboardButton___1s7Sh::before {
  margin-right: 6px;
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background-image: url('https://pos-static.chowbus.com/assets/review/copy-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.toast___1NkQ0 {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: transparent;
  animation-name: toast_hidden___b3eKi;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
  animation-delay: 500ms;
  opacity: 1;
}
.toast___1NkQ0 .content___3tDib {
  background-color: #000000B2;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding: 6px 16px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toast___1NkQ0 .content___3tDib::before {
  margin-right: 6px;
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  background-image: url('https://pos-static.chowbus.com/assets/review/copied-circle-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
}
@keyframes toast_hidden___b3eKi {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
