.PageLanding___1D2xa {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.PageLanding___1D2xa .inner-container {
  display: flex;
  flex-direction: column;
}
.PageLanding___1D2xa .inner-container .chowbus-logo {
  width: 280px;
  margin-bottom: 30px;
}
.PageLanding___1D2xa .inner-container .loading-tip {
  margin-top: 20px;
  font-size: 36px;
  text-align: center;
}
