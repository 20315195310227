.Customizations___1cYYq {
  display: flex;
  overflow-x: auto;
}
.Customizations___1cYYq .customization-section {
  height: 100%;
  margin-right: 60px;
  display: flex;
  flex-direction: column;
}
.Customizations___1cYYq .customization-section .section-title {
  color: #3c3a4a;
  font-size: 16px;
  margin-bottom: 16px;
}
.Customizations___1cYYq .customization-section .section-content {
  padding-right: 10px;
  flex: 1;
  overflow-y: auto;
}
