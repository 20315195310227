.submitAlertPopup___2Tac2 {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  padding: 0 24px;
  z-index: 10;
}
.submitAlertPopup___2Tac2 .mainBody___SESRS {
  border-radius: 16px;
  padding: 24px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}
.submitAlertPopup___2Tac2 .mainBody___SESRS .desc___Zak5P {
  color: #1D1B2E;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  text-align: center;
}
.submitAlertPopup___2Tac2 .mainBody___SESRS .bottomActionBar___3LPOa {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.submitAlertPopup___2Tac2 .mainBody___SESRS .bottomActionBar___3LPOa .actionButton___2bFyN {
  height: 44px;
  background-color: #E00051;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  border-radius: 22px;
  border: 1px solid #E00051;
  flex: 1;
}
.submitAlertPopup___2Tac2 .mainBody___SESRS .bottomActionBar___3LPOa .actionButton___2bFyN.outline___TRDOW {
  background-color: #ffffff;
  color: #E00051;
  border: 1px solid #E00051;
}
