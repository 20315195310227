.DeliverectLink___2JHqK .link-zone {
  display: inline-flex;
  align-items: center;
  padding: 20px 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.DeliverectLink___2JHqK .link-zone .icon {
  margin-right: 20px;
}
.DeliverectLink___2JHqK .link-zone .action-button {
  width: 120px;
  border-radius: 10px;
}
.DeliverectLink___2JHqK p {
  color: #1D1B2E;
}
