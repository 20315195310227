.orderReviewsReport___3HgEP {
  padding-top: 8px;
  padding-bottom: 8px;
}
.orderReviewsReport___3HgEP .header {
  margin-right: 18px;
}
.orderReviewsReport___3HgEP .header .header-primary {
  display: flex;
}
.orderReviewsReport___3HgEP .header .header-primary .range-picker {
  flex: 1;
  min-width: 0;
  margin-right: 24px;
}
.orderReviewsReport___3HgEP .header .header-secondary {
  font-size: 18px;
  color: #1d1b2e;
  margin: 24px 0 16px 0;
}
