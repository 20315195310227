.LaborSummary___1xd-T .description-card {
  display: inline-flex;
  position: relative;
  padding: 10px 16px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  color: #92929f;
  background: #ffffff;
}
.LaborSummary___1xd-T .description-card::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  bottom: 0;
  background: #e00051;
}
.LaborSummary___1xd-T .error-help {
  font-style: italic;
  color: #ce2c54;
}
