.ServiceTypesRow___3UjFi {
  margin-bottom: 24px;
}
.ServiceTypesRow___3UjFi:last-child {
  margin: 0;
}
.ServiceTypesRow___3UjFi .ant-form-item {
  margin-bottom: 0;
}
.ServiceTypesRow___3UjFi .tips-wrapper {
  margin-top: 4px;
  padding: 6px 0;
  color: #8c8c8c;
  font-size: 14px;
  line-height: 20px;
}
