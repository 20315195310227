/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.title___3WyFn {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.title___3WyFn h2 {
  margin-bottom: 0;
}
.title___3WyFn .ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}
.title___3WyFn .ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  padding: 0;
  font-size: 20px;
}
.TipsSettings___o5Ja7 .setting-section .section-title {
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
}
.TipsSettings___o5Ja7 .setting-section .section-content .section-title {
  width: 200px;
  margin-right: 24px;
}
.TipsSettings___o5Ja7 .setting-section .section-content .section-content-title {
  width: 200px;
  margin-right: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  color: #000000;
}
.TipsSettings___o5Ja7 .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  height: 32px;
}
.TipsSettings___o5Ja7 .ant-checkbox-wrapper .ant-checkbox {
  top: auto;
}
.TipsSettings___o5Ja7 .ant-checkbox-wrapper > span:last-child {
  padding: 0 8px;
}
.TipsSettings___o5Ja7 .ant-input-number-group-wrapper .ant-input-number-group-addon {
  min-width: 32px;
}
.TipsSettings___o5Ja7 .ant-radio-wrapper {
  display: flex;
  align-items: center;
  height: 32px;
  margin-top: 4px;
}
.TipsSettings___o5Ja7 .ant-radio-wrapper:first-child {
  margin-top: 0;
}
.TipsSettings___o5Ja7 .ant-radio-wrapper .ant-radio {
  top: auto;
}
.TipsSettings___o5Ja7 .ant-switch {
  transform: initial;
  transform-origin: initial;
}
