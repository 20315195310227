.btn-add-member {
  display: block;
  width: 28px;
  height: 28px;
  background-image: url('https://pos-static.chowbus.com/assets/collapsed.png');
  background-size: 100%;
  border-radius: 5px;
  cursor: pointer;
}
.Member___3urJ0 .no-wrap {
  white-space: nowrap;
  min-width: 70px;
}
.Member___3urJ0 .break-word {
  word-break: break-all;
}
.Member___3urJ0 .expiredTimeLabel___1DCqB {
  color: rgba(0, 0, 0, 0.85);
  line-height: 25px;
  font-size: 18px;
  padding-right: 5px;
  font-weight: 600;
}
