.ChartCardWrapper___3_Vp5 {
  position: relative;
  height: 445px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.ChartCardWrapper___3_Vp5.focused___hvPS5 {
  border-color: #fe9c22;
}
.ChartCardWrapper___3_Vp5.focused___hvPS5 .resize-handler,
.ChartCardWrapper___3_Vp5.focused___hvPS5 .sortable-handler {
  display: block;
}
.ChartCardWrapper___3_Vp5 .card-inner {
  display: flex;
  justify-content: center;
}
.ChartCardWrapper___3_Vp5 .resize-handler {
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  width: 16px;
  height: 116px;
  background-size: 100%;
  background-image: url('https://pos-static.chowbus.com/assets/resize_handler.png');
  transform: translate(100%, -50%);
  cursor: col-resize;
}
.ChartCardWrapper___3_Vp5 .sortable-handler {
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  width: 116px;
  height: 16px;
  background-size: 100%;
  background-image: url('https://pos-static.chowbus.com/assets/sortable_handler.png');
  transform: translate(-50%, -100%);
  cursor: move;
}
