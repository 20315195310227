/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.TipsAllocationSettings___2Dsf_ .settings-title {
  margin-bottom: 16px;
}
.TipsAllocationSettings___2Dsf_ .ant-radio-wrapper {
  margin-left: 2em;
}
.TipsAllocationSettings___2Dsf_ .ant-form-vertical .ant-form-item.withhold_input {
  margin-bottom: 0;
}
.TipsAllocationSettings___2Dsf_ .ant-form-vertical .ant-form-item.withhold_input .ant-form-item-row {
  flex-direction: row;
}
.TipsAllocationSettings___2Dsf_ .ant-form-vertical .ant-form-item.withhold_input .ant-form-item-control {
  width: auto;
}
.TipsAllocationSettings___2Dsf_ .ant-form-vertical .ant-form-item.inline_form_item .ant-form-item-row {
  flex-direction: row;
}
@media (min-width: 576px) {
  .TipsAllocationSettings___2Dsf_ .ant-form-vertical .ant-form-item.inline_form_item .ant-form-item-control {
    text-align: right;
  }
}
