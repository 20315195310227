.sensitive___1F_1O {
  padding: 10px;
  font-size: 14px;
  background-color: #FFFAE7;
  margin-bottom: 15px;
}
.sensitive___1F_1O ol {
  margin-block-end: 0;
  padding-inline-start: 30px;
}
.sensitive___1F_1O .item___3GgEw {
  margin-bottom: 10px;
}
