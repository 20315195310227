.tableSettingModal___3lomY .postfix___2E3Iu {
  margin: 0 10px 0 5px;
  color: #92929F;
  white-space: nowrap;
}
.tableSettingModal___3lomY .ant-form-item {
  margin-bottom: 10px;
}
.LoyaltyPointsSettings___1nOtt .ant-spin-nested-loading.loading .ant-spin-spinning {
  position: fixed;
  bottom: 0;
  max-height: unset;
}
.LoyaltyPointsSettings___1nOtt .sortable-list-item {
  margin-bottom: 0;
}
.LoyaltyPointsSettings___1nOtt .group .group-header {
  display: flex;
  justify-content: space-between;
}
.LoyaltyPointsSettings___1nOtt .group .group-title {
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}
.LoyaltyPointsSettings___1nOtt .group .group-content {
  margin-top: 22px;
}
.LoyaltyPointsSettings___1nOtt .ant-card-body {
  padding: 24px 24px 10px 24px;
}
.LoyaltyPointsSettings___1nOtt .applicable-card .ant-card-body {
  padding: 18px;
}
.LoyaltyPointsSettings___1nOtt .applicable-card .group-title {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.LoyaltyPointsSettings___1nOtt .setting-wrapper + .setting-wrapper {
  margin-top: 16px;
}
.LoyaltyPointsSettings___1nOtt .section-name {
  font-size: 18px;
  color: #999;
  margin-bottom: 10px;
}
.LoyaltyPointsSettings___1nOtt .ant-radio-wrapper {
  white-space: normal;
}
@media (min-width: 992px) {
  .LoyaltyPointsSettings___1nOtt .switch-wrapper {
    text-align: right;
  }
  .LoyaltyPointsSettings___1nOtt .switch-wrapper .ant-switch {
    transform: scale(1.4) translate(-13px, -3px);
    transform-origin: 0 0;
  }
}
.LoyaltyPointsSettings___1nOtt .ant-switch {
  transform: scale(1.4) translate(0, -3px);
  transform-origin: 0 0;
}
.LoyaltyPointsSettings___1nOtt .ant-switch-checked {
  background: #34c759;
}
.LoyaltyPointsSettings___1nOtt .ant-checkbox-wrapper {
  margin-bottom: 10px;
}
.LoyaltyPointsSettings___1nOtt .ant-checkbox-wrapper .ant-checkbox {
  transform: scale(1.4);
  transform-origin: 0 10px;
  margin-right: 4px;
}
.LoyaltyPointsSettings___1nOtt .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  border-radius: 0;
}
.LoyaltyPointsSettings___1nOtt .ant-table .ant-checkbox-wrapper .ant-checkbox {
  transform-origin: 0 0;
}
.LoyaltyPointsSettings___1nOtt .ant-radio {
  transform: scale(1.2);
  transform-origin: 0 14px;
}
.LoyaltyPointsSettings___1nOtt .help-icon {
  cursor: pointer;
  margin-left: 4px;
}
.LoyaltyPointsSettings___1nOtt .settings-block .settings-title {
  margin-bottom: 10px;
}
.LoyaltyPointsSettings___1nOtt .settings-block .settings-content {
  margin-left: 30px;
}
.LoyaltyPointsSettings___1nOtt .settings-block .settings-section .settings-section-header {
  margin-bottom: 18px;
}
.LoyaltyPointsSettings___1nOtt .settings-block .settings-section .settings-section-header .section-description {
  color: #92929f;
}
.LoyaltyPointsSettings___1nOtt .settings-block .settings-section .settings-section-content {
  margin-left: 30px;
}
.LoyaltyPointsSettings___1nOtt .settings-block .settings-section .ant-radio-group {
  display: block;
}
.LoyaltyPointsSettings___1nOtt .settings-block .settings-section .secondary-title {
  margin-bottom: 16px;
}
.LoyaltyPointsSettings___1nOtt .settings-block .settings-section .settings-options .option-item + .option-item {
  margin-top: 16px;
}
.LoyaltyPointsSettings___1nOtt .settings-block .settings-section .settings-options .ant-radio-wrapper {
  display: flex;
}
.LoyaltyPointsSettings___1nOtt .settings-block .settings-section .settings-options .ant-radio-wrapper .ant-radio ~ span {
  flex: 1;
  min-width: 0;
  margin-top: -3px;
}
.LoyaltyPointsSettings___1nOtt .settings-block .settings-section + .settings-section {
  margin-top: 16px;
}
.LoyaltyPointsSettings___1nOtt .settings-block + .settings-block {
  margin-top: 16px;
}
.LoyaltyPointsSettings___1nOtt .icon-trash {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('https://pos-static.chowbus.com/assets/icon_trash.png');
  background-size: 100%;
  cursor: pointer;
}
.LoyaltyPointsSettings___1nOtt .postfix___2E3Iu {
  margin: 0 10px 0 5px;
  color: #92929F;
  white-space: nowrap;
}
.LoyaltyPointsSettings___1nOtt .tableQueueOption___2i4W0 .ant-form-item {
  margin-bottom: 10px;
}
.LoyaltyPointsSettings___1nOtt .tableQueueOptionSaving___2Qu7f {
  background-color: red;
}
.LoyaltyPointsSettings___1nOtt .tableQueueNotes___2HJuw .ant-form-item {
  margin-bottom: 10px;
}
.LoyaltyPointsSettings___1nOtt .tableQueueNotes___2HJuw textarea {
  resize: none;
}
.LoyaltyPointsSettings___1nOtt .optionCapacity___3juXJ .ant-form-item-explain-connected + div {
  display: none;
}
.LoyaltyPointsSettings___1nOtt .divider___3BRTv {
  background-color: #fffcfc;
  border-width: 1px 0 0;
  margin: 5px 0;
}
.LoyaltyPointsSettings___1nOtt .errorList___3-VXo .ant-form-item-explain {
  margin: 10px 0;
}
