.MealPrototypeIdsRow___3F7Mi {
  margin-bottom: 24px;
}
.MealPrototypeIdsRow___3F7Mi:last-child {
  margin: 0;
}
.MealPrototypeIdsRow___3F7Mi .tips-wrapper {
  margin-bottom: 8px;
  padding: 6px 0;
  color: #8c8c8c;
  font-size: 14px;
  line-height: 20px;
}
.MealPrototypeIdsRow___3F7Mi .ant-form-item {
  margin-bottom: 0;
}
