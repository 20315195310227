.DateRangeGroupSelector____HFpL .range-picker {
  flex: 1;
  min-width: 0;
  margin-right: 24px;
}
.DateRangeGroupSelector____HFpL .icon-calendar {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-image: url('https://pos-static.chowbus.com/assets/icon_calendar.png');
  background-size: 100%;
  vertical-align: middle;
  margin-right: 4px;
}
.DateRangeGroupSelector____HFpL .ant-radio-button-wrapper {
  font-size: 16px;
  font-weight: 600;
  color: #8C8C8C;
}
.DateRangeGroupSelector____HFpL .ant-radio-button-wrapper-checked {
  border-color: #D9D9D9;
  background: #f5f5f5;
  color: #000000;
}
.DateRangeGroupSelector____HFpL .ant-radio-button-wrapper:focus-within {
  box-shadow: none;
}
.DateRangeGroupSelector____HFpL .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: transparent;
}
.DateRangeGroupSelector____HFpL .ranger-picker-container {
  position: relative;
}
.DateRangeGroupSelector____HFpL .ranger-picker-container .picker {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 586px;
  min-width: 300px;
  max-width: 100%;
  min-height: 350px;
  margin-top: 6px;
}
.DateRangeGroupSelector____HFpL .ranger-picker-container .picker:before {
  content: ' ';
  position: absolute;
  top: -6px;
  left: 20px;
  width: 0;
  height: 0;
  border-width: 0 6px 6px 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.15) transparent;
}
.DateRangeGroupSelector____HFpL .ranger-picker-container .picker:after {
  content: ' ';
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 21px;
  width: 0;
  height: 0;
  border-width: 0 5px 5px 5px;
  border-style: solid;
  border-color: #fff transparent;
}
.DateRangeGroupSelector____HFpL .ranger-picker-container .picker > div {
  position: relative !important;
}
.DateRangeGroupSelector____HFpL .ranger-picker-container .picker .ant-picker {
  width: 100%;
}
.DateRangeGroupSelector____HFpL .ranger-picker-container .picker .ant-picker-panel-container {
  box-shadow: none;
}
@media (max-width: 576px) {
  .DateRangeGroupSelector____HFpL .ranger-picker-container .picker .ant-picker-panels {
    flex-wrap: wrap;
  }
}
.DateRangeGroupSelector____HFpL .cmsSegmentsUsageHeader___1G5R0 {
  font-size: 18px;
  color: #1d1b2e;
  font-weight: bold;
}
.ant-card-body {
  overflow: unset;
}
.chart-dropdown {
  margin-top: 18px;
}
.chart-dropdown .ant-dropdown-menu {
  padding: 0;
}
.chart-dropdown .ant-dropdown-menu-item {
  font-family: 'Proxima Nova';
  padding: 10px 16px;
  color: #1d1b2e;
}
.chart-dropdown .ant-dropdown-menu-item.active {
  background: #ffecf3;
}
