.PageMenuDecorationPreview___2whVP .preview-iframe-container {
  display: block;
  margin: 0 auto;
  position: relative;
  width: auto;
}
.PageMenuDecorationPreview___2whVP .preview-iframe-container.landscape {
  aspect-ratio: 1.6;
  height: calc(100vh - 144px);
}
.PageMenuDecorationPreview___2whVP .preview-iframe-container.portrait {
  height: 1536px;
  aspect-ratio: 0.625;
}
.PageMenuDecorationPreview___2whVP .preview-iframe-container .preview-iframe {
  width: 100%;
  height: 100%;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}
.PageMenuDecorationPreview___2whVP .preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 12px 0;
  position: sticky;
  top: 64px;
  background-color: #f5f5f5;
  z-index: 2;
}
.PageMenuDecorationPreview___2whVP .preview-header .preview-title {
  font-size: 16px;
  color: #000;
}
.PageMenuDecorationPreview___2whVP .loading-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.PageMenuDecorationPreview___2whVP .loading-container a {
  text-decoration: underline;
  margin: 0 4px;
}
.PageMenuDecorationPreview___2whVP .nav-button {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 3;
  width: 60px;
  height: 60px;
  background-size: 100%;
  background-image: url('https://pos-static.chowbus.com/assets/arrow_up@60.png');
  border-radius: 50%;
  background-color: #DBDBDB;
  opacity: 0.8;
  transform: scale(0.8);
  transform-origin: 50% 50%;
  transition: all 0.15s;
  cursor: pointer;
  box-shadow: 0 0 5px 2px rgba(206, 44, 84, 0.5);
}
.PageMenuDecorationPreview___2whVP .nav-button:hover {
  transform: scale(1);
  opacity: 1;
}
.PageMenuDecorationPreview___2whVP .nav-button.bottom {
  transform: scale(0.8) rotate(180deg);
}
.PageMenuDecorationPreview___2whVP .nav-button.bottom:hover {
  transform: scale(1) rotate(180deg);
}
