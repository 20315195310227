.customizations-availability___2tXT0 .availability-content {
  margin-top: 21px;
}
.customizations-availability___2tXT0 .customizations-property {
  margin-bottom: 30px;
}
.customizations-availability___2tXT0 .customizations-property .customizations-property-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 61px;
  line-height: 61px;
  padding: 0 20px;
  background: #ffffff;
}
.customizations-availability___2tXT0 .customizations-property .property-name {
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.customizations-availability___2tXT0 .customizations-property .options-count {
  margin-left: 30px;
}
.customizations-availability___2tXT0 .customizations-property .status_available {
  color: #a3c95e;
}
.customizations-availability___2tXT0 .customizations-property .status_soldout {
  color: #e00051;
}
.customizations-availability___2tXT0 .customizations-property .text-capitalize {
  text-transform: capitalize;
}
