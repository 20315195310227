.CustomizationOptions___1jA-S .customization-item {
  padding: 16px;
  background-color: #fff;
  cursor: pointer;
}
.CustomizationOptions___1jA-S .customization-item .highlight-text {
  color: #E00051;
}
.CustomizationOptions___1jA-S .customization-item .customization-name {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 24px;
}
.CustomizationOptions___1jA-S .customization-item .option-item {
  gap: 16px;
  display: flex;
  flex-wrap: nowrap;
}
.CustomizationOptions___1jA-S .customization-item .option-item + .option-item {
  margin-top: 16px;
}
.CustomizationOptions___1jA-S .customization-item .field-item {
  flex: 1;
}
.CustomizationOptions___1jA-S .customization-item .field-item .field-item-label {
  line-height: 22px;
  color: #8C8C8C;
  margin-bottom: 8px;
}
.CustomizationOptions___1jA-S .customization-item .field-item .highlight-text-wrapper {
  height: 32px;
  padding: 4px 11px;
  color: #000;
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.CustomizationOptions___1jA-S .customization-item .field-item .ant-input-disabled,
.CustomizationOptions___1jA-S .customization-item .field-item .ant-input-number-disabled,
.CustomizationOptions___1jA-S .customization-item .field-item .ant-input-number-affix-wrapper-disabled {
  color: #000;
}
.CustomizationOptions___1jA-S .customization-item + .customization-item {
  margin-top: 12px;
}
