.title-section___1sx3v {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1.5px;
  color: #000000;
}
.title___28JZc {
  text-transform: uppercase;
}
.subtitle___eD6AH {
  color: #1d1b2e;
}
.divider___29M5g {
  background-color: #fffcfc;
  border-width: 1px 0 0;
  margin: 12px 0;
}
.card-item-title___33tj0 {
  color: #636371;
}
.card-item-value___bpCnT {
  color: #1d1b2e;
}
