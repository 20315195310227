/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.OptionItem___3LK6D + .OptionItem___3LK6D {
  margin-top: 16px;
}
.OptionItem___3LK6D .option-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.OptionItem___3LK6D .option-item-header .title {
  font-size: 16px;
  line-height: 20px;
}
.OptionItem___3LK6D .option-item-content {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  padding: 0 8px;
}
.OptionItem___3LK6D .option-item-content .option-field {
  width: 25%;
  padding: 0 8px;
}
.OptionItem___3LK6D .option-item-content.preview .option-field {
  flex-wrap: nowrap;
  width: 14.28571429%;
}
@media (max-width: 1299px) {
  .OptionItem___3LK6D .option-item-content {
    flex-wrap: wrap;
  }
  .OptionItem___3LK6D .option-item-content.preview .option-field {
    width: 25%;
  }
}
.OptionItem___3LK6D .ant-input-disabled,
.OptionItem___3LK6D .ant-input-number-disabled,
.OptionItem___3LK6D .ant-input-number-affix-wrapper-disabled {
  color: #000;
}
.OptionItem___3LK6D .default-option-check .ant-form-item-explain-connected {
  position: absolute;
  white-space: nowrap;
}
