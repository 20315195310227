.LoyaltySettings___1cm4_ {
  padding: 0 12px;
  letter-spacing: 0.05em;
  color: #1d1b2e;
}
.LoyaltySettings___1cm4_ .RGEmphasis___i5jJN {
  color: #ce2c54;
}
.LoyaltySettings___1cm4_ .setting-section .section-title {
  font-size: 28px;
  margin-bottom: 18px;
}
.LoyaltySettings___1cm4_ .setting-section .redirect-new-page {
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 15px;
  font-family: 'Proxima Nova';
}
.LoyaltySettings___1cm4_ .setting-section .redirect-new-page .redirect-new-title {
  height: 44px;
  line-height: 44px;
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 0px;
}
.LoyaltySettings___1cm4_ .setting-section .redirect-new-page .redirect-new-link {
  height: 34px;
  line-height: 34px;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0px;
}
.LoyaltySettings___1cm4_ .setting-section .ant-form-item-label {
  color: #1d1b2e;
  font-size: 18px;
  text-align: left;
  white-space: normal;
}
.LoyaltySettings___1cm4_ .setting-section .ant-input-number {
  width: 144px;
}
.LoyaltySettings___1cm4_ .setting-section .ant-input-number .ant-input-number-input {
  text-align: center;
}
.LoyaltySettings___1cm4_ .setting-section.discount-section .ant-input-number {
  width: 100px;
}
.LoyaltySettings___1cm4_ .setting-section.discount-section .ant-input-number .ant-input-number-handler-wrap {
  display: none;
}
@media (min-width: 992px) {
  .LoyaltySettings___1cm4_ .setting-section .ant-form-item-control {
    text-align: right;
  }
}
.LoyaltySettings___1cm4_ .setting-section .redeem-verification-code-require-setting .ant-radio-wrapper .ant-radio {
  transform: scale(1.4) translate(0, -4px);
  transform-origin: 0 0;
  margin-right: 12px;
}
.LoyaltySettings___1cm4_ .setting-section .redeem-verification-code-require-setting .ant-input-number {
  width: 120px;
}
.LoyaltySettings___1cm4_ .setting-section .redeem-verification-code-require-setting .ant-input-number .ant-input-number-input {
  text-align: left;
}
.LoyaltySettings___1cm4_ .setting-section .redeem-verification-code-require-setting .ant-form-item-explain {
  margin-top: 10px;
  margin-left: 30px;
}
@media (min-width: 992px) {
  .LoyaltySettings___1cm4_ .setting-section .redeem-verification-code-require-setting .ant-form-item-control {
    text-align: left;
  }
}
.LoyaltySettings___1cm4_ .setting-section .definition-item .definition-title {
  font-size: 20px;
}
.LoyaltySettings___1cm4_ .setting-section .definition-item + .definition-item {
  margin-top: 24px;
}
.LoyaltySettings___1cm4_ .setting-section.prepaidCardSettings .prepaidcard-title {
  white-space: nowrap;
}
.LoyaltySettings___1cm4_ .setting-section.prepaidCardSettings .prepaidcard-subtitle {
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 20px;
}
.LoyaltySettings___1cm4_ .setting-section.prepaidCardSettings .ant-form-item {
  margin-bottom: 0;
}
.LoyaltySettings___1cm4_ .setting-section.prepaidCardSettings .remove-action-wrapper {
  padding-left: 8px;
}
@media (max-width: 575px) {
  .LoyaltySettings___1cm4_ .setting-section.prepaidCardSettings .ant-form-item-label {
    min-width: 150px;
    max-width: 150px;
    flex-basis: content;
  }
  .LoyaltySettings___1cm4_ .setting-section.prepaidCardSettings .ant-form-item-control {
    flex: 1;
  }
  .LoyaltySettings___1cm4_ .setting-section.prepaidCardSettings .remove-action-wrapper {
    padding-left: 0px;
  }
}
@media (min-width: 768px) {
  .LoyaltySettings___1cm4_ .setting-section.prepaidCardSettings .ant-form-item-label {
    white-space: nowrap;
    max-width: 170px;
    flex-basis: content;
  }
}
.LoyaltySettings___1cm4_ .setting-section.prepaidCardSettings .prepaidCard-purchaseOption-row {
  margin-bottom: 10px;
}
.LoyaltySettings___1cm4_ .setting-section:has(.redirect-new-page) {
  margin-top: 10px;
}
.LoyaltySettings___1cm4_ .setting-section + .setting-section {
  margin-top: 50px;
}
.LoyaltySettings___1cm4_ .button-wrap {
  margin-bottom: 5px;
}
@media (min-width: 992px) {
  .LoyaltySettings___1cm4_ .button-wrap {
    text-align: right;
    margin-bottom: 0px;
  }
}
.LoyaltySettings___1cm4_ .qr-code-wrapper {
  position: absolute;
  left: -9999px;
  top: 0;
  z-index: 11;
}
.LoyaltySettings___1cm4_ .ant-switch {
  transform: scale(1.4) translate(0, -5px);
  transform-origin: 0 0;
}
.LoyaltySettings___1cm4_ .ant-switch-checked {
  background: #34c759;
}
@media (min-width: 992px) {
  .LoyaltySettings___1cm4_ .switch-wrapper {
    text-align: right;
  }
  .LoyaltySettings___1cm4_ .switch-wrapper .ant-switch {
    transform: scale(1.4) translate(-13px, -5px);
    transform-origin: 0 0;
  }
}
.LoyaltySettings___1cm4_ .switch-wrapper.autopilot-switch-wrapper .autopilot-switch {
  pointer-events: none;
}
.LoyaltySettings___1cm4_ .switch-wrapper.autopilot-switch-wrapper .ant-switch-disabled {
  opacity: 1;
  cursor: pointer;
}
.LoyaltySettings___1cm4_ .switch-wrapper.autopilot-switch-wrapper .ant-switch-disabled .ant-switch-handle {
  cursor: pointer;
}
.LoyaltySettings___1cm4_ .loyalty-canvas {
  position: fixed;
  left: -9999px;
  top: 0;
}
@media (min-width: 576px) {
  .LoyaltySettings___1cm4_ .qrcode-download .ant-col-sm-24 {
    justify-content: flex-start;
  }
}
.LoyaltySettings___1cm4_ .qrcode-download-container {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
}
@media (max-width: 1200px) {
  .LoyaltySettings___1cm4_ .qrcode-download-container {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
.LoyaltySettings___1cm4_ .link___7bOfy {
  padding-left: 10px;
}
