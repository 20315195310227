.GeneralSettingsSettings___1wUQj .ant-card .ant-card-body {
  padding: 24px 16px;
}
.GeneralSettingsSettings___1wUQj .setting-section .section-title {
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
}
.GeneralSettingsSettings___1wUQj .setting-section .section-content .section-title {
  width: 200px;
  margin-right: 16px;
}
.GeneralSettingsSettings___1wUQj .setting-section .section-content .section-content-title {
  width: 200px;
  margin-right: 16px;
  padding: 6px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #000000;
}
.GeneralSettingsSettings___1wUQj .setting-section .section-content .section-content-description {
  margin-bottom: 16px;
  padding: 16px;
  background-color: #fffae7;
  border-radius: 4px;
}
.GeneralSettingsSettings___1wUQj .setting-section .section-content .section-content-description .title-wrapper {
  margin-bottom: 8px;
  color: #000000;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
}
.GeneralSettingsSettings___1wUQj .setting-section .section-content .section-content-description .content-wrapper {
  color: #000000;
  font-size: 14px;
  line-height: 20px;
}
.GeneralSettingsSettings___1wUQj .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  height: 32px;
}
.GeneralSettingsSettings___1wUQj .ant-checkbox-wrapper .ant-checkbox {
  top: auto;
}
.GeneralSettingsSettings___1wUQj .ant-checkbox-wrapper > span:last-child {
  padding: 0 8px;
}
.GeneralSettingsSettings___1wUQj .ant-input-number-group-wrapper .ant-input-number-group-addon {
  min-width: 32px;
}
.GeneralSettingsSettings___1wUQj .ant-radio-wrapper {
  display: flex;
  align-items: center;
  height: 32px;
  margin-top: 4px;
}
.GeneralSettingsSettings___1wUQj .ant-radio-wrapper:first-child {
  margin-top: 0;
}
.GeneralSettingsSettings___1wUQj .ant-radio-wrapper .ant-radio {
  top: auto;
}
.GeneralSettingsSettings___1wUQj .ant-switch {
  transform: initial;
  transform-origin: initial;
}
