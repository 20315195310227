.printer-list___lnQaB .ant-descriptions .ant-descriptions-row > td {
  padding-bottom: 8px;
}
.printer-list___lnQaB .ant-descriptions-row {
  background: transparent !important;
}
.printer-list___lnQaB .editable-cell-value-wrap {
  padding: 3px 11px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 3px;
  height: 31px;
}
.printer-list___lnQaB td:hover .editable-cell-value-wrap {
  border: 1px solid #cccccc;
}
