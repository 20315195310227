.SalesTrendTables___2pPPB {
  height: 565px;
  border: 1px solid #e5e5e5;
}
.SalesTrendTables___2pPPB .rank {
  display: inline-block;
  min-width: 25px;
  height: 25px;
  padding: 0 3px;
  margin-right: 6px;
  border-radius: 50%;
  background: #c0c2cc;
  color: #000000;
  line-height: 26px;
  text-align: center;
}
.SalesTrendTables___2pPPB .rank.emphasis {
  background: #e00051;
  color: #ffffff;
}
.SalesTrendTables___2pPPB .ant-table-title {
  background: #f5f5f5;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  border-color: #e5e5e5;
  padding: 8px;
}
.SalesTrendTables___2pPPB .ant-table-footer {
  padding: 0 !important;
}
.SalesTrendTables___2pPPB .ant-table-footer .summary-row {
  padding: 8px 0;
}
.SalesTrendTables___2pPPB .ant-table-body .ant-table-placeholder {
  background: #ffffff !important;
}
.SalesTrendTables___2pPPB .ant-table-body .ant-table-placeholder td {
  border-bottom: none;
}
.SalesTrendTables___2pPPB .ant-table-body .ant-table-placeholder .ant-empty {
  margin: 165px 0;
}
.SalesTrendTables___2pPPB .ant-table-body .toggle-icon {
  display: block;
  width: 16px;
  height: 16px;
  float: right;
  margin: 4px 0 0 8px;
  background-size: 100%;
  background-image: url('https://pos-static.chowbus.com/assets/collapsed.png');
}
.SalesTrendTables___2pPPB .ant-table-body .toggle-icon.expanded {
  background-image: url('https://pos-static.chowbus.com/assets/expanded.png');
}
.SalesTrendTables___2pPPB .ant-table-pagination.ant-pagination {
  margin: 12px;
}
.SalesTrendTables___2pPPB .btn-wrap {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}
