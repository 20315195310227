.ChartTooltip___2VFOY {
  position: absolute;
  z-index: 3;
  display: flex;
  padding: 16px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(87, 87, 87, 0.0304);
}
.ChartTooltip___2VFOY .legend-bar {
  width: 8px;
  height: 105px;
}
.ChartTooltip___2VFOY .legend-detail {
  margin-left: 16px;
  color: #92929f;
  white-space: nowrap;
  line-height: 1.2;
}
.ChartTooltip___2VFOY .legend-detail .legend-name {
  text-transform: capitalize;
}
.ChartTooltip___2VFOY .legend-detail .legend-desc {
  margin: 9px 0;
}
.ChartTooltip___2VFOY .legend-detail .legend-value {
  color: rgba(29, 27, 46);
  font-size: 18px;
}
.ChartTooltip___2VFOY .legend-detail .legend-ratio {
  margin-top: 9px;
}
.ChartTooltip___2VFOY .legend-detail .legend-ratio .percent {
  padding: 4px 8px;
  border-radius: 80px;
  margin-right: 9px;
}
.ChartTooltip___2VFOY .legend-detail .legend-ratio .percent.total_members {
  background: #effaf9;
  color: #15d0c5;
}
.ChartTooltip___2VFOY .legend-detail .legend-ratio .percent.total_visits {
  background: #fff5e9;
  color: #fe9c22;
}
.ChartTooltip___2VFOY .legend-detail .legend-ratio .percent.total_cashback {
  background: #ffedf3;
  color: #e00051;
}
.ChartTooltip___2VFOY .legend-detail .legend-ratio .percent.total_sign_up {
  background: #effaf9;
  color: #15d0c5;
}
.ChartTooltip___2VFOY .legend-detail .legend-ratio .percent.total_prepaidcard {
  background: #ffedf3;
  color: #e00051;
}
.ChartTooltip___2VFOY .legend-detail .legend-ratio .percent.total_members_by_expiration {
  background: #ffedf3;
  color: #e00051;
}
.ChartTooltip___2VFOY .legend-detail .legend-ratio .percent.total_members_by_purchase {
  background: #fff5e9;
  color: #fe9c22;
}
