/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.blog-article-page {
  background-color: #ffffff !important;
}
.blog-article-page .ant-layout-content {
  padding-top: 10px !important;
}
.PageBlogArticle___17j3f {
  width: 100%;
  margin: 0 auto;
}
.PageBlogArticle___17j3f .backBlog___1tqqJ a {
  color: #1D1B2E;
}
@media (min-width: 767px) {
  .PageBlogArticle___17j3f {
    max-width: 1000px;
  }
}
.PageBlogArticle___17j3f .common-section-section {
  padding-top: 20px !important;
  padding-bottom: 30px !important;
}
.PageBlogArticle___17j3f .PageBlogArticleImage___3kfma {
  object-fit: contain;
  max-width: 100%;
}
.PageBlogArticle___17j3f .PageBlogArticleTitle___26csg {
  margin-bottom: 10px !important;
  font-weight: 600;
  font-style: normal;
  font-weight: 700;
  font-size: 36px !important;
  line-height: 1.2 !important;
  color: #1D1B2E important;
}
.PageBlogArticle___17j3f .PageBlogArticlePubishTime___3NZ0s {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.66;
  color: #87919d;
}
.PageBlogArticle___17j3f .PageBlogArticlePreview___3IGrC {
  margin-top: 24px;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
}
.PageBlogArticle___17j3f .PageBlogArticlePreview___3IGrC::before {
  content: open-quote;
  position: absolute;
  display: inline-block;
  left: 0;
  top: 0;
  font-size: 3.875rem;
  line-height: 1;
  color: #E00051;
}
.PageBlogArticle___17j3f .PageBlogArticlePreview___3IGrC p {
  color: #E00051 !important;
  margin-bottom: 0 !important;
}
.PageBlogArticle___17j3f .typeform___3B5X6 {
  width: 100%;
  height: 620px;
  display: flex;
  justify-content: center;
}
