.ThirdPartyOrdering___2ZV3S .section-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 16px;
}
.ThirdPartyOrdering___2ZV3S .ant-form-item.no-padding {
  margin-bottom: 0;
}
.ThirdPartyOrdering___2ZV3S .ant-form-item.no-padding .ant-form-item-explain {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}
