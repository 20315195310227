.sensitive___3JTtw {
  padding: 10px;
  font-size: 14px;
  background-color: #FFFAE7;
  margin-bottom: 15px;
}
.sensitive___3JTtw ol {
  margin-block-end: 0;
  padding-inline-start: 30px;
}
.sensitive___3JTtw .item___cbWGf {
  margin-bottom: 10px;
}
