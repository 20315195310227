.priceEditorPage___3lkHZ .grouped-menus {
  position: relative;
  z-index: 1;
  scroll-behavior: smooth;
}
.priceEditorPage___3lkHZ .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 48px;
}
.priceEditorPage___3lkHZ .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: 24px;
}
.priceEditorPage___3lkHZ .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}
.priceEditorPage___3lkHZ .ant-table-tbody > tr {
  cursor: pointer;
}
