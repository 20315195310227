.orderReviewPlatformSettings___rXsbV .ruleItem {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}
.orderReviewPlatformSettings___rXsbV .ruleItem .label {
  flex: 1;
  min-width: 200px;
  font-weight: 500;
  line-height: 20px;
  color: #000000;
}
.orderReviewPlatformSettings___rXsbV .ruleItem .ant-form-item {
  flex: 6;
  margin-bottom: 10px;
}
.orderReviewPlatformSettings___rXsbV .ruleItem .ant-form-item .ant-form-item-control-input {
  order: 2;
}
.orderReviewPlatformSettings___rXsbV .ruleItem .ant-form-item .ant-form-item-extra {
  order: 1;
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 20px;
}
.orderReviewPlatformSettings___rXsbV .checkboxGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.orderReviewPlatformSettings___rXsbV .checkboxGroup .ant-checkbox-wrapper {
  width: fit-content;
  margin-left: 0;
}
.orderReviewPlatformSettings___rXsbV .checkboxGroup .ant-checkbox-wrapper .ant-checkbox + span {
  padding-left: 8px;
}
.orderReviewPlatformSettings___rXsbV .ant-switch {
  margin-left: 5px;
}
.orderReviewPlatformSettings___rXsbV .ant-form-item-has-error .ant-form-item-margin-offset {
  margin-bottom: 10px !important;
}
.orderReviewPlatformSettings___rXsbV .tagetplatformAddresInput___2WLm_ {
  width: 310px;
}
.orderReviewPlatformSettings___rXsbV.isMobile___3ODrJ .ruleItem {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
}
.orderReviewPlatformSettings___rXsbV.isMobile___3ODrJ .tagetplatformAddresInputSpace___2sKly {
  width: 100%;
}
.orderReviewPlatformSettings___rXsbV.isMobile___3ODrJ .tagetplatformAddresInputSpace___2sKly .ant-space-item:first-child {
  flex: 1;
}
.orderReviewPlatformSettings___rXsbV.isMobile___3ODrJ .tagetplatformAddresInput___2WLm_ {
  width: 100%;
}
.orderReviewPlatformSettings___rXsbV .thresholdExtra___3h4UI {
  color: #00000073;
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 20px;
}
.orderReviewPlatformSettings___rXsbV .thresholdFormItem___3PnfK {
  display: flex;
  align-items: center;
}
.orderReviewPlatformSettings___rXsbV .thresholdFormItem___3PnfK .thresholdFormItemSuffix___2PReq {
  margin-bottom: 10pxx;
}
.orderReviewPlatformSettings___rXsbV .thresholdFormItem___3PnfK .ant-form-item {
  margin-bottom: 0;
}
