.commentsPictureListContainer___4EANV {
  margin-top: 10px;
  margin-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  display: inline-flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-right: 24px;
  width: calc(100% - 24px);
}
.commentsPictureListContainer___4EANV .commentsPicture___wsbE1 {
  flex-shrink: 0;
  width: calc((100% - 27px) / 3 - 5px);
  margin-right: 9px;
  margin-bottom: 9px;
  margin-top: 9px;
}
.commentsPictureListContainer___4EANV .commentsPicture___wsbE1.showMore___1MWvf {
  margin-left: 24px;
}
.commentsPictureListContainer___4EANV .commentsPicture___wsbE1 .commentsPictureInner___iDXDX {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.commentsPictureListContainer___4EANV .commentsPicture___wsbE1 .commentsPictureInner___iDXDX::before {
  content: none;
  display: block;
  width: 34px;
  height: 34px;
  background-image: url('https://pos-static.chowbus.com/assets/review/review_picture_icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.commentsPictureListContainer___4EANV .commentsPicture___wsbE1 .commentsPictureInner___iDXDX .commentsPicturePlacehorder___2h-Dt {
  color: #000000;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-top: 10px;
}
.commentsPictureListContainer___4EANV .commentsPicture___wsbE1 .commentsPictureInner___iDXDX.commentsPictureUploading___3b6Od {
  border: 1px solid #D9D9D9;
}
.commentsPictureListContainer___4EANV .commentsPicture___wsbE1.default___3bcKq {
  right: 0;
  z-index: 10;
}
.commentsPictureListContainer___4EANV .commentsPicture___wsbE1.default___3bcKq::before {
  content: '';
  position: absolute;
  background-color: #ffffff;
  width: 20px;
  height: 100%;
  right: 0;
  top: 0;
}
.commentsPictureListContainer___4EANV .commentsPicture___wsbE1.default___3bcKq .commentsPictureInner___iDXDX {
  border: 1px dashed #D9D9D9;
  border-radius: 12px;
  background-color: #ffffff;
}
.commentsPictureListContainer___4EANV .commentsPicture___wsbE1.default___3bcKq .commentsPictureInner___iDXDX::before {
  content: '';
}
.commentsPictureListContainer___4EANV .commentsPicture___wsbE1:last-child {
  margin-right: 0;
}
.commentsPictureListContainer___4EANV .commentsPicture___wsbE1 .commentPreviewImage___3UcpT {
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
}
.commentsPictureListContainer___4EANV .commentsPicture___wsbE1 .removeImageIcon___3V5jf {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 20px;
  height: 20px;
  background-image: url('https://pos-static.chowbus.com/assets/review/remove_review_image_close_icon.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 10;
}
.commentsPictureTips___11-9C {
  font-size: 13px;
  font-weight: 400;
  color: #8C8C8C;
  padding: 5px 0;
}
.uploadImageError___35Dsm {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  text-align: center;
  border-radius: 12px;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  padding: 7px;
}
.uploadImageError___35Dsm::before {
  content: none;
  display: block;
  width: 15px;
  height: 15px;
  margin: 3px;
  background-image: url('https://pos-static.chowbus.com/assets/review/remove_review_image_invalid_icon.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.uploading___PNKnq {
  display: block;
  width: 30px;
  height: 30px;
  background-image: url('https://pos-static.chowbus.com/assets/loading_icon.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  animation-name: init_rotate_animation___2NI2n;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-duration: 1500ms;
  transform-origin: center;
}
@keyframes init_rotate_animation___2NI2n {
  from {
    transform: rotate(0);
    opacity: 1;
  }
  to {
    transform: rotate(360deg);
    opacity: 1;
  }
}
