/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.blog-page {
  background-color: #ffffff !important;
}
.blog-page .ant-layout-content {
  padding-top: 10px !important;
}
@media (min-width: 767px) {
  .PageBlog___3uU_q {
    max-width: 1200px;
    margin: 0 auto;
  }
}
.PageBlog___3uU_q .allArticles___2isbb {
  width: 100%;
}
.PageBlog___3uU_q .allArticles___2isbb .backBlogHome___2enQ1 a {
  color: #1D1B2E;
}
.PageBlog___3uU_q .allArticles___2isbb .common-section-section {
  padding-top: 20px;
  padding-bottom: 30px;
}
.PageBlog___3uU_q .allArticles___2isbb .backBlogHome___2enQ1 {
  margin-bottom: 10px;
}
.PageBlog___3uU_q .allArticles___2isbb .paginationBottom___1-K5O {
  margin-top: 30px;
  width: 100%;
  text-align: center;
}
