/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.BindDishesSettings___2DDXN {
  height: 100%;
}
.BindDishesSettings___2DDXN .category-list {
  height: calc(100% - 32px);
  padding-right: 20px;
  overflow-y: auto;
}
.BindDishesSettings___2DDXN .bind-dishes-setting-card {
  height: inherit;
}
.BindDishesSettings___2DDXN .bind-dishes-setting-card .ant-card-body {
  min-height: 200px;
  height: 100%;
}
.BindDishesSettings___2DDXN .retry-wrapper {
  padding-top: 100px;
  text-align: center;
}
.BindDishesSettings___2DDXN .print-setting-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
}
.BindDishesSettings___2DDXN .print-setting-content {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
.BindDishesSettings___2DDXN .scroll-panel {
  white-space: nowrap;
  height: calc(100vh - 310px);
}
.BindDishesSettings___2DDXN .scroll-panel > div {
  display: inline-block;
  height: 100%;
}
@media (min-width: 992px) {
  .BindDishesSettings___2DDXN .scroll-panel {
    height: calc(100vh - 150px);
  }
}
.BindDishesSettings___2DDXN .list-item {
  display: flex;
  align-items: center;
}
.BindDishesSettings___2DDXN .list-item .list-item-content {
  width: 250px;
  padding: 10px 14px;
  background: #fff;
  border: 1px solid #dde4e7;
  border-radius: 10px;
  cursor: pointer;
  white-space: normal;
}
.BindDishesSettings___2DDXN .list-item .list-item-content.selected {
  background: #ffe4a2;
}
.BindDishesSettings___2DDXN .list-item .list-item-content.disabled {
  background: rgba(196, 196, 196, 0.2);
  cursor: not-allowed;
}
.BindDishesSettings___2DDXN .list-item .toggle-handle {
  width: 32px;
  margin-left: 8px;
}
.BindDishesSettings___2DDXN .list-item .view-other {
  margin-left: 8px;
}
.BindDishesSettings___2DDXN .list-item + .list-item {
  margin-top: 15px;
}
.BindDishesSettings___2DDXN .dishitem-list-wrap {
  padding: 10px 20px 10px 0;
  overflow-y: auto;
}
.BindDishesSettings___2DDXN .menu-divider {
  margin: 0 30px;
  border-color: #ccc;
  vertical-align: initial;
}
