.ServiceSettings___JvjWs .group .group-header {
  display: flex;
  justify-content: space-between;
}
.ServiceSettings___JvjWs .group .group-title {
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}
.ServiceSettings___JvjWs .group .group-content {
  margin-top: 22px;
}
.ServiceSettings___JvjWs .group .group-content .description-primary {
  color: #000000;
}
.ServiceSettings___JvjWs .group .group-content .description-secondary {
  display: inline-flex;
  position: relative;
  padding: 5px 10px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  color: #92929f;
  background: #f5f5f5;
}
.ServiceSettings___JvjWs .group .group-content .description-secondary::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  bottom: 0;
  background: #e00051;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.ServiceSettings___JvjWs .ant-card-body {
  padding: 24px 24px 0 24px;
}
.ServiceSettings___JvjWs .setting-wrapper + .setting-wrapper {
  margin-top: 16px;
}
.ServiceSettings___JvjWs .section-name {
  font-size: 18px;
  color: #999;
  margin-bottom: 10px;
}
.ServiceSettings___JvjWs .ant-radio-wrapper {
  white-space: normal;
}
.ServiceSettings___JvjWs .ant-form-item-label {
  text-align: left;
}
.ServiceSettings___JvjWs .ant-form-item-label > label {
  height: auto;
  white-space: normal;
}
.ServiceSettings___JvjWs .ant-form-item-label > label .helperText {
  color: #e00051;
  margin-left: 4px;
}
.ServiceSettings___JvjWs .ant-form-item-label > label .description {
  color: #5e5e5e;
}
.ServiceSettings___JvjWs .ant-form-item-label > label .anticon {
  line-height: 1;
}
.ServiceSettings___JvjWs .ant-table .ant-checkbox-wrapper .ant-checkbox {
  transform-origin: 0 0;
}
.ServiceSettings___JvjWs .help-icon {
  cursor: pointer;
  margin-left: 4px;
}
.ServiceSettings___JvjWs .section-description {
  color: #92929F;
  font-family: proxima-nova;
}
.ServiceSettings___JvjWs .settings-block .settings-title {
  margin-bottom: 10px;
}
.ServiceSettings___JvjWs .settings-block .settings-content {
  margin-left: 30px;
}
.ServiceSettings___JvjWs .settings-block .settings-section .settings-section-header {
  margin-bottom: 18px;
}
.ServiceSettings___JvjWs .settings-block .settings-section .settings-section-header .section-description {
  color: #92929f;
}
.ServiceSettings___JvjWs .settings-block .settings-section .settings-section-content {
  margin-left: 30px;
}
.ServiceSettings___JvjWs .settings-block .settings-section .ant-radio-group {
  display: block;
}
.ServiceSettings___JvjWs .settings-block .settings-section .secondary-title {
  margin-bottom: 16px;
}
.ServiceSettings___JvjWs .settings-block .settings-section .settings-options .option-item + .option-item {
  margin-top: 16px;
}
.ServiceSettings___JvjWs .settings-block .settings-section .settings-options .ant-radio-wrapper {
  display: flex;
}
.ServiceSettings___JvjWs .settings-block .settings-section .settings-options .ant-radio-wrapper .ant-radio ~ span {
  flex: 1;
  min-width: 0;
  margin-top: -3px;
}
.ServiceSettings___JvjWs .settings-block .settings-section + .settings-section {
  margin-top: 16px;
}
.ServiceSettings___JvjWs .settings-block + .settings-block {
  margin-top: 16px;
}
.ServiceSettings___JvjWs .enter-PIN-settings {
  margin-bottom: 24px;
}
.ServiceSettings___JvjWs .enter-PIN-settings .settings-title {
  margin-bottom: 10px;
}
.ServiceSettings___JvjWs .enter-PIN-settings .settings-content {
  margin-left: 30px;
}
.ServiceSettings___JvjWs .enter-PIN-settings .settings-section .settings-section-header {
  margin-bottom: 18px;
}
.ServiceSettings___JvjWs .enter-PIN-settings .settings-section .settings-section-header .section-description {
  color: #92929f;
}
.ServiceSettings___JvjWs .enter-PIN-settings .settings-section .settings-section-content .ant-radio-group {
  display: block;
}
.ServiceSettings___JvjWs .enter-PIN-settings .settings-section .settings-section-content .secondary-title {
  margin-bottom: 16px;
}
.ServiceSettings___JvjWs .enter-PIN-settings .settings-section .settings-section-content .settings-options .option-item + .option-item {
  margin-top: 16px;
}
.ServiceSettings___JvjWs .enter-PIN-settings .settings-section .settings-section-content .settings-options .ant-radio-wrapper {
  display: flex;
}
.ServiceSettings___JvjWs .enter-PIN-settings .settings-section .settings-section-content .settings-options .ant-radio-wrapper .ant-radio ~ span {
  flex: 1;
  min-width: 0;
  margin-top: -3px;
}
.title___2GqfB {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.title___2GqfB h2 {
  margin-bottom: 0;
}
.title___2GqfB .ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}
.title___2GqfB .ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  padding: 0;
  font-size: 20px;
}
