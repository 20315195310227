.MemberPickerModal___1bfkl .title {
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
}
.MemberPickerModal___1bfkl .description {
  margin-top: 6px;
  font-size: 16px;
  line-height: 16px;
}
