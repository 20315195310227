.receipt___2kxVj {
  width: 100vw;
  padding: 0 !important;
  margin: 0 auto;
  min-width: 100% !important;
  width: 100% !important;
  -webkit-text-size-adjust: none;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background: #c6c6c6;
  font-family: 'Proxima Nova', 'PingFang SC';
  height: 100vh;
}
.receiptContainer___1gHm_ {
  min-height: calc(var(--app-height));
  margin: 0 !important;
  display: block;
  -webkit-text-size-adjust: none;
  max-width: 414px;
  width: 100%;
  padding: 0px 15px 45px 15px;
  height: calc(var(--app-height));
  overflow-y: auto;
  background-color: #f5f5f5;
}
.receipt___2kxVj.error___3BIiH .receiptContainer___1gHm_ {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoHeader___2pbLa {
  margin-top: 21px;
  height: 80px;
  width: 100%;
  overflow: hidden;
  text-align: center;
  position: relative;
}
.logoHeader___2pbLa img {
  max-height: 100%;
  width: auto !important;
}
.title___1TxSy {
  color: #1d1b2e;
  font-family: 'Proxima Nova', Arial, sans-serif;
  font-size: 24px !important;
  line-height: 29px;
  text-align: center;
  font-weight: 700;
  margin: 20px 0;
}
.orderSection___3flor {
  background-color: #ffffff;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-weight: 400;
  line-height: 22px;
  font-size: 12px !important;
  color: #85898c;
  padding: 15px 12px 18px 12px;
  margin-top: 16px;
  border-radius: 12px;
}
.orderSummary___3mtYY {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.orderSummary___3mtYY .summaryItem___1uw0T {
  line-height: 22px;
  font-size: 14px !important;
  font-weight: 600;
  background: transparent;
  color: #1d1b2e;
}
.divideLine___20PtE {
  height: 1px;
  background-color: #dadada;
  width: 100%;
  margin: 16px 0;
}
.orderItems___37kJx {
  width: 100%;
}
.orderItemsHeader___2HsBO {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.itemColumn___1V6Mk {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #92929f;
}
.itemColumn___1V6Mk.itemCount___gu-S6 {
  font-weight: 500;
  width: 50px;
}
.itemColumn___1V6Mk.itemName___VjjLY {
  font-weight: 500;
  flex: 1;
  margin-right: 5px;
}
.itemColumn___1V6Mk.itemAmount___NH3iI {
  font-weight: 500;
  width: 60px;
  display: flex;
  justify-content: flex-end;
}
.orderItemsHeader___2HsBO .itemColumn___1V6Mk {
  font-style: normal;
  font-size: 13px;
  line-height: 24px;
  color: #1d1b2e;
  font-weight: 600;
}
.orderItemRow___3MHsI {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-weight: bold;
  line-height: 22px;
  color: #696969;
  font-size: 12px !important;
  min-height: 35px;
  margin: 8px 0;
}
.orderItemRow___3MHsI.hasCustomizations___380DZ {
  margin-bottom: 0;
}
.orderItemCustomization___1kSC_ {
  width: 100%;
  padding-left: 50px;
}
.orderItemCustomization___1kSC_ .option___1S1fv {
  display: block;
}
.orderItemRow___3MHsI .itemColumn___1V6Mk.itemName___VjjLY {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.mealName___7Pn53 {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #92929f;
}
.mealForeignName___4He0C {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #92929f;
}
.mealCustomizations___22ngv {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #92929f;
}
.giftcardBonus___2Jrgu {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #92929f;
}
.orderLineItems___3l4Hk {
  width: 100%;
}
.orderLineItemsRow___VYkFH {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  color: #1d1b2e;
  font-size: 14px !important;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  font-family: 'Proxima Nova';
  height: 28px;
}
.orderLineItemsRow___VYkFH.subtract___2Ubfk {
  color: #e00051;
}
.lineItemName___1-_nC {
  flex: 1;
  font-size: 14px;
  line-height: 24px;
}
.lineItemAmount___1QYkT {
  width: 60px;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  line-height: 24px;
}
.orderTotal___3EqVW {
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1d1b2e;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.totalTitle___3q97W {
  flex: 1;
  font-size: 20px;
  line-height: 24px;
}
.totalAmount___3DXWm {
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
  line-height: 24px;
}
.chowbusAppDownloadContainer___FqUIC {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 28px;
}
.chowbusAppDownloadContainer___FqUIC .left___1EsMt {
  flex: 232;
  margin-right: 23px;
  margin-top: 12px;
}
.chowbusAppDownloadContainer___FqUIC .right___32gk5 {
  flex: 88;
}
.downloadPromotionSection___1fsb5 {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
}
.downloadPromotionSection___1fsb5::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 32px;
  height: 6px;
  background-color: #e00051;
}
.downloadPromotionTitle___2EIvS {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  text-transform: uppercase;
  color: #241e3f;
  white-space: pre-line;
}
.downloadPromotionSubTitle___NOXRC {
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 13px;
  letter-spacing: -0.32px;
  color: #92929f;
  margin-top: 5px;
}
.downloadLinks___3Wjbk {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-end;
  margin-top: 18px;
}
.downloadLinks___3Wjbk .store___2WQ4f {
  flex: 1;
}
.downloadLinks___3Wjbk .store___2WQ4f:last-child {
  margin-left: 7px;
}
.androidAppStore___24YNX {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('https://pos-static.chowbus.com/assets/chowbusapp/Google_Play_Store_downloadpng.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.appleAppStore___qz6wu {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('https://pos-static.chowbus.com/assets/chowbusapp/Apple_App_Store_download.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.appDLPhone___yH0GN {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('https://pos-static.chowbus.com/assets/chowbusapp/chowbus_app_download_phone.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.poweredByChowbusContainer___2nDlP {
  width: 200px;
  margin: 0 auto;
  margin-top: 28px;
}
.powerbyChowbuslogo___27i_t {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('https://pos-static.chowbus.com/assets/powered by chowbus_line.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.signatureContainer___10oqD {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  margin-bottom: 5px;
}
.signatureContainer___10oqD::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: #dadada;
}
.signatureContainer___10oqD .signatureTitle___xTo6w {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #1d1b2e;
  margin-bottom: 5px;
}
.signatureContainer___10oqD .signatureImag___RaWqM {
  flex: 1;
  margin-bottom: 5px;
}
.signatureContainer___10oqD .signatureImag___RaWqM img {
  width: 100%;
  object-fit: contain;
  max-height: 100%;
}
.bottomSection___3r-01 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.bottomSection___3r-01 .title {
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}
.bottomSection___3r-01 .second {
  color: #8E8E8E;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}
.bottomSection___3r-01 .third {
  color: #ffffff;
  background-color: #E00051;
  border-radius: 4px;
  padding: 6.4px 12px 6.4px 12px;
  margin-top: 20px;
}
.bottomSection___3r-01 .fourth {
  display: flex;
  align-items: center;
  margin-top: 35px;
}
.bottomSection___3r-01 .fourth img {
  margin-right: 5px;
}
