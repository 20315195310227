.CustomizationGuide___1Um6J {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 36px;
  column-gap: 48px;
  min-height: 100%;
  padding: 48px 0;
}
.CustomizationGuide___1Um6J.large___1dYz6 {
  padding: 66px 0;
  column-gap: 84px;
}
.CustomizationGuide___1Um6J.large___1dYz6 .guide-desc {
  width: 422px;
}
.CustomizationGuide___1Um6J .guide-item {
  color: #000;
}
.CustomizationGuide___1Um6J .guide-item .guide-target {
  font-size: 24px;
  line-height: 30px;
}
.CustomizationGuide___1Um6J .guide-item .guide-image {
  margin: 24px 0;
}
.CustomizationGuide___1Um6J .guide-item .guide-desc {
  width: 360px;
}
.CustomizationGuide___1Um6J .guide-item .guide-desc p {
  margin: 0;
}
