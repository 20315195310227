/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.title___3Nb4I {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.title___3Nb4I h2 {
  margin-bottom: 0;
  font-size: 20px;
}
.title___3Nb4I button {
  width: 100px;
}
.CustomizationsCombinationDetail___3ZHz4 .setting-section .section-title {
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
}
.CustomizationsCombinationDetail___3ZHz4 .setting-section .section-content .section-title {
  width: 200px;
  margin-right: 24px;
}
.CustomizationsCombinationDetail___3ZHz4 .setting-section .section-content .section-content-title {
  width: 200px;
  margin-right: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  color: #000000;
}
.CustomizationsCombinationDetail___3ZHz4 .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  height: 32px;
}
.CustomizationsCombinationDetail___3ZHz4 .ant-checkbox-wrapper .ant-checkbox {
  top: auto;
}
.CustomizationsCombinationDetail___3ZHz4 .ant-checkbox-wrapper > span:last-child {
  padding: 0 8px;
}
.CustomizationsCombinationDetail___3ZHz4 .ant-input-number-group-wrapper .ant-input-number-group-addon {
  min-width: 32px;
}
.CustomizationsCombinationDetail___3ZHz4 .ant-radio-wrapper {
  display: flex;
  align-items: center;
  height: 32px;
  margin-top: 4px;
}
.CustomizationsCombinationDetail___3ZHz4 .ant-radio-wrapper:first-child {
  margin-top: 0;
}
.CustomizationsCombinationDetail___3ZHz4 .ant-radio-wrapper .ant-radio {
  top: auto;
}
.CustomizationsCombinationDetail___3ZHz4 .ant-switch {
  transform: initial;
  transform-origin: initial;
}
