/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.PageUserActivities___2vFlc .page-copy {
  font-size: 18px;
  color: #000000;
}
.PageUserActivities___2vFlc .ant-table thead {
  font-family: 'Proxima Nova';
}
.PageUserActivities___2vFlc .tools {
  padding-top: 6px;
  color: red;
  display: flex;
  align-items: center;
  margin-left: auto;
}
.PageUserActivities___2vFlc .tools .flex-icon {
  display: flex;
}
@media (min-width: 1300px) {
  .PageUserActivities___2vFlc .actions-col {
    margin-top: 28px;
  }
}
