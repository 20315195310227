.Tips___3HiwP .toggle-icon {
  display: block;
  float: right;
  width: 16px;
  height: 16px;
  margin: 4px 0 0 8px;
  background-size: 100%;
  background-image: url('https://pos-static.chowbus.com/assets/collapsed.png');
}
.Tips___3HiwP .toggle-icon.expanded {
  background-image: url('https://pos-static.chowbus.com/assets/expanded.png');
}
.Tips___3HiwP .recipient-role {
  font-size: 14px;
  color: #9b9b9b;
  line-height: 1;
}
.Tips___3HiwP .receipient-item + .receipient-item {
  margin-top: 8px;
}
.Tips___3HiwP .source-group .group-name {
  color: #9b9b9b;
  line-height: 24px;
  margin-bottom: 8px;
}
.Tips___3HiwP .source-group .group-items .group-item + .group-item {
  margin-top: 8px;
}
.Tips___3HiwP .source-group + .source-group {
  margin-top: 16px;
}
.Tips___3HiwP .payment-badge {
  margin-right: 4px;
  font-size: 14px;
  line-height: 1;
}
.Tips___3HiwP .payment-badge.paid {
  color: #15D0C5;
}
.Tips___3HiwP .payment-badge.unpaid {
  color: #ce2c54;
}
