.RadioCardGroup___2UILE {
  display: flex;
}
.RadioCardGroup___2UILE > * {
  flex: 1;
  margin-right: 16px;
}
.RadioCardGroup___2UILE > *:last-child {
  margin-right: 0;
}
