.rate_icon___2Ex0W {
  width: 130px;
}
.rate_star_container___2rYqn {
  width: calc(100% - 20px);
  margin: 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.rate_star_container___2rYqn .rate_star___1Suup {
  width: calc((100% - 4 * 6px) / 5);
  margin-right: 3px;
  margin-left: 3px;
}
.rate_star_container___2rYqn .rate_star___1Suup .rate_star_point_disable___3t8le {
  pointer-events: none;
}
.reviewBodySectionScroll___3Fn3Z {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  position: relative;
}
.reviewBodySectionScroll___3Fn3Z .commentsContainer___DO03I {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
.commentsContainer___DO03I {
  scroll-snap-align: end;
  scroll-snap-stop: always;
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  min-height: unset;
  overflow-y: hidden;
  padding-bottom: 56px;
  flex: unset;
  min-height: calc(100% - 0px);
}
.commentsContainer___DO03I .innerSection___2-8pp {
  padding-left: 24px;
  padding-right: 24px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}
.commentsSectionActionButton___gCMN0 {
  position: sticky;
  bottom: 0px;
  width: 100%;
  padding: 8px 24px 18px 24px;
  flex-shrink: 0;
  background-color: #ffffff;
  bottom: -18px;
  height: 74px;
  z-index: 10;
}
.commentsTextContainer___3qhuP {
  position: relative;
  width: 100%;
  background-color: transparent;
  margin-top: 20px;
  flex-shrink: 0;
  padding: 16px 16px 34px 16px;
  background-color: #F5F5F5;
  border-radius: 12px;
}
.commentsTextContainer___3qhuP textarea {
  background-image: url('https://pos-static.chowbus.com/assets/review/entry_placeholder_icon.png');
  background-size: 20px 20px;
  background-position: 5px 2px;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  resize: none;
  -webkit-appearance: none;
  outline: 0;
  border: 0;
  box-sizing: border-box;
  margin: 0;
  list-style: none;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  text-align: left;
  font-family: inherit;
  background-color: transparent;
}
.commentsTextContainer___3qhuP textarea.hidePlacehoder___1DDSO {
  background-image: none;
}
.commentsTextContainer___3qhuP textarea::placeholder {
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #BFBFBF;
  opacity: 0.8;
  font-size: 16px;
  line-height: 20px;
  padding-left: 30px;
}
.commentsTextContainer___3qhuP .countTips___1u9X9 {
  position: absolute;
  right: 16px;
  bottom: 10px;
  color: #BFBFBF;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
@keyframes init_rotate_animation___3BbF7 {
  from {
    transform: rotate(0);
    opacity: 1;
  }
  to {
    transform: rotate(360deg);
    opacity: 1;
  }
}
.continueDesc___nHHy0 {
  font-size: 14px;
}
