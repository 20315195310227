.OvertimeSettingsModal___1yF5V .ant-modal-body {
  font-size: 16px;
  min-height: 300px;
}
.OvertimeSettingsModal___1yF5V .ant-modal-body .title {
  font-weight: 600;
  margin-bottom: 16px;
  font-size: 16px;
  height: 20px;
}
.OvertimeSettingsModal___1yF5V .ant-modal-body .ant-row {
  margin-bottom: 16px;
}
.OvertimeSettingsModal___1yF5V .ant-modal-body .ant-row .ant-form-item {
  margin-bottom: 0;
}
.OvertimeSettingsModal___1yF5V .ant-modal-body .ant-row .ant-form-item .ant-form-item-row {
  margin-bottom: 0;
  display: flex;
}
.OvertimeSettingsModal___1yF5V .ant-modal-body .ant-row .ant-form-item .ant-form-item-row .ant-form-item-label {
  height: 32px;
  text-align: left;
  padding: 0;
  display: flex;
  align-items: center;
}
.OvertimeSettingsModal___1yF5V .ant-modal-body .ant-row .ant-form-item .ant-form-item-row .ant-form-item-label > label {
  height: auto;
  white-space: normal;
}
.OvertimeSettingsModal___1yF5V .ant-modal-body .ant-row .ant-form-item .ant-form-item-row .ant-input-number {
  width: 100%;
}
.OvertimeSettingsModal___1yF5V .ant-modal-body .errorList.ant-form-item-explain {
  margin-top: -16px;
}
.OvertimeSettingsModal___1yF5V .ant-modal-body .overtimeType .ant-radio-wrapper {
  margin-right: 100px;
}
.OvertimeSettingsModal___1yF5V .ant-modal-body .overtimeType .ant-radio-wrapper:last-child {
  margin-right: 0;
}
