/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.CashStatement___2dBmn .page-copy {
  font-size: 18px;
  color: #000000;
  margin-bottom: 20px;
}
.CashStatement___2dBmn .field-title {
  width: 100px;
  font-size: 18px;
  color: #1d1b2e;
  text-align: right;
}
@media (max-width: 575px) {
  .CashStatement___2dBmn .field-title {
    text-align: left;
  }
}
.CashStatement___2dBmn .table-header {
  display: flex;
  justify-content: space-between;
}
.CashStatement___2dBmn .table-header .table-header-title {
  letter-spacing: 0.05em;
}
.CashStatement___2dBmn .table-header .table-actions {
  display: inline-flex;
  line-height: 1;
  margin-right: 12px;
}
.CashStatement___2dBmn .ant-table thead {
  text-transform: uppercase;
  font-family: 'Proxima Nova';
}
.CashStatement___2dBmn .ant-table .ant-table-title {
  border-width: 1px 1px 0;
  border-style: solid;
  border-color: #f0f0f0;
}
.CashStatement___2dBmn .ant-table .ant-table-content {
  font-size: 14px;
  color: #1d1b2e;
}
