/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.title___2wtO6 {
  width: 368px;
  margin: 40px 0 20px 0;
  font-size: 16px;
}
.main___21DNm {
  width: 368px;
  margin: 50px auto 0;
}
.main___21DNm .icon___3oNfH {
  margin-left: 16px;
  color: rgba(0, 0, 0, 0.2);
  font-size: 24px;
  vertical-align: middle;
  cursor: pointer;
  transition: color 0.3s;
}
.main___21DNm .icon___3oNfH:hover {
  color: #ce2c54;
}
.main___21DNm .other___2_A2L {
  margin-top: 24px;
  line-height: 22px;
  text-align: left;
}
.main___21DNm .other___2_A2L .register___3s3x6 {
  float: right;
}
.main___21DNm .lang-title {
  margin-bottom: 10px;
  font-size: 16px;
}
.link___WQsGv {
  font-size: 16px;
  text-align: center;
}
.hubspotPopupOverlay___3vrLt {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100%;
  text-align: center;
  top: 0;
  left: 0;
  z-index: 99999 !important;
  padding: 30px 0 ;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hubspotPopupOverlay___3vrLt .hubspotpopup___UNFre {
  width: 600px;
  height: 100%;
  background-color: #ffffff;
  position: relative;
}
.hubspotPopupOverlay___3vrLt .hubspotpopup___UNFre .hubspotpopupMain___cshqx {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.hubspotPopupOverlay___3vrLt .closeIcon___1fSoW {
  background: url(https://cdn.filestackcontent.com/HuaIq7oFQwWyS5Fjni9e);
  background-repeat: no-repeat;
  position: absolute;
  top: 10px !important;
  right: 10px;
  width: 29px;
  height: 28px;
  cursor: pointer;
  z-index: 99999 !important;
}
