.availability___3C-SG .status_available {
  color: #a3c95e;
}
.availability___3C-SG .status_soldout {
  color: #e00051;
}
.availability___3C-SG .highlight-text {
  color: #e00051;
}
.category-actions {
  margin: 20px 0 10px 0;
  text-align: right;
}
