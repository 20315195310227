/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.articlePreviewCard___163kl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 240px;
  cursor: pointer;
}
.articlePreviewCard___163kl .articlePreviewCardImage___2yBCx {
  flex: unset;
  width: 100%;
}
.articlePreviewCard___163kl .articlePreviewCardImage___2yBCx .articlePreviewImage___1YnuP {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.articlePreviewCard___163kl .articlePreviewCardContent___1ei3y {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.articlePreviewCard___163kl .articlePreviewCardContent___1ei3y .articlePreviewCardTitle___1YgyK {
  padding: 8px 0 8px 0px;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}
.articlePreviewCard___163kl .articlePreviewCardContent___1ei3y .articlePreviewCardPreview___jVlS- {
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  flex: 1;
  overflow: hidden;
}
.articlePreviewCard___163kl .articlePreviewCardContent___1ei3y .articlePreviewCardPublishTime___1vvz2 {
  width: 100%;
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.66;
  color: #87919d;
}
.articlePreviewCard___163kl.articlePreviewCardHorizontal___1I8-N {
  flex-direction: row;
  align-items: center;
  min-height: unset;
  height: 100%;
}
.articlePreviewCard___163kl.articlePreviewCardHorizontal___1I8-N .articlePreviewCardImage___2yBCx {
  flex: 3;
}
.articlePreviewCard___163kl.articlePreviewCardHorizontal___1I8-N .articlePreviewCardContent___1ei3y {
  flex: 7;
  padding: 0 10px 10px 10px;
  height: 100%;
}
@media (max-width: 575px) {
  .articlePreviewCard___163kl.articlePreviewCardHorizontal___1I8-N {
    flex-direction: column;
  }
  .articlePreviewCard___163kl.articlePreviewCardHorizontal___1I8-N .articlePreviewCardImage___2yBCx {
    flex: unset;
  }
  .articlePreviewCard___163kl.articlePreviewCardHorizontal___1I8-N .articlePreviewCardContent___1ei3y {
    flex: 7;
    padding: 0 12px 12px 12px;
    height: 100%;
  }
}
