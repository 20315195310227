.EllipsisedContent___2DoLW {
  height: 32px;
  padding: 4px 11px;
  color: #000;
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
