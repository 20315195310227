/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.AccountSettings___1qBGb .ant-card + .ant-card {
  margin-top: 10px;
}
.AccountSettings___1qBGb .section .section-title {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}
.AccountSettings___1qBGb .section .section-content .ant-form-item:last-child {
  margin-bottom: 0;
}
.AccountSettings___1qBGb .secret-password {
  display: inline-block;
  vertical-align: middle;
}
.AccountSettings___1qBGb .error-helper {
  color: #ce2c54;
}
.AccountSettings___1qBGb .tooltipLis {
  height: auto;
}
.AccountSettings___1qBGb .tooltipLis > div {
  height: 32px;
  line-height: 32px;
}
.AccountSettings___1qBGb .cutOffTime > .ant-col {
  margin-bottom: 16px;
}
.AccountSettings___1qBGb .cutOffTime .ant-form-item-label > label.ant-form-item-no-colon {
  height: auto;
}
