/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.BlacklistManagement___EJ29V {
  margin: 0 -16px -16px;
}
.BlacklistManagement___EJ29V .blacklist-management-header {
  padding: 12px 16px;
  position: sticky;
  z-index: 10;
  background: #f0f2f5;
}
.BlacklistManagement___EJ29V .blacklist-management-header .ant-breadcrumb {
  line-height: 24px;
}
.BlacklistManagement___EJ29V .blacklist-management-header .ant-breadcrumb .ant-breadcrumb-separator {
  margin: 0 8px 0 4px;
}
.BlacklistManagement___EJ29V .blacklist-management-content {
  margin: 0 16px;
}
