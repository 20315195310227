/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.pricing-page {
  background-color: #F5F5F5 !important;
}
.pricing-page .ant-layout-content {
  padding-top: 80px;
}
.PagePricing___2c7lf .topSection___3J4mh .common-section-section {
  padding-top: 0 !important;
}
.PagePricing___2c7lf .chowbusTagsSection___1gFRD .common-section-section {
  padding-top: 60px !important;
  padding-bottom: 80px !important;
}
.PagePricing___2c7lf .successCaseSection___DwsL1 {
  margin-top: 40px;
}
.PagePricing___2c7lf .successCaseSection___DwsL1 .section-title {
  white-space: pre-line;
}
@media (min-width: 1600px) {
  .PagePricing___2c7lf .pricingWallet___ZqmTy .ant-col-xxl-5 {
    flex-basis: 20%;
  }
}
@media (min-width: 992px) {
  .PagePricing___2c7lf .pricingWallet___ZqmTy .ant-col-xxl-5 {
    flex-basis: 20%;
  }
}
@media (min-width: 768px) {
  .PagePricing___2c7lf .pricingWallet___ZqmTy .ant-col-xxl-5 {
    flex-basis: 20%;
  }
}
@media (max-width: 575px) {
  .PagePricing___2c7lf {
    margin-bottom: 0px;
  }
}
.PagePricing___2c7lf .features-section .common-description {
  white-space: pre-line;
}
.PagePricing___2c7lf .action-button {
  height: 56px;
  line-height: 56px;
  font-size: 24px;
  border-radius: 34px;
  background: #e00051;
  padding: 16px 24px;
  width: 100%;
}
@media (max-width: 575px) {
  .PagePricing___2c7lf .action-button {
    margin-top: 20px;
    flex: 1;
    font-size: 18px;
    line-height: normal;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}
.PagePricing___2c7lf .pricingCard___3TrWX {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  background: #FFFFFF;
  border-radius: 16px;
}
@media (min-width: 1088px) {
}
.PagePricing___2c7lf .pricingCard___3TrWX .pricingTop___27FNb {
  width: 100%;
  padding: 0 10px;
}
.PagePricing___2c7lf .pricingCard___3TrWX .pricingTop___27FNb .pricingPromtionTagWrapper___3UJB3 {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
  justify-content: center;
  height: 30px;
}
.PagePricing___2c7lf .pricingCard___3TrWX .pricingTop___27FNb .pricingPromtionTag___wMmsG {
  padding: 2px 12px;
  background: #E00051;
  border-radius: 14px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: normal;
  color: #FFFFFF;
}
.PagePricing___2c7lf .pricingCard___3TrWX .pricingTop___27FNb .pricingTitle___3hSsy {
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;
  color: #1D1B2E;
  text-align: center;
  white-space: pre-line;
}
.PagePricing___2c7lf .pricingCard___3TrWX .pricingTop___27FNb .pricingAmountDesc___3sAo2 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  color: #1D1B2E;
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 16px 0;
}
.PagePricing___2c7lf .pricingCard___3TrWX .pricingTop___27FNb .pricingAmountDesc___3sAo2 .pricingSuffix___1eQ-U {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #92929F;
}
.PagePricing___2c7lf .pricingCard___3TrWX .includeBasicPackageTitle___3dCW6 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #E00051;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  padding: 0px 12px;
}
.PagePricing___2c7lf .pricingCard___3TrWX .includeBasicPackageTitle___3dCW6.subTtile___38Xeq {
  font-size: 15px;
  margin-top: 5px;
  color: #1D1B2E;
  line-height: 19px;
}
.PagePricing___2c7lf .pricingCard___3TrWX .serviceItemList___3libB {
  width: 100%;
  overflow: hidden;
  margin-top: 16px;
  padding: 0px 12px;
}
.PagePricing___2c7lf .pricingCard___3TrWX .serviceItemList___3libB .serviceItem___E0Ari {
  width: 100%;
  margin-top: 24px;
}
.PagePricing___2c7lf .pricingCard___3TrWX .serviceItemList___3libB .serviceItem___E0Ari:first-child {
  margin-top: 0px;
}
.PagePricing___2c7lf .pricingCard___3TrWX .serviceItemList___3libB .serviceItem___E0Ari .serviceTitle___1gTMk {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #1D1B2E;
}
.PagePricing___2c7lf .pricingCard___3TrWX .serviceItemList___3libB .serviceItem___E0Ari .servicePoint___2LHuR {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  text-align: left;
  color: #92929F;
}
.PagePricing___2c7lf .pricingCard___3TrWX .serviceItemList___3libB .serviceItem___E0Ari .servicePoint___2LHuR::before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px 6px;
  background-image: url('https://pos-static.chowbus.com/assets/checked_icon.png');
  margin-right: 8px;
}
.PagePricing___2c7lf .successCaseCard___3Ve_F {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;
  background: #FFFFFF;
  border-radius: 16px;
}
.PagePricing___2c7lf .successCaseCard___3Ve_F .successCaseTop___33k11 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PagePricing___2c7lf .successCaseCard___3Ve_F .successCaseTop___33k11 .avatorWrapper___3ENJy {
  width: 40%;
  max-width: 140px;
  border-radius: 100%;
  overflow: hidden;
}
.PagePricing___2c7lf .successCaseCard___3Ve_F .successCaseTop___33k11 .avatorWrapper___3ENJy .avator___2blRR {
  width: 100%;
  height: 100%;
}
.PagePricing___2c7lf .successCaseCard___3Ve_F .successCaseDesc___3hWeD {
  margin-top: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  color: #92929F;
}
.PagePricing___2c7lf .successCaseCard___3Ve_F .successCaseBottom___2PeLx {
  width: 100%;
  margin-top: 36px;
}
.PagePricing___2c7lf .successCaseCard___3Ve_F .successCaseBottom___2PeLx .successCaseRestauantName___3M56o {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #E00051;
}
.PagePricing___2c7lf .successCaseCard___3Ve_F .successCaseBottom___2PeLx .successCaseRestauanLocation___2k07S {
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #92929F;
}
