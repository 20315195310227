.btn-add-member {
  display: inline-block;
  width: 28px;
  height: 28px;
  background-image: url('https://pos-static.chowbus.com/assets/collapsed.png');
  background-size: 100%;
  border-radius: 5px;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 16px;
}
.PointsReport___3bwo9 .no-wrap {
  white-space: nowrap;
  min-width: 70px;
}
.PointsReport___3bwo9 .break-word {
  word-break: break-all;
}
.PointsReport___3bwo9 .metric-item .metric-name {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #1d1b2e;
  opacity: 0.5;
}
.PointsReport___3bwo9 .metric-item .metric-value {
  font-size: 36px;
  line-height: 51px;
  color: #1d1b2e;
}
.PointsReport___3bwo9 .metric-item .metric-value.emphasis {
  color: #e00051;
}
