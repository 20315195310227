.CustomizationBrief___LDRTL .restriction-group-label {
  display: flex;
  line-height: 22px;
  color: #8C8C8C;
  margin-bottom: 8px;
}
.CustomizationBrief___LDRTL .restriction-group-label .label {
  max-width: calc(100% - 18px);
}
.CustomizationBrief___LDRTL .restriction-form-item {
  display: flex;
  align-items: center;
}
.CustomizationBrief___LDRTL .restriction-form-item .restriction-field {
  flex: 1;
  min-width: 0;
}
.CustomizationBrief___LDRTL .restriction-label {
  width: 30px;
  color: #8C8C8C;
  margin-bottom: 16px;
}
.CustomizationBrief___LDRTL .restriction-field .ant-form-item-explain {
  display: none;
}
.CustomizationBrief___LDRTL .restriction-errors {
  margin: -16px 0 16px 0;
  color: #E00051;
}
.CustomizationBrief___LDRTL .ant-input-disabled,
.CustomizationBrief___LDRTL .ant-input-number-disabled,
.CustomizationBrief___LDRTL .ant-input-number-affix-wrapper-disabled {
  color: #000;
}
