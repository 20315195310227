.WaitlistSettings___PRAi5 .sortable-list-item {
  margin-bottom: 0;
}
.WaitlistSettings___PRAi5 .group .group-header {
  display: flex;
  justify-content: space-between;
}
.WaitlistSettings___PRAi5 .group .group-title {
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}
.WaitlistSettings___PRAi5 .group .group-content {
  margin-top: 22px;
}
.WaitlistSettings___PRAi5 .ant-card-body {
  padding: 24px 24px 10px 24px;
}
.WaitlistSettings___PRAi5 .setting-wrapper + .setting-wrapper {
  margin-top: 16px;
}
.WaitlistSettings___PRAi5 .section-name {
  font-size: 18px;
  color: #999;
  margin-bottom: 10px;
}
.WaitlistSettings___PRAi5 .ant-radio-wrapper {
  white-space: normal;
}
@media (min-width: 992px) {
  .WaitlistSettings___PRAi5 .switch-wrapper {
    text-align: right;
  }
  .WaitlistSettings___PRAi5 .switch-wrapper .ant-switch {
    transform: scale(1.4) translate(-13px, -3px);
    transform-origin: 0 0;
  }
}
.WaitlistSettings___PRAi5 .ant-switch {
  transform: scale(1.4) translate(0, -3px);
  transform-origin: 0 0;
}
.WaitlistSettings___PRAi5 .ant-switch-checked {
  background: #34c759;
}
.WaitlistSettings___PRAi5 .ant-checkbox-wrapper {
  margin-bottom: 10px;
}
.WaitlistSettings___PRAi5 .ant-checkbox-wrapper .ant-checkbox {
  transform: scale(1.4);
  transform-origin: 0 10px;
  margin-right: 4px;
}
.WaitlistSettings___PRAi5 .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  border-radius: 0;
}
.WaitlistSettings___PRAi5 .ant-table .ant-checkbox-wrapper .ant-checkbox {
  transform-origin: 0 0;
}
.WaitlistSettings___PRAi5 .ant-radio {
  transform: scale(1.2);
  transform-origin: 0 14px;
}
.WaitlistSettings___PRAi5 .help-icon {
  cursor: pointer;
  margin-left: 4px;
}
.WaitlistSettings___PRAi5 .settings-block .settings-title {
  margin-bottom: 10px;
}
.WaitlistSettings___PRAi5 .settings-block .settings-content {
  margin-left: 30px;
}
.WaitlistSettings___PRAi5 .settings-block .settings-section .settings-section-header {
  margin-bottom: 18px;
}
.WaitlistSettings___PRAi5 .settings-block .settings-section .settings-section-header .section-description {
  color: #92929f;
}
.WaitlistSettings___PRAi5 .settings-block .settings-section .settings-section-content {
  margin-left: 30px;
}
.WaitlistSettings___PRAi5 .settings-block .settings-section .ant-radio-group {
  display: block;
}
.WaitlistSettings___PRAi5 .settings-block .settings-section .secondary-title {
  margin-bottom: 16px;
}
.WaitlistSettings___PRAi5 .settings-block .settings-section .settings-options .option-item + .option-item {
  margin-top: 16px;
}
.WaitlistSettings___PRAi5 .settings-block .settings-section .settings-options .ant-radio-wrapper {
  display: flex;
}
.WaitlistSettings___PRAi5 .settings-block .settings-section .settings-options .ant-radio-wrapper .ant-radio ~ span {
  flex: 1;
  min-width: 0;
  margin-top: -3px;
}
.WaitlistSettings___PRAi5 .settings-block .settings-section + .settings-section {
  margin-top: 16px;
}
.WaitlistSettings___PRAi5 .settings-block + .settings-block {
  margin-top: 16px;
}
.WaitlistSettings___PRAi5 .icon-trash {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('https://pos-static.chowbus.com/assets/icon_trash.png');
  background-size: 100%;
  cursor: pointer;
}
.WaitlistSettings___PRAi5 .tableQueueOption___wDYQo .ant-form-item {
  margin-bottom: 10px;
}
.WaitlistSettings___PRAi5 .tableQueueOptionSaving___1KMnZ {
  background-color: red;
}
.WaitlistSettings___PRAi5 .tableQueueNotes___tU_Dn .ant-form-item {
  margin-bottom: 10px;
}
.WaitlistSettings___PRAi5 .tableQueueNotes___tU_Dn textarea {
  resize: none;
}
.WaitlistSettings___PRAi5 .optionCapacity___34CEw .ant-form-item-explain-connected + div {
  display: none;
}
.WaitlistSettings___PRAi5 .divider___1eQ6c {
  background-color: #fffcfc;
  border-width: 1px 0 0;
  margin: 5px 0;
}
.WaitlistSettings___PRAi5 .errorList___V-_KE .ant-form-item-explain {
  margin: 10px 0;
}
