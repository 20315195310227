.PromotionForm___1fvUX .primary-title {
  font-size: 20px;
}
.PromotionForm___1fvUX .secondary-title {
  margin-top: 4px;
  color: #1D1B2E;
}
.PromotionForm___1fvUX .menu-list {
  display: flex;
  flex-wrap: wrap;
}
.PromotionForm___1fvUX .menu-list .menu-item {
  padding: 12px 24px;
  margin: 0 20px 20px 0;
  cursor: pointer;
}
.PromotionForm___1fvUX .menu-list .menu-item.selected {
  color: #e00051;
  background: #ffedf3;
}
.PromotionForm___1fvUX .no-sources {
  color: #e00051;
}
.PromotionForm___1fvUX .error-help {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #e00051;
}
.PromotionForm___1fvUX .ant-collapse-borderless {
  background-color: #fff;
}
.PromotionForm___1fvUX .ant-collapse-borderless .ant-collapse-header {
  background: #f5f5f5;
  padding: 8px 16px;
}
.PromotionForm___1fvUX .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 16px 0;
}
.PromotionForm___1fvUX .ant-tabs,
.PromotionForm___1fvUX .ant-tabs > .ant-tabs-nav {
  width: 100%;
}
.PromotionForm___1fvUX .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 12px;
}
.PromotionForm___1fvUX .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  background: #ffedf3;
}
.PromotionForm___1fvUX .ant-tabs-tab-active .ant-checkbox-wrapper {
  color: #e00051;
}
.PromotionForm___1fvUX .ant-checkbox-wrapper.checked-via-menu .ant-checkbox-inner {
  background-color: #ce2c54;
  border-color: #ce2c54 !important;
}
.PromotionForm___1fvUX .ant-checkbox-wrapper.checked-via-menu .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  content: ' ';
}
.PromotionForm___1fvUX .item-picker-wrapper + .item-picker-wrapper {
  margin-top: 20px;
}
.PromotionForm___1fvUX .quantity-unlimited-toggle {
  position: absolute;
  right: 0;
  top: 6px;
  z-index: 1;
}
.PromotionForm___1fvUX .ant-form-item-label > label {
  color: #000;
  font-weight: 400;
}
.PromotionForm___1fvUX .amount-input .ant-input-number,
.PromotionForm___1fvUX .amount-input .ant-input-number-input {
  border-radius: 3px 0 0 3px;
}
.PromotionForm___1fvUX .amount-input .ant-form-item-explain-error {
  margin-right: -128px;
}
.PromotionForm___1fvUX .type-radio .ant-radio-button-wrapper:first-child {
  border-radius: 0;
}
.PromotionForm___1fvUX .ant-select-tree .ant-select-tree-treenode {
  padding: 0 0 4px 8px;
}
.PromotionForm___1fvUX .ant-select-tree .ant-select-tree-treenode .ant-select-tree-switcher {
  width: 0;
}
