.IndexFilter___WhmHS {
  position: relative;
}
.IndexFilter___WhmHS .filter-icon {
  display: inline-block;
  width: 22px;
  height: 20px;
  background-image: url('https://pos-static.chowbus.com/assets/filter.png');
  background-size: 100%;
}
.IndexFilter___WhmHS .ant-btn {
  padding: 7px 18px;
  line-height: 1;
  height: auto;
}
.IndexFilter___WhmHS .ant-btn.focused {
  background: #ffedf3;
}
.IndexFilter___WhmHS .dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  margin-top: 18px;
  width: 330px;
  padding: 16px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
.IndexFilter___WhmHS .dropdown .option-item + .option-item {
  margin-top: 16px;
}
.IndexFilter___WhmHS .dropdown .ant-checkbox {
  transform: scale(1.5);
}
