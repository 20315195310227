/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.main___ycd8F {
  margin: 50px auto 0;
}
@media (min-width: 768px) {
  .main___ycd8F {
    width: 520px;
  }
}
.main___ycd8F .title___3EDyi {
  margin: 20px 0 20px 0;
  font-size: 16px;
}
.main-content___2p66A {
  margin-top: 30px;
}
.link___2-k71 {
  margin-top: 24px;
  font-size: 16px;
  text-align: center;
}
