.SearchResultPanel___1enGm {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}
.SearchResultPanel___1enGm .search-topbar {
  padding: 12px 16px;
  border-bottom: 1px solid #D9D9D9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  align-items: center;
}
.SearchResultPanel___1enGm .search-topbar .result-summary {
  font-size: 20px;
  line-height: 24px;
  color: #000;
  margin-left: 12px;
}
.SearchResultPanel___1enGm .search-result-wrapper {
  flex: 1;
  min-height: 0;
  display: flex;
}
.SearchResultPanel___1enGm .empty-result {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SearchResultPanel___1enGm .empty-result .error-desc {
  margin-top: 24px;
  color: #000000;
  text-align: center;
  line-height: 20px;
}
