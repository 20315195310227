/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.account-settings___WULaT {
  padding-top: 20px;
}
.account-settings___WULaT th.ant-descriptions-item {
  text-align: left;
}
.account-settings___WULaT th.ant-descriptions-item .ant-descriptions-item-label {
  font-size: 18px;
}
.account-settings___WULaT td.ant-descriptions-item {
  padding: 0 10px 50px 0;
}
.account-settings___WULaT .secret-password {
  display: inline-block;
  vertical-align: middle;
}
.account-settings___WULaT .error-helper {
  color: #ce2c54;
}
.reset-password-modal___xdRQI .ant-form-item-label {
  line-height: 40px;
  width: 190px;
  text-align: right;
}
