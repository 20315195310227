.contentfulRichText___2__sG *:not(a) {
  color: rgba(0, 0, 0, 0.85) !important;
}
.contentfulRichText___2__sG a {
  color: #ce2c54;
}
.contentfulRichText___2__sG a u {
  color: #ce2c54 !important;
}
.contentfulRichText___2__sG a i {
  color: #ce2c54 !important;
}
.contentfulRichText___2__sG p {
  margin: 0 0 1.5em 0 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
}
.contentfulRichText___2__sG .hrWrapper___T4xBR {
  margin: 0px 0px 1.5em;
}
.contentfulRichText___2__sG .hrWrapper___T4xBR .hrbreak___3tAI8 {
  margin: 0px;
  height: 1em;
  background: transparent;
  position: relative;
  border: 0px;
  user-select: none;
}
.contentfulRichText___2__sG .hrWrapper___T4xBR .hrbreak___3tAI8::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #cfd9e0;
  top: 50%;
}
.contentfulRichText___2__sG .chowbusBlockquote___5vDkF blockquote {
  margin-top: 24px;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
}
.contentfulRichText___2__sG .chowbusBlockquote___5vDkF blockquote::before {
  content: open-quote;
  position: absolute;
  display: inline-block;
  left: 0;
  top: 0;
  font-size: 3.875rem;
  line-height: 1;
  color: #E00051;
}
.contentfulRichText___2__sG .chowbusBlockquote___5vDkF blockquote::after {
  content: close-quote;
  color: transparent;
  font-size: 0;
}
.contentfulRichText___2__sG .chowbusBlockquote___5vDkF blockquote p {
  color: #E00051 !important;
  margin-bottom: 0 !important;
  padding-bottom: 20px;
}
.contentfulRichText___2__sG .chowbusBlockquote___5vDkF blockquote p:last-child {
  padding-bottom: 0;
}
