.PageLearn___2y4va .learn-section {
  padding: 0 64px;
}
.PageLearn___2y4va .filter-zone {
  margin-bottom: 36px;
}
.PageLearn___2y4va .filter-zone .filter-field-name {
  font-size: 18px;
  line-height: 24px;
  color: #1d1b2e;
  margin-bottom: 12px;
}
.PageLearn___2y4va .result-zone {
  margin-bottom: -36px;
}
.PageLearn___2y4va .result-zone .material-item {
  margin-bottom: 36px;
}
.PageLearn___2y4va .result-zone .material-item .poster-wrapper {
  position: relative;
  height: 0;
  padding-top: 64.79%;
}
.PageLearn___2y4va .result-zone .material-item .poster-wrapper .poster {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.PageLearn___2y4va .result-zone .material-item .poster-wrapper .poster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
.PageLearn___2y4va .result-zone .material-item .poster-wrapper .poster .placeholder {
  width: 100%;
  height: 100%;
  background: #d9d9d9;
  border-radius: 12px;
}
.PageLearn___2y4va .result-zone .material-item .title {
  font-size: 18px;
  margin: 16px 0;
  line-height: 24px;
}
.PageLearn___2y4va .result-zone .material-item .tag {
  display: inline-block;
  padding: 6px 10px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 22px;
  color: #ffffff;
}
.PageLearn___2y4va .result-zone .show-more {
  margin-top: 60px;
  text-align: center;
}
.PageLearn___2y4va .result-zone .show-more .show-more-btn {
  font-size: 24px;
  line-height: 24px;
}
