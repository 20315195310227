.switchLanguageContainer___1D1iV {
  width: 100px;
  height: 36px;
  background-color: #EBEBEB;
  border-radius: 24px;
  padding: 2px;
  font-family: 'Proxima Nova', 'PingFang SC';
  position: relative;
}
.switchLanguageContainer___1D1iV .options___2aM-k {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: relative;
}
.switchLanguageContainer___1D1iV .option___YSpP6 {
  width: 49px;
  height: 32px;
  background-color: transparent;
  border-radius: 20px;
  color: #8C8C8C;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all;
  transition-duration: 1000ms;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.switchLanguageContainer___1D1iV .option_active_lay___2gQMs {
  position: absolute;
  background-color: #ffffff;
  width: 49px;
  height: 32px;
  border-radius: 20px;
  right: 2px;
  top: 2px;
  transition-property: all;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: translateX(calc(-100% + 2px));
}
.switchLanguageContainer___1D1iV.optionEnActived___1vONW .optionEn___3D_du {
  color: #000000;
}
.switchLanguageContainer___1D1iV.optionEnActived___1vONW .optionZh___1Ri-N {
  color: #8C8C8C;
}
.switchLanguageContainer___1D1iV.optionEnActived___1vONW .option_active_lay___2gQMs {
  transform: translateX(0);
}
.switchLanguageContainer___1D1iV.optionZhActived___3cWQ8 .optionEn___3D_du {
  color: #8C8C8C;
}
.switchLanguageContainer___1D1iV.optionZhActived___3cWQ8 .optionZh___1Ri-N {
  color: #000000;
}
.switchLanguageContainer___1D1iV.optionZhActived___3cWQ8 .option_active_lay___2gQMs {
  transform: translateX(calc(-100% + 2px));
}
