.TimeLog___1w0iB .error-help {
  font-style: italic;
  color: #ce2c54;
}
.TimeLog___1w0iB .icon-warn {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  background-image: url('https://pos-static.chowbus.com/assets/icon_warn.png');
  background-size: 100%;
  vertical-align: -2px;
}
.TimeLog___1w0iB .icon-error {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  background-image: url('https://pos-static.chowbus.com/assets/icon_warn_red.png');
  background-size: 100%;
  vertical-align: -2px;
}
.FormattedClockOutClassName {
  max-width: 360px;
}
.FormattedClockOutClassName .FormattedClockOutTooltipContent .icon-warn {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 0;
  background-image: url('https://pos-static.chowbus.com/assets/icon_warn.png');
  background-size: 100%;
  vertical-align: -2px;
}
.FormattedClockOutClassName .FormattedClockOutTooltipContent .icon-error {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 0;
  background-image: url('https://pos-static.chowbus.com/assets/icon_warn_red.png');
  background-size: 100%;
  vertical-align: -2px;
}
