.ProfileCard___rjkj1 .ant-form-item {
  margin-bottom: 0;
}
.ProfileCard___rjkj1 .ant-form-item.fix-height {
  padding: 6px 0;
  height: 46px;
  line-height: 34px;
}
.ProfileCard___rjkj1 .ant-form-item.fix-height .ant-input {
  text-align: right;
}
.ProfileCard___rjkj1 .ant-form-item.fix-height .ant-form-item-control {
  flex-direction: row-reverse;
  justify-content: end;
}
.ProfileCard___rjkj1 .ant-form-item.fix-height .ant-form-item-control .ant-form-item-explain {
  margin-right: 8px;
  line-height: 33px;
}
.ProfileCard___rjkj1 .form-item-wrapper + .form-item-wrapper {
  border-top: 1px solid #dddddd;
}
.ProfileCard___rjkj1 .ant-card-head {
  border-bottom: none;
}
.ProfileCard___rjkj1 .ant-card-body {
  padding: 0 24px 18px;
}
.ProfileCard___rjkj1 .ant-card-body .ant-form-item-label > label {
  color: #636371;
}
.ProfileCard___rjkj1 .member-actions {
  margin-top: 16px;
}
.ProfileCard___rjkj1 .actions {
  text-align: right;
}
.ProfileCard___rjkj1 + .ProfileCard___rjkj1 {
  margin-top: 16px;
}
