.column-item___1k_hi {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px;
  background: #fff;
  margin-bottom: 10px;
}
.column-item___1k_hi .absolute-tooltip {
  position: absolute;
  right: 12px;
  top: 12px;
}
