.NewFormContent___dv61W {
  height: 100%;
}
.NewFormContent___dv61W .ant-card-body {
  height: 100%;
}
.NewFormContent___dv61W .ant-card-body .setting-section {
  position: relative;
  height: 100%;
}
.NewFormContent___dv61W .ant-card-body .setting-section .section-title .section-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.NewFormContent___dv61W .ant-card-body .setting-section .section-title .section-title-wrapper > div:first-child .anticon {
  margin-right: 4px;
  font-size: 16px;
  cursor: pointer;
}
.NewFormContent___dv61W .ant-card-body .setting-section .section-title .section-title-wrapper > div:last-child {
  color: #e00051;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}
.NewFormContent___dv61W .ant-card-body .setting-section .section-title .section-title-wrapper > div:last-child.disabled {
  opacity: 0.25;
  cursor: not-allowed;
}
.NewFormContent___dv61W .ant-card-body .setting-section .section-title .section-title-wrapper > div:last-child .anticon {
  margin-left: 4px;
  font-size: 16px;
}
.NewFormContent___dv61W .ant-card-body .setting-section .section-content {
  position: absolute;
  top: 56px;
  left: 0;
  right: -16px;
  bottom: 0;
  padding-right: 16px;
  overflow-y: auto;
}
.NewFormContent___dv61W .ant-card-body .setting-section .section-content .section-content-title {
  padding: 6px 0;
  line-height: 20px !important;
}
