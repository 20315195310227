.CustomService___1x4w2 {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.CustomService___1x4w2 .refresh-link {
  margin-top: 12px;
  font-size: 16px;
}
