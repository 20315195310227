.salesSummary___M5mb1 {
  margin-block-end: 20px;
  width: 100%;
  overflow: hidden;
}
.salesSummary___M5mb1 .sales_summary_filter {
  margin-block: 20px;
}
.salesSummary___M5mb1 .sales-summary-content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
.salesSummary___M5mb1 .sales-summary-content .col {
  width: 100%;
  overflow: auto;
}
.salesSummary___M5mb1 .sales-summary-content.two-col {
  grid-template-columns: repeat(2, calc(50% - 8px));
  gap: 16px;
}
.salesSummary___M5mb1 .ant-tree-select {
  z-index: 3!important;
}
.summaryCard___1hb_0 {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-block-end: 15px;
}
.summaryTable___lvYyN .summaryTable__title {
  height: 44px;
  padding-block: 12px;
  padding-inline: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #d9d9d9;
  background: linear-gradient(#fff, #f5f5f5);
  border-radius: 4px 4px 0 0;
}
.summaryTable___lvYyN .summaryTable__title span {
  display: inline-block;
  height: 20px;
  padding-block: 2px;
  padding-inline-start: 18px;
  line-height: 16px;
  border-left: 2px solid #E00051;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.summaryTable___lvYyN .summaryTable__content {
  padding-inline: 16px;
  padding-block: 16px;
}
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-content {
  border: 0px;
}
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-tbody > tr > td {
  border-bottom: 0px;
}
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0px;
  height: 0px;
}
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-thead > tr > th {
  background: #fff;
}
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small:not(.ant-table-scroll-horizontal) .ant-table-tbody tr:nth-of-type(even) {
  background-color: transparent;
}
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-tbody tr:nth-of-type(odd) td.ant-table-column-sort,
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-tbody tr:nth-of-type(odd) td.ant-table-cell-fix-left {
  background-color: #fff;
}
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-tbody tr.ant-table-row.row-even-bg {
  background-color: #fafafa;
}
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-tbody tr.ant-table-row.row-even-bg .ant-table-cell-fix-left,
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-tbody tr.ant-table-row.row-even-bg .ant-table-cell-fix-right {
  background-color: #fafafa;
}
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-tbody tr.ant-table-row.row-even-bg:hover .ant-table-cell-fix-left,
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-tbody tr.ant-table-row.row-even-bg:hover .ant-table-cell-fix-right {
  background-color: #f5f5f5;
}
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-tbody tr.ant-table-row:hover td {
  background-color: #f5f5f5;
}
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-tbody tr.ant-table-row:hover .ant-table-cell-fix-left,
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-tbody tr.ant-table-row:hover .ant-table-cell-fix-right {
  background-color: #f5f5f5;
}
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-thead > tr > th {
  padding-block: 6px;
  padding-inline: 18px;
  font-size: 14px;
  color: #8c8c8c;
  font-weight: normal;
}
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding: 6px 18px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-tbody > tr > td span.redEmphasis {
  color: #e00051;
}
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-tbody > tr.indents td:first-child {
  padding-block: 6px;
  padding-inline: 33px 18px;
}
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-tbody > tr.bold td {
  font-weight: 500;
}
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small.ant-table-scroll-horizontal .ant-table-tbody tr {
  background-color: #fff;
}
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-placeholder .ant-table-cell {
  padding: 6px 0px;
  overflow: hidden;
}
.summaryTable___lvYyN .summaryTable__content .ant-table.ant-table-small .ant-table-placeholder .ant-table-cell .ant-table-expanded-row-fixed {
  margin: -16px;
}
.skeletonLoading___3j3EC {
  width: 100%;
}
.skeletonLoading___3j3EC .summaryTable__title {
  height: 44px;
  padding-block: 12px;
  padding-inline: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #d9d9d9;
  background: linear-gradient(#fff, #f5f5f5);
  border-radius: 4px 4px 0 0;
}
.skeletonLoading___3j3EC .summaryTable__title span {
  display: inline-block;
  height: 20px;
  padding-block: 2px;
  padding-inline-start: 18px;
  line-height: 16px;
  border-left: 2px solid #E00051;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.skeletonLoading___3j3EC .summaryTable__content {
  padding-block: 16px;
  padding-inline: 18px;
}
.skeletonLoading___3j3EC .summaryTable__content .skeleton_row {
  height: 34px;
  line-height: 34px;
}
.skeletonLoading___3j3EC .summaryTable__content .ant-skeleton-element .ant-skeleton-input-sm {
  margin-block-start: 6px;
}
.skeletonLoading___3j3EC .summaryTable__content .ant-row .ant-col:first-child .ant-skeleton {
  width: 90%;
}
.skeletonLoading___3j3EC .summaryTable__content .ant-row .ant-col:last-child .ant-skeleton {
  width: 90%;
}
