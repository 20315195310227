/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.CustomerVoice___2RnpU {
  width: 100%;
}
.CustomerVoice___2RnpU:hover {
  cursor: pointer;
}
.CustomerVoice___2RnpU.lightMode___2ZyMP .customer-voice-content {
  color: #000000 !important;
}
.CustomerVoice___2RnpU.lightMode___2ZyMP .customer-voice-name-address span {
  color: #000000 !important;
}
.CustomerVoice___2RnpU .customer-voice-card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
}
@media (max-width: 1024px) {
  .CustomerVoice___2RnpU .customer-voice-card {
    flex-direction: column;
    margin-bottom: 36px;
  }
}
.CustomerVoice___2RnpU .customer-voice-card img {
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
}
.CustomerVoice___2RnpU .customer-voice-card .customer-voice-content {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
}
@media (max-width: 575px) {
  .CustomerVoice___2RnpU .customer-voice-card .customer-voice-content {
    font-size: 18px;
    line-height: 28px;
  }
}
.CustomerVoice___2RnpU .customer-voice-card .customer-voice-name-address {
  margin-top: 48px;
}
@media (max-width: 1024px) {
  .CustomerVoice___2RnpU .customer-voice-card .customer-voice-name-address {
    margin-top: 24px;
  }
}
.CustomerVoice___2RnpU .customer-voice-card .customer-voice-name-address span {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #FFFFFF;
}
.CustomerVoice___2RnpU .customer-voice-card .customer-voice-left-section {
  border-radius: 16px;
  overflow: hidden;
  flex: 1;
}
@media (max-width: 1024px) {
  .CustomerVoice___2RnpU .customer-voice-card .customer-voice-left-section {
    width: 100%;
    order: 1 !important;
  }
}
.CustomerVoice___2RnpU .customer-voice-card .customer-voice-card-gap {
  width: 32px;
  order: 2;
}
@media (max-width: 1024px) {
  .CustomerVoice___2RnpU .customer-voice-card .customer-voice-card-gap {
    height: 12px;
    order: 2;
  }
}
.CustomerVoice___2RnpU .customer-voice-card .customer-voice-right-section {
  flex: 1;
}
@media (max-width: 1024px) {
  .CustomerVoice___2RnpU .customer-voice-card .customer-voice-right-section {
    width: 100%;
    order: 3 !important;
  }
}
