.FeeModal___15_Q6 .description {
  margin-bottom: 12px;
  color: #92929F;
}
.FeeModal___15_Q6 .desc {
  line-height: 34px;
}
.FeeModal___15_Q6 .field {
  margin-bottom: 8px;
}
.FeeModal___15_Q6 .field.combo .ant-form-item-control-input {
  margin-top: -8px;
}
.FeeModal___15_Q6 .rule-card {
  position: relative;
}
.FeeModal___15_Q6 .rule-card .delete-icon {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.FeeModal___15_Q6 .help-block {
  display: inline-block;
  margin-left: 8px;
  line-height: 34px;
  color: #e00051;
}
