.DiscountTooltipContent___nw3Ac {
  padding: 30px;
}
.DiscountTooltipContent___nw3Ac .summary-item {
  display: flex;
  align-items: center;
  font-weight: 400;
}
.DiscountTooltipContent___nw3Ac .summary-item .item-title {
  flex: 1;
  min-width: 0;
}
.DiscountTooltipContent___nw3Ac .summary-item .item-value {
  width: 90px;
  text-align: right;
}
.DiscountTooltipContent___nw3Ac .summary-item .item-extra {
  margin: 0 8px;
  width: 30px;
  text-align: right;
}
