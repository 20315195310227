.OnlineOrderingSelfDelivery___36mKD .form-item-label {
  margin-bottom: 12px;
}
.OnlineOrderingSelfDelivery___36mKD .ant-form-item .ant-form-item-control-input {
  display: inline-block;
  vertical-align: top;
}
.OnlineOrderingSelfDelivery___36mKD .ant-form-item .ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: rgba(0, 0, 0, 0.85);
}
.OnlineOrderingSelfDelivery___36mKD .ant-form-item .ant-form-item-explain,
.OnlineOrderingSelfDelivery___36mKD .ant-form-item .ant-form-item-extra {
  position: absolute;
  left: 100%;
  top: 50%;
  white-space: nowrap;
}
