/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.featuredArticle___vwQW8 {
  width: 100%;
}
.featuredArticle___vwQW8 .common-section-section {
  padding-top: 10px;
  padding-bottom: 10px;
}
.featuredArticle___vwQW8 .featuredImage___R8k4o {
  height: auto;
  min-height: 300px;
  width: 100%;
  object-fit: cover;
}
.featuredArticle___vwQW8 .featuredArticlePreviewText___1r9bA {
  padding: 0 18px;
  margin-bottom: 1em;
}
.featuredArticle___vwQW8 .featuredArticleTitle___2qCmG {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}
.featuredArticle___vwQW8 .featuredArticleTitle___2qCmG span {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  padding-left: 15px;
  line-height: 1.2;
  margin-bottom: 20px;
}
.featuredArticle___vwQW8 .featuredArticleTitle___2qCmG::before {
  content: '';
  position: absolute;
  top: 5px;
  border-left: 8px solid #e00051;
  height: 28px;
  background-color: #e00051;
  flex-shrink: 0;
}
.featuredArticle___vwQW8 .featuredArticlePreviewBottom___3mzdI {
  width: 100%;
  padding: 0 18px;
}
.featuredArticle___vwQW8 .featuredArticleReadMore___1rENy {
  height: 44px;
  font-size: 16px;
  border-radius: 22px;
  background: #e00051;
  padding: 5px 24px;
}
@media (max-width: 575px) {
  .featuredArticle___vwQW8 .featuredArticleReadMore___1rENy {
    margin-top: 20px;
    flex: 1;
    border-radius: 22px;
    font-size: 18px;
    line-height: normal;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 100%;
  }
}
