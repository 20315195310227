.TipSheetDetailDrawer___11xpr .ant-card .ant-card-head {
  min-height: 32px;
  padding: 0 16px;
}
.TipSheetDetailDrawer___11xpr .ant-card .ant-card-head .ant-card-head-title {
  padding: 8px 0;
}
.TipSheetDetailDrawer___11xpr .ant-card .ant-card-body {
  padding: 16px;
}
.TipSheetDetailDrawer___11xpr .ant-card .ant-card-body .ant-row + .ant-row {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid #e5e5e5;
}
.TipSheetDetailDrawer___11xpr .ant-card.activity-card .ant-card-body {
  padding: 0 16px;
}
.TipSheetDetailDrawer___11xpr .ant-card.activity-card .activity-name {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 1;
  font-weight: normal;
}
.TipSheetDetailDrawer___11xpr .ant-card.activity-card .shooter {
  font-size: 12px;
  line-height: 1;
}
.TipSheetDetailDrawer___11xpr .payment-badge {
  display: inline-block;
  margin-right: 4px;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 14px;
}
.TipSheetDetailDrawer___11xpr .payment-badge.paid {
  background: #EFFAF9;
  color: #15D0C5;
}
.TipSheetDetailDrawer___11xpr .payment-badge.unpaid {
  color: #ce2c54;
}
.TipSheetDetailDrawer___11xpr .source-group .group-name {
  color: #9b9b9b;
  line-height: 24px;
  margin-bottom: 8px;
}
.TipSheetDetailDrawer___11xpr .source-group .group-items {
  padding-left: 2em;
}
.TipSheetDetailDrawer___11xpr .source-group .group-items .group-item + .group-item {
  margin-top: 8px;
}
.TipSheetDetailDrawer___11xpr .source-group + .source-group {
  margin-top: 16px;
}
.TipSheetDetailDrawer___11xpr .employee-group .role-name {
  color: #9b9b9b;
  line-height: 24px;
  margin: 16px 0 8px;
}
.TipSheetDetailDrawer___11xpr .employee-group .employees {
  padding-left: 2em;
}
.TipSheetDetailDrawer___11xpr .employee-group .employees .employee-item + .employee-item {
  margin-top: 8px;
}
.TipSheetDetailDrawer___11xpr .ant-card + .ant-card {
  margin-top: 20px;
}
