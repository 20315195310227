.permission-setting___3XKbc .ant-card {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 160px);
}
.permission-setting___3XKbc .ant-card-body {
  flex: 1;
  overflow-y: auto;
  margin-top: 1px;
  min-height: 0;
}
.permission-setting___3XKbc .ant-tree-title {
  display: inline-block;
  padding: 5px;
}
.permission-setting___3XKbc .ant-tree-switcher {
  padding: 5px;
}
