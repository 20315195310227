.CustomizationList___3sYeE {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}
.CustomizationList___3sYeE .customization-top-bar {
  padding: 12px 16px 12px 22px;
  border-bottom: 1px solid #D9D9D9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.CustomizationList___3sYeE .custoization-panel-wrapper {
  flex: 1;
  min-height: 0;
  display: flex;
}
