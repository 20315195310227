.TimeSheetForm___1mf6s .clock-out-wrapper {
  position: relative;
}
.TimeSheetForm___1mf6s .clock-out-wrapper .action {
  position: absolute;
  right: 0;
  top: 2px;
}
.TimeSheetForm___1mf6s .clock-out-wrapper .icon-warn {
  position: absolute;
  top: 38px;
  left: 175px;
  display: block;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  background-image: url('https://pos-static.chowbus.com/assets/icon_warn.png');
  background-size: 100%;
}
.TimeSheetForm___1mf6s .clock-out-wrapper .icon-error {
  position: absolute;
  top: 38px;
  left: 175px;
  display: block;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  background-image: url('https://pos-static.chowbus.com/assets/icon_warn_red.png');
  background-size: 100%;
}
