.Categories___dboKq .edit-copy {
  color: #636371;
  margin-bottom: 16px;
  font-size: 18px;
}
.CategoriesAction___10lr6 {
  color: #e00051;
  font-size: 16px;
  margin-left: 10px;
}
