.restaurantList___2NIcV {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.restaurantList___2NIcV .ant-menu-submenu .ant-menu .ant-menu-item {
  height: auto;
  white-space: normal;
  line-height: 1.4;
  padding: 8px;
}
