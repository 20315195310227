.SuccessModal___1SIgd .ant-modal-content .ant-modal-close .ant-modal-close-x {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 56px;
}
.SuccessModal___1SIgd .ant-modal-content .ant-modal-header {
  padding: 16px;
}
.SuccessModal___1SIgd .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #1d1b2e;
  font-family: 'Proxima Nova';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.SuccessModal___1SIgd .ant-modal-content .ant-modal-body {
  padding: 16px;
}
.SuccessModal___1SIgd .ant-modal-content .ant-modal-body .description-wrapper {
  margin-bottom: 8px;
  color: #1d1b2e;
  font-size: 14px;
  line-height: 20px;
}
.SuccessModal___1SIgd .ant-modal-content .ant-modal-body .image-wrapper {
  position: relative;
  width: 608px;
  padding: 12px;
  background-color: #ffffff;
  border: 12px solid #ebebeb;
  border-radius: 4px;
}
.SuccessModal___1SIgd .ant-modal-content .ant-modal-body .image-wrapper > div {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 8px;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 4px;
}
.SuccessModal___1SIgd .ant-modal-content .ant-modal-body .image-wrapper > img {
  width: 560px;
}
