.Holidays___3TvSs {
  position: relative;
  padding-bottom: 16px;
}
.Holidays___3TvSs .error-tip-helper {
  position: absolute;
  right: 0;
  color: #E00051;
  height: 53px;
  display: flex;
  align-items: center;
}
