.PageDepartment___2pKG0 .department-card .ant-card-body {
  position: relative;
  height: 131px;
  padding: 18px 22px;
}
.PageDepartment___2pKG0 .department-card .ant-card-body .delete-icon {
  position: absolute;
  bottom: 10px;
  right: 6px;
  cursor: pointer;
}
.PageDepartment___2pKG0 .unassigned-title {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 24px;
  letter-spacing: -0.3px;
  color: #1d1b2e;
}
