.CampaignFilter___3Rtfc {
  margin-bottom: 24px;
}
.CampaignFilter___3Rtfc .filter-item-wrap {
  position: relative;
}
.CampaignFilter___3Rtfc .filter-item-wrap .form-item-title {
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 8px;
}
.CampaignFilter___3Rtfc .filter-item-wrap .clear-action {
  position: absolute;
  top: 0;
  right: 0;
}
.CampaignFilter___3Rtfc .filter-button {
  width: 87px;
  padding: 10px 0;
  margin-top: 29px;
  line-height: 20px;
}
.CampaignFilter___3Rtfc .filter-button:hover,
.CampaignFilter___3Rtfc .filter-button:active {
  color: rgba(0, 0, 0, 0.85);
}
