.ChartLegend___2qSIs {
  line-height: 1;
}
.ChartLegend___2qSIs .legend-item {
  display: flex;
}
.ChartLegend___2qSIs .legend-item .legend-title {
  flex: 1;
  min-width: 0;
  margin-right: 8px;
}
.ChartLegend___2qSIs .legend-item .legend-title .legend-dot {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
  vertical-align: middle;
}
.ChartLegend___2qSIs .legend-item .legend-title .legend-name {
  color: rgba(29, 27, 46, 0.5);
}
.ChartLegend___2qSIs .legend-item .legend-value {
  color: #1d1b2e;
}
.ChartLegend___2qSIs .legend-item .legend-value .divider {
  display: inline-block;
  width: 1px;
  height: 16px;
  background: #1d1b2e;
  margin: 0 4px 0 8px;
  vertical-align: middle;
}
.ChartLegend___2qSIs .legend-item .legend-value .percent {
  display: inline-block;
  width: 40px;
  text-align: right;
}
.ChartLegend___2qSIs .legend-item + .legend-item {
  margin-top: 10px;
}
