.Promotions___2YhSb .section + .section {
  margin-top: 20px;
}
.Promotions___2YhSb .section-title {
  font-size: 18px;
  margin-bottom: 8px;
}
.Promotions___2YhSb .promotion-card {
  padding: 10px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #c0c2cc;
  cursor: pointer;
}
.Promotions___2YhSb .promotion-card:hover,
.Promotions___2YhSb .promotion-card:active {
  border-color: #ce2c54;
}
.Promotions___2YhSb .promotion-card .promotion-type {
  font-size: 18px;
}
.Promotions___2YhSb .promotion-card .promotion-sample {
  font-size: 14px;
  color: #636371;
  font-style: italic;
}
.Promotions___2YhSb .status-indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
  vertical-align: middle;
}
.Promotions___2YhSb .status-indicator.ongoing {
  background-color: #15D0C5;
}
