/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.CommonSectionLayout___2XFpv {
  position: relative;
}
.CommonSectionLayout___2XFpv .common-section-section {
  padding-top: 50px;
  margin-top: 0;
  margin-bottom: 0 !important;
  padding-bottom: 50px;
  position: relative;
}
@media (max-width: 575px) {
  .CommonSectionLayout___2XFpv .common-section-section {
    padding-top: 40px !important;
    padding-bottom: 30px;
  }
}
.CommonSectionLayout___2XFpv .common-section-section .section-content {
  padding-left: 64px;
  padding-right: 64px;
  position: relative;
  margin: 0 auto;
}
.CommonSectionLayout___2XFpv .common-section-section .section-content .header {
  margin: 0 auto;
  max-width: 80%;
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .CommonSectionLayout___2XFpv .common-section-section .section-content .header {
    max-width: 100%;
    margin: unset;
  }
}
.CommonSectionLayout___2XFpv .common-section-section .section-content .header .section-title {
  margin-bottom: 5px;
}
.CommonSectionLayout___2XFpv .common-section-section .section-content .section-title {
  margin-bottom: 30px;
  text-align: center;
}
.chowbusoneComposedTags___1MjUm .chowbusoneComposedTagsHotAreas___24rVr {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}
.chowbusoneComposedTags___1MjUm .chowbusoneComposedTagsHotAreas___24rVr .chowbusoneTags___3yCKe {
  position: absolute;
  cursor: pointer;
  background-color: #ffffff;
  color: #1D1B2E;
  border-radius: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  line-height: normal;
  text-align: center;
  overflow: hidden;
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 100ms;
}
.chowbusoneComposedTags___1MjUm .chowbusoneComposedTagsHotAreas___24rVr .chowbusoneTags___3yCKe .plusicon___1LCm2 {
  color: #E00051;
  padding-right: 5px;
}
@media (max-width: 1060px) {
  .chowbusoneComposedTags___1MjUm .chowbusoneComposedTagsHotAreas___24rVr .chowbusoneTags___3yCKe {
    font-size: 16px;
    line-height: 22px;
  }
}
.chowbusoneComposedTags___1MjUm .chowbusoneComposedTagsHotAreas___24rVr .chowbusoneTags___3yCKe:hover {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  transform: translateY(-10%);
}
.chowbusoneComposedTags___1MjUm .chowbusone-composed-tags-image {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slick-dots.slick-dots-bottom {
  bottom: -15px;
}
.slick-dots button {
  background-color: gray !important;
}
.slick-dots .slick-active button {
  background-color: black !important;
}
.ourClientsList___11Sxc a.client-logo-link:hover .client-logo-image {
  filter: grayscale(0);
  opacity: 1;
}
.ourClientsList___11Sxc .client-logo-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ourClientsList___11Sxc .client-logo-container .client-logo-image {
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 100ms;
  cursor: pointer;
  width: 50%;
  object-fit: contain;
  filter: grayscale(1);
  opacity: 0.6;
}
.ourClientsList___11Sxc .client-logo-container .client-logo-image.showAsOriginal {
  filter: grayscale(0);
  opacity: 1;
}
