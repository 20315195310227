.btn-add-member {
  display: inline-block;
  width: 28px;
  height: 28px;
  background-image: url('https://pos-static.chowbus.com/assets/collapsed.png');
  background-size: 100%;
  border-radius: 5px;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 16px;
}
.Member___2YC9X .no-wrap {
  white-space: nowrap;
  min-width: 70px;
}
.Member___2YC9X .break-word {
  word-break: break-all;
}
