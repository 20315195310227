.StackChart___1qMk_ .ant-checkbox-inner {
  border-radius: 0;
}
.StackChart___1qMk_ .ant-checkbox-checked:after {
  border: none;
}
.StackChart___1qMk_ .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 40px;
}
.StackChart___1qMk_ .ant-checkbox-inner:after {
  border-color: #000000;
  width: 9px;
  height: 13px;
  left: 10%;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.new .ant-checkbox-inner {
  background: #caf9f4;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.regular .ant-checkbox-inner {
  background: #15d0c5;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.inactive .ant-checkbox-inner {
  background: #d2dcdb;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.atRisk .ant-checkbox-inner {
  background: pink;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.at_risk .ant-checkbox-inner {
  background: pink;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.low_frequency .ant-checkbox-inner {
  background: #e00051;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.lost .ant-checkbox-inner {
  background: #d2dcdb;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.redeemed .ant-checkbox-inner {
  background: #e00051;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.unredeemed .ant-checkbox-inner,
.StackChart___1qMk_ .ant-checkbox-wrapper.issued .ant-checkbox-inner {
  background: #ffb4cf;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.in_store .ant-checkbox-inner {
  background: #15d0c5;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.qr_code .ant-checkbox-inner {
  background: #fdc229;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.kiosk .ant-checkbox-inner {
  background: #e00051;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.new_visits .ant-checkbox-inner {
  background: #fdc229;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.return_visits .ant-checkbox-inner {
  background: #ff9d42;
}
.StackChart___1qMk_ .chart-container {
  padding-top: 30px;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.FirstPurchase .ant-checkbox-inner {
  background: #FFB4CF;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.Birthday .ant-checkbox-inner {
  background: #17ba63;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.FirstPurchase .ant-checkbox-inner {
  background: #15D0C5;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.Re-Purchase .ant-checkbox-inner {
  background: #FFB4CF;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.At-risk .ant-checkbox-inner {
  background: #D2DCDB;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.Lost .ant-checkbox-inner {
  background: #E00051;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.NewRegister .ant-checkbox-inner {
  background: #CAF9F4;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.NewRegisterFree .ant-checkbox-inner {
  background: #CAF9F4;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.Firstreminder .ant-checkbox-inner {
  background: #FDC229;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.FirstreminderFree .ant-checkbox-inner {
  background: #FDC229;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.recent .ant-checkbox-inner {
  background: #FDC229;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.SMS .ant-checkbox-inner {
  background: #8bc34a;
}
.StackChart___1qMk_ .ant-checkbox-wrapper.MMS .ant-checkbox-inner {
  background: #ff5722;
}
