.ActivateModal___37dH1 .ant-form-item.fix-height {
  margin-bottom: 0;
  line-height: 34px;
}
.ActivateModal___37dH1 .ant-form-item.fix-height .ant-form-item-control {
  flex-direction: row-reverse;
  justify-content: end;
}
.ActivateModal___37dH1 .ant-form-item.fix-height .ant-form-item-control .ant-form-item-explain {
  margin-right: 8px;
  line-height: 33px;
}
