.BatchOperationsModal___-kFUC .ant-modal-close-x {
  margin-top: 6px;
  font-size: 24px;
}
.BatchOperationsModal___-kFUC .ant-modal-body {
  padding: 0 16px 24px;
}
.BatchOperationsModal___-kFUC .modal-header {
  padding: 16px 0;
  border-bottom: 1px solid #D9D9D9;
}
.BatchOperationsModal___-kFUC .modal-header .modal-title {
  font-size: 24px;
  line-height: 30px;
  color: #000;
}
.BatchOperationsModal___-kFUC .modal-body {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
}
.BatchOperationsModal___-kFUC .modal-body .selection-zone .zone-title {
  font-size: 16px;
  line-height: 20px;
  color: #000;
  margin-bottom: 8px;
}
.BatchOperationsModal___-kFUC .modal-body .selection-zone .zone-content {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 8px;
}
.BatchOperationsModal___-kFUC .modal-body .selection-zone + .selection-zone {
  margin-top: 16px;
}
.BatchOperationsModal___-kFUC .modal-body .selector-wrapper {
  height: 450px;
  margin-top: 16px;
}
.BatchOperationsModal___-kFUC .modal-body .selection-item {
  padding: 6px 12px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  background: #F5F5F5;
  display: flex;
  align-items: center;
}
.BatchOperationsModal___-kFUC .modal-body .selection-item .selection-label {
  line-height: 22px;
  margin-right: 8px;
}
.BatchOperationsModal___-kFUC .modal-footer {
  display: flex;
  justify-content: end;
}
