.CustomizationDetailPanel___820e4 {
  flex: 1;
  min-width: 0;
  height: 100%;
  padding: 12px 16px;
  overflow-y: auto;
  background: #F5F5F5;
  border-bottom-right-radius: 4px;
}
.CustomizationDetailPanel___820e4 .related-meals-wrapper {
  overflow: hidden;
}
.CustomizationDetailPanel___820e4 .related-meals-wrapper .toggle-action {
  padding: 6px 16px;
  text-align: center;
  cursor: pointer;
}
.CustomizationDetailPanel___820e4 .section-header {
  margin-bottom: 16px;
}
.CustomizationDetailPanel___820e4 .section-header .section-title {
  font-size: 16px;
  line-height: 20px;
  color: #000;
}
