/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.TimePicker___2qIgR {
  display: flex;
  background: #ffffff;
  padding: 8px;
  flex: 1;
}
.TimePicker___2qIgR.compact___an4oV {
  background: none;
  padding: 0;
}
.TimePicker___2qIgR.compact___an4oV .quick-options-wrapper {
  flex: none;
}
.TimePicker___2qIgR.compact___an4oV .quick-options-wrapper .ant-tabs {
  position: static;
}
.TimePicker___2qIgR.large___73Fqj .quick-options-wrapper .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 16px;
}
.TimePicker___2qIgR .ant-tabs-card > .ant-tabs-nav {
  line-height: 1;
  max-width: 100%;
}
.TimePicker___2qIgR .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  padding: 4px 16px;
  line-height: 25px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 0;
  border: none;
  cursor: pointer;
  background: transparent;
  font-family: 'Proxima Nova';
}
.TimePicker___2qIgR .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  background: #ffedf3;
  color: #e00051;
}
.TimePicker___2qIgR .ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar {
  display: none;
}
.TimePicker___2qIgR .ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}
.TimePicker___2qIgR .ant-tabs-top > .ant-tabs-nav:before {
  border-bottom: none;
}
@media (min-width: 1400px) {
  .TimePicker___2qIgR .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-operations {
    position: relative !important;
    display: none;
  }
}
.TimePicker___2qIgR .quick-options-wrapper {
  flex: 1;
  min-width: 0;
  position: relative;
  height: 33px;
}
@media (max-width: 991px) {
  .TimePicker___2qIgR .quick-options-wrapper {
    width: 100%;
    flex: auto;
    margin-bottom: 12px;
  }
}
.TimePicker___2qIgR .quick-options-wrapper .ant-tabs {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
}
.TimePicker___2qIgR .ranger-picker-container {
  position: relative;
  float: left;
}
.TimePicker___2qIgR .ranger-picker-container .picker {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 2;
  padding: 15px;
  margin-top: 6px;
  background: #ffffff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  min-height: 350px;
}
.TimePicker___2qIgR .ranger-picker-container .picker:before {
  content: ' ';
  position: absolute;
  top: -6px;
  right: 30px;
  width: 0;
  height: 0;
  border-width: 0 6px 6px 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.15) transparent;
}
.TimePicker___2qIgR .ranger-picker-container .picker:after {
  content: ' ';
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 31px;
  width: 0;
  height: 0;
  border-width: 0 5px 5px 5px;
  border-style: solid;
  border-color: #fff transparent;
}
.TimePicker___2qIgR .ranger-picker-container .picker > div {
  position: relative !important;
}
.TimePicker___2qIgR .ranger-picker-container .picker .ant-picker {
  width: 100%;
}
.TimePicker___2qIgR .ranger-picker-container .picker .ant-picker-panel-container {
  box-shadow: none;
}
@media (max-width: 576px) {
  .TimePicker___2qIgR .ranger-picker-container .picker .ant-picker-panels {
    flex-wrap: wrap;
  }
}
.TimePicker___2qIgR .RangePicker___3cFNf .dete-input-group {
  padding: 0 6px;
  margin: -1px 0;
  border: 1px solid #E1E1E1;
  border-radius: 4px;
  background: #fff;
}
.TimePicker___2qIgR .RangePicker___3cFNf .time-input-group {
  border: 1px solid #E1E1E1;
  border-radius: 4px;
  background: #fff;
}
.TimePicker___2qIgR .RangePicker___3cFNf .time-input-group.has-error {
  border-color: #e00051;
}
.TimePicker___2qIgR .RangePicker___3cFNf .addon-button {
  padding: 0;
  height: 30px;
  color: #C0C2CC;
}
