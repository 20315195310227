/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.PageProductThirdpartyIntegration___pO8g7 .customerVoiceList___2Ge7B {
  width: 100%;
}
.PageProductThirdpartyIntegration___pO8g7 .bottomShowBaseContainer___15Rtl {
  margin-bottom: 30px;
}
.PageProductThirdpartyIntegration___pO8g7 .feature_checked_icon___g6P6b {
  width: 20px;
  height: 10px;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}
.PageProductThirdpartyIntegration___pO8g7 .chowbus_feature_checked___PYpIq {
  width: 22px;
  height: 22px;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}
.PageProductThirdpartyIntegration___pO8g7 .chowbus_logo___2M_gO {
  height: 22px;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}
.PageProductThirdpartyIntegration___pO8g7 .slick-dots {
  justify-content: flex-start;
  margin-left: 10px;
}
@media (max-width: 575px) {
  .PageProductThirdpartyIntegration___pO8g7 .slick-dots {
    justify-content: center;
  }
}
.PageProductThirdpartyIntegration___pO8g7 .slick-dots li {
  width: auto;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 575px) {
  .PageProductThirdpartyIntegration___pO8g7 .slick-dots li {
    height: 7px;
  }
}
.PageProductThirdpartyIntegration___pO8g7 .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
@media (max-width: 575px) {
  .PageProductThirdpartyIntegration___pO8g7 .slick-dots li button {
    width: 7px;
    height: 7px;
  }
}
.PageProductThirdpartyIntegration___pO8g7 .slick-dots li button::after {
  content: unset;
}
.PageProductThirdpartyIntegration___pO8g7 .slick-dots li.slick-active {
  width: auto;
}
.PageProductThirdpartyIntegration___pO8g7 .slick-dots li.slick-active button {
  background-color: #000000 !important;
}
.PageProductThirdpartyIntegration___pO8g7 .section-title {
  font-size: 30px !important;
  line-height: 40px !important;
}
@media (max-width: 575px) {
  .PageProductThirdpartyIntegration___pO8g7 .section-title {
    font-size: 20px !important;
    line-height: normal !important;
  }
}
.PageProductThirdpartyIntegration___pO8g7 .topSection {
  margin-bottom: 50px;
}
@media (max-width: 575px) {
  .PageProductThirdpartyIntegration___pO8g7 .topSection {
    margin-bottom: 0px;
  }
}
.PageProductThirdpartyIntegration___pO8g7 .topSection .description-info {
  order: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
@media (max-width: 575px) {
  .PageProductThirdpartyIntegration___pO8g7 .topSection .description-info {
    display: block;
  }
}
.PageProductThirdpartyIntegration___pO8g7 .topSection .description-card-poster {
  order: 2;
}
.PageProductThirdpartyIntegration___pO8g7 .topSection .description-card-poster .poster-img-container {
  border-radius: unset;
}
.PageProductThirdpartyIntegration___pO8g7 .topSection .description-content {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: #92929F;
}
@media (max-width: 575px) {
  .PageProductThirdpartyIntegration___pO8g7 .topSection .description-content {
    font-size: 19px;
    line-height: normal;
  }
}
.PageProductThirdpartyIntegration___pO8g7 .topSection .description-content-more {
  width: 100%;
  margin-top: 24px;
}
.PageProductThirdpartyIntegration___pO8g7 .topSection .description-content-more .description-content-item {
  margin-bottom: 14px;
}
.PageProductThirdpartyIntegration___pO8g7 .topSection .description-content-more .description-content-item .description-content {
  white-space: pre-line;
  font-size: 18px;
}
@media (max-width: 575px) {
  .PageProductThirdpartyIntegration___pO8g7 .topSection .description-content-more .description-content-item .description-content {
    font-size: 16px;
    white-space: normal;
  }
}
.PageProductThirdpartyIntegration___pO8g7 .topSection .description-content-more .description-content-item .description-content-title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: #1D1B2E;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 575px) {
  .PageProductThirdpartyIntegration___pO8g7 .topSection .description-content-more .description-content-item .description-content-title {
    font-size: 19px;
    line-height: 1.5;
  }
}
.PageProductThirdpartyIntegration___pO8g7 .topSection .description-content-more .description-content-item .description-content-title::after {
  content: '';
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('https://pos-static.chowbus.com/assets/checkbox_small.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 5px;
  flex-shrink: 0;
}
@media (max-width: 575px) {
  .PageProductThirdpartyIntegration___pO8g7 .topSection .description-content-more .description-content-item .description-content-title::after {
    width: 22px;
    height: 22px;
  }
}
.PageProductThirdpartyIntegration___pO8g7 .features-section .section-title {
  font-size: 30px;
  line-height: 40px;
}
@media (max-width: 575px) {
  .PageProductThirdpartyIntegration___pO8g7 .features-section .section-title {
    font-size: 20px;
    line-height: normal !important;
  }
}
.PageProductThirdpartyIntegration___pO8g7 .advance-section {
  margin: 50px 0;
}
@media (max-width: 575px) {
  .PageProductThirdpartyIntegration___pO8g7 .advance-section {
    margin: 30px 0;
  }
}
@media (max-width: 575px) {
  .PageProductThirdpartyIntegration___pO8g7 .advance-section .production-advance-card .description-container .title {
    font-size: 18px;
    line-height: 23px;
  }
}
.PageProductThirdpartyIntegration___pO8g7 .compare_table___2YKR1 .compare_column___1Lq5t {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  color: #E00051;
  overflow: hidden;
}
.PageProductThirdpartyIntegration___pO8g7 .compare_table___2YKR1 .compare_header___1Bz5V {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.PageProductThirdpartyIntegration___pO8g7 .compare_table___2YKR1 .compare_header___1Bz5V .compare_column___1Lq5t {
  background-color: #F5F5F5;
  border: 1px solid #F5F5F5;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  color: #E00051;
}
.PageProductThirdpartyIntegration___pO8g7 .compare_table___2YKR1 .compare_body___29Piw .compare_row_group___YBs8X:last-child .compare_row___dLbTK .compare_column___1Lq5t .row_span_shadow___EvBe8 {
  border: 0;
  border-bottom: 0;
}
.PageProductThirdpartyIntegration___pO8g7 .compare_table___2YKR1 .compare_body___29Piw .compare_row___dLbTK {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.PageProductThirdpartyIntegration___pO8g7 .compare_table___2YKR1 .compare_body___29Piw .compare_row___dLbTK .compare_column___1Lq5t {
  position: relative;
  border: 0;
  border-right: 1px solid #F5F5F5;
  border-bottom: 1px solid #F5F5F5;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  color: #92929F;
}
@media (max-width: 575px) {
  .PageProductThirdpartyIntegration___pO8g7 .compare_table___2YKR1 .compare_body___29Piw .compare_row___dLbTK .compare_column___1Lq5t {
    font-size: 12px;
  }
}
.PageProductThirdpartyIntegration___pO8g7 .compare_table___2YKR1 .compare_body___29Piw .compare_row___dLbTK .compare_column___1Lq5t .row_span_shadow___EvBe8 {
  position: absolute;
  top: 0;
  height: calc(var(--row-group-height));
  left: 0;
  right: 0;
  background-color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-bottom: 1px solid #F5F5F5;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #1D1B2E;
  background-color: #eaeaea;
}
@media (max-width: 575px) {
  .PageProductThirdpartyIntegration___pO8g7 .compare_table___2YKR1 .compare_body___29Piw .compare_row___dLbTK .compare_column___1Lq5t .row_span_shadow___EvBe8 {
    font-size: 14px;
  }
}
.PageProductThirdpartyIntegration___pO8g7 .compare_table___2YKR1 .compare_body___29Piw .compare_row___dLbTK .compare_column___1Lq5t.first___2HJbv {
  overflow: unset;
}
.PageProductThirdpartyIntegration___pO8g7 .compare_table___2YKR1 .compare_body___29Piw .compare_row___dLbTK .compare_column___1Lq5t.span___3fL6U {
  visibility: hidden;
  border: 0;
}
.PageProductThirdpartyIntegration___pO8g7 .moreButtonsContainer___2EqB_ {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 24px 0 ;
}
.PageProductThirdpartyIntegration___pO8g7 .moreButtonsContainer___2EqB_ .ant-btn-link {
  font-weight: 600;
}
@media (max-width: 575px) {
  .PageProductThirdpartyIntegration___pO8g7 .moreButtonsContainer___2EqB_ {
    margin: 12px 0 ;
  }
  .PageProductThirdpartyIntegration___pO8g7 .moreButtonsContainer___2EqB_ .ant-btn-link {
    font-size: 13px;
    font-weight: 600;
  }
}
