/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.GuideLine___3jXB8 {
  display: flex;
  align-items: center;
  padding: 24px 16px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.GuideLine___3jXB8 .left-wrapper {
  flex: 0 0 auto;
  width: 318px;
}
.GuideLine___3jXB8 .left-wrapper .image-wrapper {
  text-align: center;
}
.GuideLine___3jXB8 .left-wrapper .image-wrapper > img {
  height: 198px;
}
.GuideLine___3jXB8 .left-wrapper .tips-wrapper {
  margin-top: 24px;
  color: #8c8c8c;
  line-height: 20px;
}
.GuideLine___3jXB8 .right-wrapper {
  flex: 0 0 auto;
  width: calc(100% - 318px);
  padding-left: 54px;
}
.GuideLine___3jXB8 .right-wrapper .step-wrapper {
  margin-bottom: 36px;
}
.GuideLine___3jXB8 .right-wrapper .step-wrapper:last-child {
  margin-bottom: 0;
}
.GuideLine___3jXB8 .right-wrapper .step-wrapper .title-wrapper {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.GuideLine___3jXB8 .right-wrapper .step-wrapper .tips-wrapper {
  padding: 6px 0;
  color: #8c8c8c;
  line-height: 20px;
}
.GuideLine___3jXB8 .right-wrapper .step-wrapper .link-wrapper {
  margin-top: 4px;
}
.GuideLine___3jXB8 .right-wrapper .step-wrapper .link-wrapper .ant-btn {
  width: 120px;
  color: #1d1b2e;
}
