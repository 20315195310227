.legend-sales-gross_sales .ant-checkbox .ant-checkbox-inner {
  background: #e00051;
}
.legend-sales-net_sales .ant-checkbox .ant-checkbox-inner {
  background: #ffedf3;
}
.legend-platform-Chowbus .ant-checkbox .ant-checkbox-inner {
  background: #e00051;
}
.legend-platform-Caviar .ant-checkbox .ant-checkbox-inner {
  background: #fe9c22;
}
.legend-platform-Doordash .ant-checkbox .ant-checkbox-inner {
  background: #15d0c5;
}
.legend-platform-Grubhub .ant-checkbox .ant-checkbox-inner {
  background: #d34cb6;
}
.legend-platform-Postmates .ant-checkbox .ant-checkbox-inner {
  background: #8cd319;
}
.legend-platform-UberEats .ant-checkbox .ant-checkbox-inner {
  background: #6baae4;
}
.legend-platform-Fantuan .ant-checkbox .ant-checkbox-inner {
  background: #f76161;
}
.legend-platform-HungryPanda .ant-checkbox .ant-checkbox-inner {
  background: #00e0ff;
}
.legend-platform-Ricepo .ant-checkbox .ant-checkbox-inner {
  background: #19d342;
}
.legend-platform-Other .ant-checkbox .ant-checkbox-inner {
  background: #9360ff;
}
.legend-platform-Website .ant-checkbox .ant-checkbox-inner {
  background: #ffe600;
}
.legend-order-source-walk_in .ant-checkbox .ant-checkbox-inner {
  background: #e00051;
}
.legend-order-source-call_in .ant-checkbox .ant-checkbox-inner {
  background: #fe9c22;
}
.legend-order-source-qr_code .ant-checkbox .ant-checkbox-inner {
  background: #15d0c5;
}
.legend-order-source-kiosk .ant-checkbox .ant-checkbox-inner {
  background: #d34cb6;
}
.legend-order-source-payment_terminal .ant-checkbox .ant-checkbox-inner {
  background: #8cd319;
}
.legend-order-source-external .ant-checkbox .ant-checkbox-inner {
  background: #6baae4;
}
.legend-order-source-online .ant-checkbox .ant-checkbox-inner {
  background: #f76161;
}
.legend-service-type-dine_in .ant-checkbox .ant-checkbox-inner {
  background: #e00051;
}
.legend-service-type-take_out .ant-checkbox .ant-checkbox-inner {
  background: #fe9c22;
}
.legend-service-type-delivery .ant-checkbox .ant-checkbox-inner {
  background: #15d0c5;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
 {
  /** modify ant default style start */
  /** modify ant default style end */
}
html {
  font-size: 10px;
}
body {
  font-family: 'Proxima Nova', 'PingFang SC';
  font-weight: 400;
  font-feature-settings: normal;
  font-variant-numeric: normal;
}
h1,
h2,
h3,
h4,
b {
  font-weight: 700;
}
br {
  content: ' ';
  display: block;
  height: 20px;
}
video {
  object-fit: cover;
}
.bold {
  font-family: 'Proxima Nova';
  font-weight: 700;
}
.ant-table-content {
  border: 1px solid #e5e5e5;
}
.ant-table.ant-table-scroll-horizontal .ant-table-tbody tr:nth-of-type(odd) {
  background-color: #fafafa;
}
.ant-table.ant-table-scroll-horizontal .ant-table-tbody tr:nth-of-type(odd) td.ant-table-column-sort,
.ant-table.ant-table-scroll-horizontal .ant-table-tbody tr:nth-of-type(odd) td.ant-table-cell-fix-left {
  background-color: #fafafa;
}
.ant-table.ant-table-scroll-horizontal .ant-table-tbody tr:nth-of-type(odd):hover td {
  background-color: #f5f5f5;
}
.ant-table.ant-table-scroll-horizontal .ant-table-tbody tr:nth-of-type(even) td.ant-table-column-sort {
  background-color: #ffffff;
}
.ant-table.ant-table-scroll-horizontal .ant-table-tbody tr:nth-of-type(even):hover td.ant-table-column-sort,
.ant-table.ant-table-scroll-horizontal .ant-table-tbody tr:nth-of-type(even):hover td.ant-table-cell-fix-left {
  background-color: #fafafa;
}
.ant-table.ant-table-scroll-horizontal .ant-table-tbody tr.ant-table-row-selected td.ant-table-column-sort {
  background-color: #fff0f1;
}
.ant-table.ant-table-scroll-horizontal .ant-table-tbody tr.ant-table-row-selected:hover td.ant-table-column-sort {
  background-color: #ffe6e7;
}
.ant-table:not(.ant-table-scroll-horizontal) .ant-table-tbody tr:nth-of-type(odd) td.ant-table-column-sort {
  background-color: #ffffff;
}
.ant-table:not(.ant-table-scroll-horizontal) .ant-table-tbody tr:nth-of-type(odd):hover td.ant-table-column-sort,
.ant-table:not(.ant-table-scroll-horizontal) .ant-table-tbody tr:nth-of-type(odd):hover td.ant-table-cell-fix-left {
  background-color: #fafafa;
}
.ant-table:not(.ant-table-scroll-horizontal) .ant-table-tbody tr:nth-of-type(even) {
  background-color: #fafafa;
}
.ant-table:not(.ant-table-scroll-horizontal) .ant-table-tbody tr:nth-of-type(even) td.ant-table-cell-fix-left {
  background-color: #fafafa;
}
.ant-table:not(.ant-table-scroll-horizontal) .ant-table-tbody tr.ant-table-row-selected td.ant-table-column-sort {
  background-color: #fff0f1;
}
.ant-table:not(.ant-table-scroll-horizontal) .ant-table-tbody tr.ant-table-row-selected:hover td.ant-table-column-sort {
  background-color: #ffe6e7;
}
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  font-size: 15px !important;
}
.ant-table .ant-table-tbody tr.clickable {
  cursor: pointer;
}
.ant-table .ant-table-tbody tr.disabled-row {
  cursor: not-allowed !important;
  opacity: 0.5;
}
.ant-table .ant-table-tbody tr.selected-row > td {
  background: #fff0f1;
}
.ant-table .ant-table-tbody tr.highlight-row > td {
  background: #ffd0d0;
}
.ant-table .ant-table-tbody tr.highlight-row:hover > td {
  background: #ffc6c6;
}
.ant-picker-time-panel-column {
  overflow-y: auto !important;
}
.ant-picker-time-panel .ant-picker-time-panel-column:after {
  height: 0;
}
.ant-pagination-total-text {
  white-space: nowrap;
}
.ant-btn-link {
  color: #9b9b9b;
}
.ant-table-filter-dropdown .ant-table-filter-dropdown-btns .ant-btn-link {
  color: #e00051 !important;
}
.ant-radio-group.checkbox-style .ant-radio-inner {
  border-radius: 3px;
}
.ant-radio-group.checkbox-style .ant-radio-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  border-radius: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-form-item-label > label.ant-form-item-required::before {
  position: absolute;
  top: 3px;
  right: -12px;
  margin: 0;
}
.ant-form-item-label > label.ant-form-item-required.ant-form-item-no-colon::before {
  top: 2px;
  right: -4px;
}
.ant-form-vertical .ant-form-item-label > label.ant-form-item-no-colon {
  height: 32px;
}
.ant-form-vertical .ant-form-item-label > label.ant-form-item-no-colon::after {
  display: block;
}
.ant-form-item-label > label {
  line-height: normal;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background: #ce2c54;
  border-color: #ce2c54;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background: #fff;
  height: 2px;
}
.ant-tabs-dropdown .ant-tabs-tab-active .ant-checkbox-wrapper {
  color: #ce2c54;
}
.ant-tabs-dropdown .ant-checkbox-indeterminate .ant-checkbox-inner {
  background: #ce2c54;
  border-color: #ce2c54;
}
.ant-tabs-dropdown .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background: #fff;
  height: 2px;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background: #ce2c54;
  border-color: #ce2c54;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  background: #fff;
  height: 2px;
}
.ant-switch {
  transform: scale(1.2);
  transform-origin: 80% 80%;
}
.ant-switch.small {
  transform: none;
}
@media (max-width: 575px) {
  .ant-switch {
    transform-origin: center center;
  }
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  border-bottom: 1px solid #f5f5f5;
}
.toggle-field .ant-form-item-row {
  flex-direction: row;
}
@media (min-width: 576px) {
  .toggle-field .ant-form-item-control {
    text-align: right;
  }
}
.ant-switch-checked {
  background: #52c41a;
}
@media (max-width: 576px) {
  .ant-pagination {
    flex-wrap: wrap;
  }
}
.g2-tooltip {
  font-family: 'Proxima Nova' !important;
  font-size: 14px !important;
}
.g2-tooltip-list-item {
  min-width: 180px;
}
.g2-tooltip-list-item .g2-tooltip-value {
  margin-left: 0 !important;
}
.error-helper {
  color: #ce2c54;
}
.tooltip-content {
  padding: 6px 8px;
  display: flex;
  font-size: 12px;
  line-height: 14px;
}
.tooltip-content .index-item + .index-item {
  margin-left: 16px;
}
.tooltip-content .index-value {
  margin-top: 6px;
}
.absolute-center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.absolute-center.vertical {
  flex-direction: column;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.icon-file {
  display: inline-block;
  width: 17px;
  height: 22px;
  background-image: url('https://pos-static.chowbus.com/assets/icon_file.png');
  background-size: cover;
  cursor: pointer;
}
.icon-download {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url('https://pos-static.chowbus.com/assets/icon_download.png');
  background-size: cover;
  cursor: pointer;
}
.icon-download-black {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url('https://pos-static.chowbus.com/assets/icon_download_black.png');
  background-size: cover;
  cursor: pointer;
}
.icon-download-black.disable {
  cursor: wait;
  opacity: 0.1;
}
.icon-delete {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('https://pos-static.chowbus.com/assets/icon_del.png');
  background-size: cover;
  cursor: pointer;
}
.icon-filter {
  display: block;
  width: 21px;
  height: 21px;
  background-image: url('https://pos-static.chowbus.com/assets/icon_filter.png');
  background-size: cover;
  cursor: pointer;
}
.icon-eye {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url('https://pos-static.chowbus.com/assets/icon_eye.svg');
  background-size: contain;
  cursor: pointer;
}
.icon-warn {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url('https://pos-static.chowbus.com/assets/icon_warn.png');
  background-size: contain;
  vertical-align: -2px;
}
.icon-person {
  display: block;
  width: 16px;
  height: 16px;
  background-image: url('https://pos-static.chowbus.com/assets/icon_person.png');
  background-size: contain;
}
.icon-sort-placeholder {
  width: 16px;
  height: 16px;
  background-image: url('https://pos-static.chowbus.com/assets/icon_sort_placeholder.svg');
  background-size: contain;
}
.icon-wrapper {
  padding: 2px;
  height: 20px;
  cursor: pointer;
  line-height: 1;
}
.icon-wrapper .icon-edit,
.icon-wrapper .icon-delete,
.icon-wrapper .icon-add,
.icon-wrapper .icon-history,
.icon-wrapper .icon-refresh {
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
}
.icon-wrapper.large {
  height: 24px;
}
.icon-wrapper.large .icon-edit,
.icon-wrapper.large .icon-delete,
.icon-wrapper.large .icon-add,
.icon-wrapper.large .icon-history,
.icon-wrapper.large .icon-refresh {
  width: 20px;
  height: 20px;
}
.icon-wrapper .icon-edit {
  background-image: url("https://pos-static.chowbus.com/assets/icon_edit.svg");
}
.icon-wrapper .icon-delete {
  background-image: url("https://pos-static.chowbus.com/assets/icon_delete.svg");
}
.icon-wrapper .icon-add {
  background-image: url("https://pos-static.chowbus.com/assets/icon_add.svg");
}
.icon-wrapper .icon-history {
  background-image: url("https://pos-static.chowbus.com/assets/icon_history.svg");
}
.icon-wrapper .icon-refresh {
  background-image: url("https://pos-static.chowbus.com/assets/icon_refresh.svg");
}
.icon-wrapper:hover .icon-edit,
.icon-wrapper:active .icon-edit {
  background-image: url("https://pos-static.chowbus.com/assets/icon_edit_active.svg");
}
.icon-wrapper:hover .icon-delete,
.icon-wrapper:active .icon-delete {
  background-image: url("https://pos-static.chowbus.com/assets/icon_delete_active.svg");
}
.icon-wrapper:hover .icon-add,
.icon-wrapper:active .icon-add {
  background-image: url("https://pos-static.chowbus.com/assets/icon_add_active.svg");
}
.icon-wrapper:hover .icon-history,
.icon-wrapper:active .icon-history {
  background-image: url("https://pos-static.chowbus.com/assets/icon_history_active.svg");
}
.icon-wrapper:hover .icon-refresh,
.icon-wrapper:active .icon-refresh {
  background-image: url("https://pos-static.chowbus.com/assets/icon_refresh_active.svg");
}
.icon-copy {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("https://pos-static.chowbus.com/assets/icon_copy.svg");
  cursor: pointer;
}
.icon-copy:hover,
.icon-copy:active {
  background-image: url("https://pos-static.chowbus.com/assets/icon_copy_active.svg");
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.text-capitalize {
  text-transform: capitalize;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.break-word {
  word-break: break-all;
  hyphens: auto;
}
.no-wrap {
  white-space: nowrap;
}
.required-badge {
  position: relative;
  top: -5px;
  left: 4px;
  color: #ff4d4f;
  line-height: 1;
  font-size: 14px;
  font-family: SimSun, sans-serif;
}
.text-right {
  text-align: right;
}
.page-title {
  font-size: 24px;
  line-height: 32px;
  font-family: 'Proxima Nova';
  white-space: nowrap;
  font-weight: bold;
}
.error-block {
  padding-top: 200px;
  text-align: center;
  font-size: 18px;
}
.one-line-ellipsis {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  word-break: break-all;
}
.two-line-ellipsis {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.scroll-container {
  overflow-y: auto;
  scroll-behavior: smooth;
}
.scroll-container::-webkit-scrollbar {
  width: 8px;
}
.scroll-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.scroll-container:hover::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
  border-radius: 4px;
}
.scroll-container:hover::-webkit-scrollbar-thumb:hover {
  background-color: #BFBFBF;
}
.scroll-container::-webkit-scrollbar-track,
.scroll-container::-webkit-scrollbar-track-piece {
  display: none;
}
.loading-circle {
  animation: loadingCircle 2s linear 0s infinite;
}
