/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.privacy-policy-page {
  background-color: #f5f5f5 !important;
}
.privacy-policy-page .ant-layout-content {
  padding: 1em !important;
  display: flex;
  justify-content: center;
}
.privacy-policy-page .ant-layout-content .privacy-policy-page-content {
  background-color: #fff;
  padding: 1em;
}
.privacy-policy-page .ant-layout-content .privacy-policy-page-content .title {
  line-height: 2;
  font-size: 16px;
  font-weight: 600;
}
.privacy-policy-page .ant-layout-content .privacy-policy-page-content .sub-title {
  line-height: 2;
  border-bottom: 1px solid #ebebeb;
}
.privacy-policy-page .ant-layout-content .privacy-policy-page-content div {
  line-height: 1.6;
  font-size: 15px;
  color: #000000;
  margin: 24px 0;
}
