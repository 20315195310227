.QRCodeSettings___1iQFV {
  font-size: 16px;
}
.QRCodeSettings___1iQFV .submitBtn {
  position: absolute;
  z-index: 999;
  top: 15px;
  right: 50%;
}
.QRCodeSettings___1iQFV .ant-form-item {
  margin-bottom: 16px;
}
.QRCodeSettings___1iQFV .ant-form-item-control {
  text-align: right;
  padding-right: 0.9%;
}
.QRCodeSettings___1iQFV .tabs-setting {
  width: 100%;
  margin-top: -25px;
}
.QRCodeSettings___1iQFV .tabs-setting .titleWrap {
  display: flex;
  align-items: center;
}
.QRCodeSettings___1iQFV .tabs-setting .titleWrap .lineHeight {
  border-left: 2px solid #92929f;
  height: 20px;
  margin-left: 12px;
  margin-right: 12px;
}
.QRCodeSettings___1iQFV .tabs-setting .ant-tabs-nav {
  background-color: #f0f2f5;
  position: sticky;
  padding-bottom: 15px;
  padding-top: 20px;
  font-size: 20px;
  z-index: 998;
  top: 55px;
  width: 100%;
}
.QRCodeSettings___1iQFV .tabs-setting .ant-tabs-nav .ant-tabs-tab {
  padding: 0;
  font-size: 20px;
}
.QRCodeSettings___1iQFV .tabs-setting .ant-tabs-tabpane {
  width: 100%;
}
.QRCodeSettings___1iQFV .section-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #000000;
}
.QRCodeSettings___1iQFV .section-desc {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}
.QRCodeSettings___1iQFV .ant-form-item-label > label {
  white-space: normal;
  height: auto;
}
.QRCodeSettings___1iQFV .config-section .config-section-title {
  margin-bottom: 20px;
}
.QRCodeSettings___1iQFV .config-section .ant-form-item-control {
  text-align: left;
}
.QRCodeSettings___1iQFV .config-section .ant-radio-wrapper {
  margin-bottom: 16px;
}
.QRCodeSettings___1iQFV .ant-form-item-label {
  text-align: left;
}
.QRCodeSettings___1iQFV .category-setting .ant-form-item-label > label {
  height: auto;
}
.QRCodeSettings___1iQFV .extra-setting .ant-form-item-control {
  flex: 1;
  padding-right: 0;
  margin-right: -10px;
}
.QRCodeSettings___1iQFV .extra-setting .ant-form-item-control-input {
  margin-right: -10px;
}
.QRCodeSettings___1iQFV .empty-content {
  height: calc(100vh - 360px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 16px;
}
.QRCodeSettings___1iQFV .ant-checkbox-inner {
  transform: scale(1.3);
}
.QRCodeSettings___1iQFV .ant-table-row {
  cursor: pointer;
}
.QRCodeSettings___1iQFV .ant-table-row.disabled {
  color: #cccccc;
  cursor: default;
}
