.OnlinePlatformsSettings___1Miuk .ant-card .ant-typography {
  margin-bottom: 20px;
}
.OnlinePlatformsSettings___1Miuk .ant-card .ant-form-item-label > label {
  height: auto;
  line-height: 32px;
}
.OnlinePlatformsSettings___1Miuk .ant-card .ant-form-item:last-child {
  margin-bottom: 0;
}
.OnlinePlatformsSettings___1Miuk .help-icon {
  margin-left: 4px;
}
.OnlinePlatformsSettings___1Miuk .title {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 8px;
}
.OnlinePlatformsSettings___1Miuk p {
  color: #1D1B2E;
}
.OnlinePlatformsSettings___1Miuk .ant-checkbox-wrapper {
  margin-bottom: 10px;
}
.OnlinePlatformsSettings___1Miuk .preference_row___3p5TI {
  margin: 20px 0 20px 0;
}
