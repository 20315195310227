.waitlistDetail___GYad- {
  width: 100vw;
  padding: 0 !important;
  margin: 0 auto;
  min-width: 100% !important;
  width: 100% !important;
  -webkit-text-size-adjust: none;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background: #c6c6c6;
  font-family: 'Proxima Nova', 'PingFang SC';
}
.waitlistDetailContainer___37dJ- {
  min-height: calc(var(--app-height));
  margin: 0 !important;
  display: block;
  -webkit-text-size-adjust: none;
  max-width: 414px;
  width: 100%;
  padding: 37px 17px 45px 17px;
  height: calc(var(--app-height));
  overflow-y: auto;
  background-color: #f5f5f5;
  position: relative;
}
.googleMapContainer___32Pf8 {
  min-height: calc(var(--app-height));
  margin: 0 !important;
  display: block;
  -webkit-text-size-adjust: none;
  max-width: 414px;
  width: 100%;
  padding: 0;
  height: calc(var(--app-height));
  overflow-y: auto;
  background-color: #f5f5f5;
  position: absolute;
  left: 0;
  top: 0;
}
.googleMapContainer___32Pf8 .closeGoogleMap___1SecO {
  width: 34px;
  height: 34px;
  background-image: url('https://pos-static.chowbus.com/assets/back_icon.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  position: absolute;
  left: 10px;
  top: 10px;
  border-radius: 100%;
  background-color: #ffffff;
  z-index: 10;
}
.langSwitch___2Q0zQ {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #92929F;
  background-color: #ffffff;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 100%;
}
.waitlistDetail___GYad-.error___1YFQM .waitlistDetailContainer___37dJ- {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #E00051;
  background-color: #ffffff;
}
.waitlistDetail___GYad-.error___1YFQM .waitlistDetailContainer___37dJ- .poweredByChowbusContainer___2THSs {
  position: fixed;
  bottom: 30px;
}
.restaurantInfoContainer___LHWg4 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.restaurantName___3BKQR {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.restaurantContract___29f-U,
.restaurantAddress___2joQM {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: normal;
  text-align: left;
  color: #000000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 7px;
}
.restaurantContract___29f-U a {
  color: #000000;
}
.restaurantContract___29f-U a:hover {
  color: #000000;
}
.restaurantContract___29f-U a:visited {
  color: #000000;
}
.restaurantAddress___2joQM::before,
.restaurantContract___29f-U::before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background-image: url('https://pos-static.chowbus.com/assets/location_icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 7px;
  flex-shrink: 0;
}
.restaurantContract___29f-U::before {
  background-image: url('https://pos-static.chowbus.com/assets/phone_icon.png');
}
.waitDetailContainer___2rUWW {
  width: 100%;
  background: #FFFFFF;
  border-radius: 16px;
  margin-top: 20px;
  padding: 16px;
}
.waitDetailContainer___2rUWW .header___32hmN {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.header___32hmN .title___3jG8E {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #1D1B2E;
  flex: 1;
  text-align: left;
}
.header___32hmN .statusTag___2KzVI {
  background: #FFEDF3;
  border-radius: 16px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  color: #E00051;
  display: flex;
  justify-content: center;
  align-items: center;
}
.waitTicketNumber___Q_dAQ {
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 72px;
  color: #E00051;
  margin-top: 5px;
}
.queueInfoContainer___36C_z {
  margin-top: 5px;
}
.queueInfo___21K-_ {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1D1B2E;
}
.queueInfo___21K-_ .emphasized___2FiSM {
  color: #E00051;
}
.waitlistSummary___12BOk {
  width: 100%;
  margin-top: 25px;
}
.waitlistSummary___12BOk .summaryItem___2Zy7B {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #92929F;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 5px;
}
.summaryItem___2Zy7B .itemHeader___1a_hv {
  flex: 2;
  min-width: 70px;
}
.en___2RBkZ .summaryItem___2Zy7B .itemHeader___1a_hv {
  flex: 3;
  min-width: 100px;
}
.summaryItem___2Zy7B .itemContent___30045 {
  flex: 1;
  overflow: hidden;
  padding-left: 12px;
  flex: 7;
}
.waitlistConfirmSection___1dJBj {
  width: 100%;
  margin-top: 25px;
}
.waitlistConfirmSection___1dJBj .description___30Aje {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #1D1B2E;
  white-space: pre-line;
}
.actionBar___1v9t7 {
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.actionBar___1v9t7 .button___2QVaS {
  height: 40px;
  border-radius: 20px;
  padding: 8px 0;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  flex: 1;
  cursor: pointer;
}
.actionBar___1v9t7 .button___2QVaS.primary___2h0iz {
  background-color: #E00051;
  color: #FFFFFF;
}
.actionBar___1v9t7 .button___2QVaS.second___2Ztha {
  background-color: #F5F5F5;
  color: #92929F;
}
.cancelConfirmWrapper___3RhvS {
  min-height: calc(var(--app-height));
  margin: 0 !important;
  display: block;
  -webkit-text-size-adjust: none;
  max-width: 414px;
  width: 100%;
  padding: 0;
  height: calc(var(--app-height));
  overflow-y: auto;
  background-color: #f5f5f5;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.cancelConfirmMain___vb69B {
  margin: 0 16px;
  width: calc(100% - 32px);
  background: #FFFFFF;
  border-radius: 16px;
  overflow: hidden;
  padding: 16px;
}
.cancelConfirmMain___vb69B .cancelConfirmBody___2FBxf {
  width: 100%;
}
.cancelConfirmMain___vb69B .cancelConfirmBody___2FBxf .cancelConfirmTitle___1wpFo {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #1D1B2E;
  display: flex;
  justify-content: center;
  text-align: center;
}
.cancelConfirmMain___vb69B .cancelConfirmBody___2FBxf .cancelConfirmSubTitle___161Sr {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1D1B2E;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.cancelConfirmMain___vb69B .cancelConfirmActions___15wzR {
  width: 100%;
  margin-top: 24px;
}
.chowbusAppDownloadContainer___2lo3z {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 28px;
}
.chowbusAppDownloadContainer___2lo3z .left___3zQ1m {
  flex: 232;
  margin-right: 23px;
  margin-top: 12px;
}
.chowbusAppDownloadContainer___2lo3z .right___1g3jU {
  flex: 88;
}
.downloadPromotionSection___nQPjD {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
}
.downloadPromotionSection___nQPjD::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 32px;
  height: 6px;
  background-color: #e00051;
}
.downloadPromotionTitle___1HQfc {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  text-transform: uppercase;
  color: #241e3f;
  white-space: pre-line;
}
.downloadPromotionSubTitle___1IQlO {
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 13px;
  letter-spacing: -0.32px;
  color: #92929f;
  margin-top: 5px;
}
.downloadLinks___1_GQO {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-end;
  margin-top: 18px;
}
.downloadLinks___1_GQO .store___3vzgI {
  flex: 1;
}
.downloadLinks___1_GQO .store___3vzgI:last-child {
  margin-left: 7px;
}
.androidAppStore___31lx_ {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('https://pos-static.chowbus.com/assets/chowbusapp/Google_Play_Store_downloadpng.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.appleAppStore___1tFf4 {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('https://pos-static.chowbus.com/assets/chowbusapp/Apple_App_Store_download.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.appDLPhone___1vmNM {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('https://pos-static.chowbus.com/assets/chowbusapp/chowbus_app_download_phone.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.poweredByChowbusContainer___2THSs {
  width: 200px;
  margin: 0 auto;
  margin-top: 28px;
}
.powerbyChowbuslogo___1zVl1 {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('https://pos-static.chowbus.com/assets/powered by chowbus_line.png');
  background-size: contain;
  background-repeat: no-repeat;
}
