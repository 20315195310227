.TipPolicyRules___XlhJJ .ant-card-body {
  color: #000000;
}
.TipPolicyRules___XlhJJ .ant-card-body .rule-descriptions {
  padding: 10px 20px;
  margin: 16px 0;
  background: #F5F5F5;
  border-radius: 8px;
  line-height: 22px;
}
.TipPolicyRules___XlhJJ .ant-card-body .rule-descriptions p {
  margin-bottom: 0;
}
.TipPolicyRules___XlhJJ .ant-card-body .rule-descriptions p + p {
  margin-top: 7px;
}
.TipPolicyRules___XlhJJ .ant-card-body .rules-container {
  overflow-x: auto;
  width: 100%;
  padding: 0 8px;
  margin: 0 -8px;
}
.TipPolicyRules___XlhJJ .ant-card-body .copy {
  line-height: 40px;
  white-space: nowrap;
}
