.landingContainer___3qa1I {
  width: 100%;
  min-height: calc(100vh - 64px);
  background: #FFFFFF;
  padding: 0px 24px;
  margin-bottom: 30px;
}
.contentWrapper___218lC {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  padding-left: 64px !important;
  padding-right: 64px !important;
  gap: 32px !important;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .contentWrapper___218lC {
    flex-direction: column;
    padding-left: 0px !important;
    padding-right: 0px !important;
    gap: 40px;
    position: relative;
  }
}
.leftContent___13Hpt {
  width: 100%;
  flex: none;
}
@media (max-width: 768px) {
  .leftContent___13Hpt {
    max-width: 100%;
    order: 2;
  }
}
.textContent___23rBV {
  margin-bottom: 40px;
}
.title___1ZJjl {
  font-size: 42px !important;
  line-height: 48px !important;
  font-weight: 700 !important;
  color: #1d1b2e !important;
  margin-bottom: 24px;
}
@media (max-width: 768px) {
  .title___1ZJjl {
    font-size: 32px;
  }
}
.subtitle___1tWqw {
  font-size: 22px !important;
  line-height: 32px !important;
  color: #92929f !important;
  margin-bottom: 0;
}
.insightsList___2gLKc .insightsTitle___2fUeI {
  font-size: 24px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
  color: #e00051 !important;
  margin-bottom: 24px !important;
}
.insightsList___2gLKc ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.insightsList___2gLKc ul li {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.5;
  color: #666666;
}
.insightsList___2gLKc ul li .insightContent___3YplP .insightLine___3cNaP {
  line-height: 24px;
}
.insightsList___2gLKc ul li .insightContent___3YplP .boldLine___1ZF_a {
  font-weight: 700 !important;
}
.insightsList___2gLKc ul li span:last-child {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #92929f;
}
.insightsList___2gLKc ul li:last-child {
  margin-bottom: 0;
}
.checkmark___3pVPo {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.checkmark___3pVPo::before {
  content: '';
  position: absolute;
  width: 11px;
  height: 7px;
  top: 9px;
  left: 6px;
  border-left: 2.5px solid #E6185F;
  border-bottom: 2.5px solid #E6185F;
  transform: rotate(-45deg);
}
.rightContent___1qoOQ {
  width: 100%;
  background: #F8F8F8;
  border-radius: 8px;
  padding: 32px;
}
@media (max-width: 768px) {
  .rightContent___1qoOQ {
    padding: 24px;
    margin-top: 0;
    order: 1;
  }
}
.formTitle___JTGDj {
  font-size: 24px !important;
  line-height: 32px !important;
  font-weight: 700 !important;
  color: #1d1b2e !important;
  text-align: center;
  margin-bottom: 24px !important;
}
.nameFields___3z06M {
  display: flex;
  gap: 16px;
}
.nameFields___3z06M .ant-form-item___9Qbht {
  flex: 1;
  margin-bottom: 0;
}
.form___2b0n0 .ant-form-item {
  margin-bottom: 16px;
  flex: 1;
}
.form___2b0n0 .ant-form-item:last-child {
  margin-bottom: 0;
}
.form___2b0n0 .ant-input {
  height: 48px;
  padding: 12px 16px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #E6E6E6;
  background: #FFFFFF;
}
.form___2b0n0 .ant-input::placeholder {
  color: #999999;
}
.form___2b0n0 .ant-input:hover,
.form___2b0n0 .ant-input:focus {
  border-color: #E6185F;
  box-shadow: none;
}
.form___2b0n0 .ant-btn-primary {
  height: auto;
  padding: 14px 45px;
  line-height: 16px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 30px;
  background: #E6185F;
  border: none;
  box-shadow: none;
}
.form___2b0n0 .ant-btn-primary:hover {
  background: #b8134c;
}
.form___2b0n0 .ant-btn-primary:focus {
  background: #E6185F;
  box-shadow: none;
}
.form___2b0n0 .ant-form-item-explain-error {
  font-size: 14px;
  color: #E6185F;
  margin-top: 4px;
}
.submitButton___1XByV {
  display: flex;
  justify-content: center;
  margin-top: 24px !important;
  margin-bottom: 0 !important;
}
@media (max-width: 768px) {
  .landingContainer___3qa1I {
    padding: 40px 16px;
  }
  .title___1ZJjl {
    font-size: 28px;
  }
  .subtitle___1tWqw {
    font-size: 22px !important;
  }
  .rightContent___1qoOQ {
    padding: 24px;
  }
  .formTitle___JTGDj {
    font-size: 20px;
  }
  .nameFields___3z06M {
    flex-direction: column;
    gap: 20px;
  }
}
