.MealPrototypeIdsSelector___XdyTN {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 920px;
  padding: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.MealPrototypeIdsSelector___XdyTN .wrapper {
  width: 100%;
  height: 100%;
}
.MealPrototypeIdsSelector___XdyTN .wrapper .all-wrapper {
  margin-bottom: 8px;
}
.MealPrototypeIdsSelector___XdyTN .wrapper .scroll-wrapper {
  display: flex;
  height: calc(100% - 40px);
}
.MealPrototypeIdsSelector___XdyTN .wrapper .scroll-wrapper .list-wrapper {
  flex: 1;
  height: 100%;
  margin-right: -12px;
  padding-right: 12px;
  overflow-y: auto;
}
.MealPrototypeIdsSelector___XdyTN .wrapper .scroll-wrapper .list-wrapper .list-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.MealPrototypeIdsSelector___XdyTN .wrapper .scroll-wrapper .list-wrapper .list-item:last-child {
  margin-bottom: 0;
}
.MealPrototypeIdsSelector___XdyTN .wrapper .scroll-wrapper .list-wrapper .list-item .toggle-handle {
  flex: 0;
}
.MealPrototypeIdsSelector___XdyTN .wrapper .scroll-wrapper .list-wrapper .list-item .list-item-content {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  white-space: normal;
}
.MealPrototypeIdsSelector___XdyTN .wrapper .scroll-wrapper .list-wrapper .list-item .list-item-content.selected {
  background: #ffedf3;
  border: 1px solid #e00051;
}
.MealPrototypeIdsSelector___XdyTN .wrapper .scroll-wrapper .list-wrapper .list-item .list-item-content.disabled {
  opacity: 0.25;
  cursor: not-allowed;
}
.MealPrototypeIdsSelector___XdyTN .wrapper .scroll-wrapper .list-wrapper .list-item .list-item-content .list-item-name {
  color: #000000;
  font-size: 14px;
  line-height: 20px;
}
.MealPrototypeIdsSelector___XdyTN .wrapper .scroll-wrapper .ant-divider {
  flex: 0;
  height: 100%;
  margin: 0 32px;
  border: none;
  border-left: 1px solid #d9d9d9;
}
.MealPrototypeIdsSelector___XdyTN .wrapper .ant-checkbox-wrapper > span {
  padding: 0;
}
.MealPrototypeIdsSelector___XdyTN .wrapper .ant-checkbox-wrapper > span:first-child {
  margin-right: 16px;
}
