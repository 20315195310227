.campaignAccount___G5spl .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.campaignAccount___G5spl .header .title {
  font-weight: 600;
  font-size: 28px;
  color: #000000;
}
.campaignAccount___G5spl .header .register {
  width: 120px;
  height: 40px;
}
.iconEye___1GXp3 {
  cursor: pointer;
}
.iconEye___1GXp3:hover path {
  fill: #e00051;
}
.iconHistory___1gn9u {
  cursor: pointer;
}
.iconHistory___1gn9u:hover path {
  fill: none;
  stroke: #e00051;
}
.activated___3uuOK {
  color: #15d0c5;
}
.rejected___oqUZO {
  color: #e00051;
}
.pending___1PFy8 {
  color: #fdc229;
}
.Active_bg___WgN04 {
  background-color: #15d0c5;
}
.Rejected_bg___2PwT7 {
  background-color: #e00051;
}
.Pending_bg___1A08U {
  background-color: #fdc229;
}
.Reset_bg___2mzTg {
  background-color: #000000;
}
.timeBall___2q2_O {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
