.TableContent___2_aHT .ant-table-wrapper .ant-table-content {
  border: 0;
}
.TableContent___2_aHT .ant-table-wrapper .ant-table-content .ant-table-thead .ant-table-cell {
  padding: 18.5px 16px;
  font-family: 'Proxima Nova';
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  line-height: 18px;
  background-color: #fafafa;
}
.TableContent___2_aHT .ant-table-wrapper .ant-table-content .ant-table-thead .ant-table-cell:not(:last-child) {
  border-right: none;
}
.TableContent___2_aHT .ant-table-wrapper .ant-table-content .ant-table-tbody {
  background-color: #ffffff;
}
.TableContent___2_aHT .ant-table-wrapper .ant-table-content .ant-table-tbody .ant-table-row:nth-of-type(even) {
  background-color: #fafafa;
}
.TableContent___2_aHT .ant-table-wrapper .ant-table-content .ant-table-tbody .ant-table-row .ant-table-cell {
  padding: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 22px;
}
.TableContent___2_aHT .ant-table-wrapper .ant-table-content .ant-table-tbody .ant-table-row .ant-table-cell:not(:last-child) {
  border-right: none;
}
.TableContent___2_aHT .ant-table-wrapper .ant-table-content .ant-table-tbody .ant-table-row .ant-table-cell:last-child {
  padding: 15px;
}
.TableContent___2_aHT .ant-table-wrapper .ant-table-content .ant-table-tbody .ant-table-row .ant-table-cell .ant-dropdown-trigger {
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
}
.TableContent___2_aHT .ant-table-wrapper .ant-table-content .ant-table-tbody .ant-table-row .ant-table-cell .ant-dropdown-trigger .anticon {
  width: 24px;
  height: 24px;
}
.TableContent___2_aHT .ant-table-wrapper .ant-table-content .ant-table-tbody .ant-table-row .ant-table-cell .ant-dropdown .ant-dropdown-menu {
  padding: 16px;
}
.TableContent___2_aHT .ant-table-wrapper .ant-table-content .ant-table-tbody .ant-table-row .ant-table-cell .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  margin-bottom: 16px;
  padding: 0;
  color: #1d1b2e;
  font-size: 14px;
  line-height: 20px;
}
.TableContent___2_aHT .ant-table-wrapper .ant-table-content .ant-table-tbody .ant-table-row .ant-table-cell .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:last-child {
  margin-bottom: 0;
}
