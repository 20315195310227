/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.FloorPlanSettings___1NwFb .group .group-header {
  display: flex;
  justify-content: space-between;
}
.FloorPlanSettings___1NwFb .group .group-title {
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}
.FloorPlanSettings___1NwFb .group .group-content {
  margin-top: 22px;
}
.FloorPlanSettings___1NwFb .ant-form-item-label {
  text-align: left;
  font-weight: 700;
}
.FloorPlanSettings___1NwFb .ant-form-item-label > label {
  height: auto;
  white-space: normal;
}
.FloorPlanSettings___1NwFb .secondary-settings {
  margin-left: 30px;
}
.FloorPlanSettings___1NwFb .enter-PIN-settings {
  margin-bottom: 24px;
}
.FloorPlanSettings___1NwFb .enter-PIN-settings .settings-title {
  margin-bottom: 10px;
}
.FloorPlanSettings___1NwFb .enter-PIN-settings .settings-content {
  margin-left: 30px;
}
.FloorPlanSettings___1NwFb .enter-PIN-settings .settings-section .settings-section-header {
  margin-bottom: 18px;
}
.FloorPlanSettings___1NwFb .enter-PIN-settings .settings-section .settings-section-header .section-description {
  color: #92929f;
}
.FloorPlanSettings___1NwFb .enter-PIN-settings .settings-section .settings-section-content .ant-radio-group {
  display: block;
}
.FloorPlanSettings___1NwFb .enter-PIN-settings .settings-section .settings-section-content .secondary-title {
  margin-bottom: 16px;
}
.FloorPlanSettings___1NwFb .enter-PIN-settings .settings-section .settings-section-content .settings-options .option-item + .option-item {
  margin-top: 16px;
}
.FloorPlanSettings___1NwFb .description {
  color: #92929F;
  line-height: 1;
  margin-top: 4px;
}
.FloorPlanSettings___1NwFb .help-icon {
  margin-left: 4px;
}
