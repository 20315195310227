.OptionItem___3E8up {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  cursor: move;
}
.OptionItem___3E8up + .OptionItem___3E8up {
  margin-top: 10px;
}
.OptionItem___3E8up .option-fields {
  display: inline-flex;
  flex: 1;
  min-width: 0;
  flex-wrap: nowrap;
  gap: 16px;
}
.OptionItem___3E8up .option-fields .field-item {
  flex: 1;
}
.OptionItem___3E8up .option-fields .field-item .field-item-label {
  line-height: 22px;
  color: #8C8C8C;
  margin-bottom: 8px;
}
