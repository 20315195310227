.tableSettingModal___1ScA_ .postfix___3Tm2i {
  margin: 0 10px 0 5px;
  color: #92929F;
  white-space: nowrap;
}
.tableSettingModal___1ScA_ .ant-form-item {
  margin-bottom: 10px;
}
.ReservationSettings___2K8Qu .ant-spin-nested-loading.loading .ant-spin-spinning {
  position: fixed;
  bottom: 0;
  max-height: unset;
}
.ReservationSettings___2K8Qu .sortable-list-item {
  margin-bottom: 0;
}
.ReservationSettings___2K8Qu .group .group-header {
  display: flex;
  justify-content: space-between;
}
.ReservationSettings___2K8Qu .group .group-title {
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}
.ReservationSettings___2K8Qu .group .group-content {
  margin-top: 22px;
}
.ReservationSettings___2K8Qu .ant-card-body {
  padding: 24px 24px 10px 24px;
}
.ReservationSettings___2K8Qu .setting-wrapper + .setting-wrapper {
  margin-top: 16px;
}
.ReservationSettings___2K8Qu .section-name {
  font-size: 18px;
  color: #999;
  margin-bottom: 10px;
}
.ReservationSettings___2K8Qu .ant-radio-wrapper {
  white-space: normal;
}
@media (min-width: 992px) {
  .ReservationSettings___2K8Qu .switch-wrapper {
    text-align: right;
  }
  .ReservationSettings___2K8Qu .switch-wrapper .ant-switch {
    transform: scale(1.4) translate(-13px, -3px);
    transform-origin: 0 0;
  }
}
.ReservationSettings___2K8Qu .ant-switch {
  transform: scale(1.4) translate(0, -3px);
  transform-origin: 0 0;
}
.ReservationSettings___2K8Qu .ant-checkbox-wrapper {
  margin-bottom: 10px;
}
.ReservationSettings___2K8Qu .ant-checkbox-wrapper .ant-checkbox {
  transform: scale(1.4);
  transform-origin: 0 10px;
  margin-right: 4px;
}
.ReservationSettings___2K8Qu .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  border-radius: 0;
}
.ReservationSettings___2K8Qu .ant-table .ant-checkbox-wrapper .ant-checkbox {
  transform-origin: 0 0;
}
.ReservationSettings___2K8Qu .ant-radio {
  transform: scale(1.2);
  transform-origin: 0 14px;
}
.ReservationSettings___2K8Qu .help-icon {
  cursor: pointer;
  margin-left: 4px;
}
.ReservationSettings___2K8Qu .settings-block .settings-title {
  margin-bottom: 10px;
}
.ReservationSettings___2K8Qu .settings-block .settings-content {
  margin-left: 30px;
}
.ReservationSettings___2K8Qu .settings-block .settings-section .settings-section-header {
  margin-bottom: 18px;
}
.ReservationSettings___2K8Qu .settings-block .settings-section .settings-section-header .section-description {
  color: #92929f;
}
.ReservationSettings___2K8Qu .settings-block .settings-section .settings-section-content {
  margin-left: 30px;
}
.ReservationSettings___2K8Qu .settings-block .settings-section .ant-radio-group {
  display: block;
}
.ReservationSettings___2K8Qu .settings-block .settings-section .secondary-title {
  margin-bottom: 16px;
}
.ReservationSettings___2K8Qu .settings-block .settings-section .settings-options .option-item + .option-item {
  margin-top: 16px;
}
.ReservationSettings___2K8Qu .settings-block .settings-section .settings-options .ant-radio-wrapper {
  display: flex;
}
.ReservationSettings___2K8Qu .settings-block .settings-section .settings-options .ant-radio-wrapper .ant-radio ~ span {
  flex: 1;
  min-width: 0;
  margin-top: -3px;
}
.ReservationSettings___2K8Qu .settings-block .settings-section + .settings-section {
  margin-top: 16px;
}
.ReservationSettings___2K8Qu .settings-block + .settings-block {
  margin-top: 16px;
}
.ReservationSettings___2K8Qu .icon-trash {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('https://pos-static.chowbus.com/assets/icon_trash.png');
  background-size: 100%;
  cursor: pointer;
}
.ReservationSettings___2K8Qu .postfix___3Tm2i {
  margin: 0 10px 0 5px;
  color: #92929F;
  white-space: nowrap;
}
.ReservationSettings___2K8Qu .tableQueueOption___13Oci .ant-form-item {
  margin-bottom: 10px;
}
.ReservationSettings___2K8Qu .tableQueueOptionSaving___1tCKb {
  background-color: red;
}
.ReservationSettings___2K8Qu .tableQueueNotes___1IrwE .ant-form-item {
  margin-bottom: 10px;
}
.ReservationSettings___2K8Qu .tableQueueNotes___1IrwE textarea {
  resize: none;
}
.ReservationSettings___2K8Qu .optionCapacity___1tzQJ .ant-form-item-explain-connected + div {
  display: none;
}
.ReservationSettings___2K8Qu .divider___1eo2J {
  background-color: #fffcfc;
  border-width: 1px 0 0;
  margin: 5px 0;
}
.ReservationSettings___2K8Qu .errorList___148fu .ant-form-item-explain {
  margin: 10px 0;
}
