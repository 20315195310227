.chartContainer___DpfAC {
  display: flex;
  flex-direction: row;
}
.chartContainer___DpfAC .chartLegend___2nwGa {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 20px;
}
.chartContainer___DpfAC .legend-title {
  padding-right: 50px;
}
.chartContainer___DpfAC .legend-value {
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
}
.chartContainer___DpfAC.isMobile___36xAi {
  flex-direction: column;
}
.chartContainer___DpfAC.isMobile___36xAi .chartLegend___2nwGa {
  align-items: center;
  padding-bottom: 0;
}
.chartContainer___DpfAC.isMobile___36xAi .chart-legends {
  width: 100%;
}
.ReviewRatingChartWrapper___3ybgo {
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  padding: 30px;
  width: 600px;
  min-width: 530px;
}
.ReviewRatingChartWrapper___3ybgo.isMobile___36xAi {
  width: 100%;
  min-width: auto;
}
.reportSummarySection___22pjO {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.reportSummarySection___22pjO .reportSummaryCard___Oxk2X {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.reportSummarySection___22pjO .reportSummaryCard___Oxk2X .reportSummaryTitle___3MhI- {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.reportSummarySection___22pjO .reportSummaryCard___Oxk2X .reportSummaryNumber___38Xpn {
  color: #000000;
  font-size: 32px;
  line-height: 34px;
  font-weight: 600;
  padding: 10px 0;
}
.reportSummarySection___22pjO .reportSummaryCard___Oxk2X .reportSummaryGrowingInfo___2hcD2 {
  color: #52C41A;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}
.isMobile___36xAi .reportSummarySection___22pjO {
  flex-direction: column;
  gap: 20px;
}
