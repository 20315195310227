.OrderTimeoutThresholds___3ySPW {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  overflow: hidden;
}
.OrderTimeoutThresholds___3ySPW .ant-input-number {
  width: 80px;
}
.OrderTimeoutThresholds___3ySPW .ant-input-number-group-addon {
  padding: 0 12px;
}
.OrderTimeoutThresholds___3ySPW > div {
  width: 13%;
}
.OrderTimeoutThresholds___3ySPW > div > div {
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 16px;
  border-bottom: 1px solid #ebebeb;
}
.OrderTimeoutThresholds___3ySPW > div > div:first-child {
  height: 55px;
  font-size: 14px;
  font-weight: 600;
  background-color: #fafafa;
}
.OrderTimeoutThresholds___3ySPW > div > div:last-child {
  border-bottom: none;
}
.OrderTimeoutThresholds___3ySPW > div > div .preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 200px;
  line-height: 20px;
  padding: 6px 8px;
  font-size: 14px;
  font-weight: 600;
  border-top-left-radius: 5.33px;
  border-top-right-radius: 5.33px;
  box-shadow: 0px 1.33px 8px 0px #0000000d;
}
.OrderTimeoutThresholds___3ySPW > div:nth-child(3),
.OrderTimeoutThresholds___3ySPW > div:nth-child(4) {
  width: 37%;
}
