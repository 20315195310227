.reviewMain___3g3TE {
  width: 100vw;
  padding: 0 !important;
  margin: 0 auto;
  min-width: 100% !important;
  width: 100% !important;
  -webkit-text-size-adjust: none;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background: #c6c6c6;
  font-family: 'Proxima Nova', 'PingFang SC';
  height: calc(var(--app-height));
}
.reviewContainer___2ohkv {
  position: relative;
  margin: 0 !important;
  display: block;
  -webkit-text-size-adjust: none;
  max-width: 414px;
  width: 100%;
  padding: 0px 15px 68px 15px;
  padding: 0px 15px 0px 15px;
  height: 100%;
  overflow-y: hidden;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 576px) {
  .reviewContainer___2ohkv {
    max-width: 100vw;
  }
}
.reviewContainer___2ohkv .top___1oVGh {
  height: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 15px;
  left: 15px;
  top: 5px;
}
.receipt___3hQoG.error___3W13- .receiptContainer___BHnbB {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoHeader___3oInf {
  margin-top: 21px;
  height: 80px;
  width: 100%;
  overflow: hidden;
  text-align: center;
  position: relative;
}
.logoHeader___3oInf img {
  max-height: 100%;
  width: auto !important;
}
.title___1nYbJ {
  color: #000000;
  font-family: 'Proxima Nova', Arial, sans-serif;
  font-size: 28px !important;
  line-height: 29px;
  text-align: center;
  font-weight: 700;
  margin: 20px 0;
}
.reviewBodySection___3HLXn {
  width: 100%;
  background-color: #ffffff;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-weight: 400;
  line-height: 22px;
  font-size: 12px !important;
  color: #85898c;
  padding: 50px 24px 18px 24px;
  margin-top: 55px;
  border-radius: 12px;
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
}
.reviewBodySection___3HLXn .innerSection___c8hR- {
  padding-left: 24px;
  padding-right: 24px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}
.desc___1CUKP {
  font-weight: 400;
  color: #8C8C8C;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
.mobileEntryContainer___2hX5Y {
  width: 100%;
  height: 48px;
  margin-top: 50px;
}
.mobileEntryError___3KBKq {
  color: #E00051;
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  padding: 10px 0;
  width: 100%;
}
input.commonInput___1JwPL {
  -webkit-appearance: none;
  outline: 0;
  border: 0;
  box-sizing: border-box;
  margin: 0;
  list-style: none;
  width: 100%;
  text-align: left;
  height: 100%;
  background-color: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  background-color: #F5F5F5;
  border-radius: 24px;
  padding: 15px 19px;
}
/* 设置 line-height 会导致 placeholder显示位置不正确的bug */
input.commonInput___1JwPL::placeholder {
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #C0C2CC;
  opacity: 0.8;
  font-size: 16px;
}
.actionButtonContainer___1nSqU {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}
.confirmButton___1u2mK {
  width: 100%;
  background-color: #D9D9D9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: #ffffff;
  border-radius: 24px;
  height: 48px;
  cursor: pointer;
}
.confirmButton___1u2mK.emptyButton___1Z8jP {
  background-color: transparent;
  color: #8C8C8C;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
  height: 32px;
}
.confirmButton___1u2mK.outlineButton___F-VzV {
  border: 1px solid #E00051;
  background-color: #ffffff;
}
.confirmButton___1u2mK.inactive___T_0OJ {
  pointer-events: none;
}
.confirmButton___1u2mK.active___IZyn1 {
  background-color: #E00051;
  pointer-events: auto;
}
.divideLine___1mHfs {
  height: 1px;
  background-color: #dadada;
  width: 100%;
  margin: 16px 0;
}
.bottomSection___2rpTD {
  bottom: 0px;
  left: 0;
  right: 0;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottomSection___2rpTD .title {
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}
.bottomSection___2rpTD .second {
  color: #8E8E8E;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}
.bottomSection___2rpTD .third {
  color: #ffffff;
  background-color: #E00051;
  border-radius: 4px;
  padding: 6.4px 12px 6.4px 12px;
  margin-top: 20px;
}
.bottomSection___2rpTD .fourth {
  display: flex;
  align-items: center;
}
.bottomSection___2rpTD .fourth img {
  margin-right: 5px;
  max-height: 20px;
}
.consent___2bN6d {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}
.consent___2bN6d .consentCheckbox___35QKc {
  width: 17px;
  height: 17px;
  flex: none;
  border: 1px solid #C0C2CC;
  border-radius: 2px;
  visibility: visible;
}
.consent___2bN6d .checkboxImg___SMNZ_ {
  visibility: hidden;
  width: 17px;
  height: 17px;
  position: relative;
  background-color: #e00051;
  flex: none;
  margin-left: -17px;
  border-radius: 2px;
}
.consent___2bN6d .checkboxImg___SMNZ_::after {
  position: absolute;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: " ";
  top: 50%;
  left: 26%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
}
.consent___2bN6d .consentDesc___2nxHG {
  color: #92929F;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-left: 8px;
}
.consent___2bN6d.consentActived___261Sx .consentCheckbox___35QKc {
  visibility: hidden;
}
.consent___2bN6d.consentActived___261Sx .checkboxImg___SMNZ_ {
  visibility: visible;
}
