.orderReviewsInvitation___3720x .ruleItem {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}
.orderReviewsInvitation___3720x .ruleItem .label {
  flex: 1;
  min-width: 200px;
  font-weight: 600;
  line-height: 20px;
  color: #000000;
}
.orderReviewsInvitation___3720x .ruleItem .ant-form-item {
  flex: 6;
  margin-bottom: 0;
}
.orderReviewsInvitation___3720x .ruleItem .ant-form-item .ant-form-item-control-input {
  order: 2;
}
.orderReviewsInvitation___3720x .ruleItem .ant-form-item .ant-form-item-extra {
  order: 1;
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 20px;
}
.orderReviewsInvitation___3720x .ant-switch {
  margin-left: 5px;
}
.orderReviewsInvitation___3720x.isMobile___2tN82 .ruleItem {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
}
.orderReviewsInvitation___3720x .settingDisable___14yio {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.orderReviewsInvitation___3720x .settingDisable___14yio a {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
